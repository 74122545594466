import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div style={{
      fontFamily: 'Arial, sans-serif',
      margin: '0 auto',
      padding: '20px',
      maxWidth: '800px',
      lineHeight: '1.6',
      color: '#333'
    }}>
    <div style={{ textAlign: "center" }}>
        <div style={{ fontSize: "20px", fontWeight: "bold" }}>Privacy Policy</div>
        <p>Last Updated: January 24, 2024</p>
        <p>PLEASE READ THE FOLLOWING PRIVACY POLICY CAREFULLY BEFORE USING THIS WEBSITE OR OTHER SERVICES THAT WE PROVIDE. BY ACCESSING OR USING OUR WEBSITE OR OUR SERVICES (OTHER THAN TO READ THIS PRIVACY POLICY FOR THE FIRST TIME), YOU AGREE TO THE TERMS OF THIS PRIVACY POLICY AND YOU CONSENT TO YOUR INFORMATION BEING COLLECTED, PROCESSED AND/OR SHARED IN ACCORDANCE WITH THIS POLICY.</p>
        <p>Your privacy is very important to us. This Privacy Policy governs the use of your personal information by any websites or mobile websites owned or operated by Digital Transformation Pro and its affiliates (“Company,” “us,” “we”), including the website currently located at  LinkLeads.Ai  (the “Site”), and any applications, interactive features, widgets and resources that may be offered by Company through traditional Internet websites, mobile devices or other platforms or services (all of which, together with the Site, are collectively referred to as the “Properties” and each individually as a “Property”).</p>
        <div style={{ fontSize: "24px", fontWeight: "bold" }}>Managing Your Information Preferences</div>
        <p>We will not disclose Personal Information (as defined below) to third parties for their direct marketing purposes without your consent. If, after providing consent, you want to opt out of having your Personal Information disclosed to third parties for their direct marketing purposes, please let us know by sending us an email at  info@Standout.Digital be sure to include your full name, email address, mailing address, and specifically state that you do not want your personal information shared with third parties for their direct marketing purposes.</p>
        <p>Additionally, you will have an opportunity to unsubscribe from receiving promotional material from the Company by sending us an email at info@Standout.Digital.</p>
        <div style={{ fontSize: "24px", fontWeight: "bold" }}>Information We Collect</div>
        <p>By providing any personal information to us, you fully understand, acknowledge, agree, and unambiguously consent to the transfer of such personal information and the collection and processing of such personal information in the United States. Your personal information, if stored, will be stored and processed on computers in the United States, Canada, and/or elsewhere outside of the United States.</p>
        <p>We collect the following categories of information:</p>
        <p>Personal Information</p>
        <p>We may collect personally identifiable information (“Personal Information”) when you request information from us, apply for or enroll in our programs, enter in or participate in our contests, sweepstakes or other promotions, vote in polls or otherwise express an opinion, subscribe to one of our services, participate in one of our online forums or communities, register on the Site, and at other times when we give you the opportunity to provide us Personal Information.  Further, you will provide Personal Information when you search for a product, place an order through the Site, provide information on any account you have with us, communicate with us by phone, e-mail or otherwise. As a result of those actions, you might supply us with such information as your name, age, mailing address and phone numbers; credit card information; people to whom purchases have been shipped; e-mail addresses of you and other people; content of reviews and e-mails to us; personal description and photographs; and financial information.</p>
        <p>The decision to provide this information is optional. However, if you choose not to provide the requested information, you may not be able to purchase our products or enroll in our programs or use some or all of the features of our Properties.</p>
        <p>Information About Others</p>
        <p>The Properties may allow you to (i) submit information about other people, such as their name, e-mail address, zip code, mailing address, information about their interest in and use of various content, products, programs and services, and other information, and/or (ii) invite friends to join the Properties and to connect with you through our social media pages associated with Social Networking Sites (as defined below). We may collect any such information submitted or shared by you. The decision to provide such information is optional.</p>
        <p>Usage Data and Site Activity</p>
        <p>When your computer, tablet, mobile device or any other device contacts our web servers (for example, when you visit the Site or any other Property), we may collect information in connection with the actions you take on the Properties (“Usage Data”). For example, each time you use a Property we may collect the type of Web browser you use, your operating system, your Internet Service Provider, your IP address, the pages you view, your media access control (MAC) address, your mobile carrier, your mobile Internet browsers, your location information, and the time and duration of your visits to the Property. This anonymous usage information may be associated with your user name and profile, but we will not disclose the associated information to third parties, except as explained in this Privacy Policy. We may use this information to help us understand how people use the Properties, and to enhance the Properties and our services.</p>
        <p>Cookies and Web Beacons</p>
        <p>We may use cookies (a small text file placed on your computer to identify your computer and browser). We may also use Web beacons (an electronic file placed on a Web site that monitors usage). We use cookies and Web beacons primarily to improve the experience of the Properties, such as pre-populating your user name for easier login and for the storage of items and other information in your shopping cart between visits. Most Web browsers are initially set up to accept cookies. You can reset your Web browser to refuse all cookies or to indicate when a cookie is being sent. However, certain features of the Properties may not work if you delete or disable cookies. Some of our Third-Party Providers (as defined below), may use their own cookies and Web beacons in connection with the services they perform on our behalf, as further explained below.</p>
        <p>You also can learn more about cookies by visiting www.allaboutcookies.org, which includes information on cookies and how to block cookies on different types of browsers and mobile devices. However, by blocking or deleting cookies used on this Site, you may lose full functionality of the Site.</p>
        <p>Google Web Analytics Service </p>
        <p>This Site uses the Google Analytics analytics system to help improve usability and the customer experience. Google may record mouse clicks, mouse movements and scrolling activity and will track users of this Site. If you do not wish to be tracked, you can obtain the Google Analytics opt-out browser extension at https://tools.google.com/dlpage/gaoptout. </p>
        <p>Flash Cookies</p>
        <p>Our Properties may enable the use of the Adobe Flash Player. Adobe’s Flash Player is used by the vast majority of Web sites that offer video and other interactive content. By default, your use of the Adobe Flash Player generates “flash cookies” (also known as “persistent identification elements” or “local shared objects”). The Adobe Flash Player (and similar applications) use flash cookies to remember user settings, preferences and usage similar to the browser cookies referenced above, but flash cookies can store more information than browser cookies and are managed through a different interface than the one provided by your Web browser. You can control the degree to which you accept flash cookies by accessing your Adobe Flash Player management tools directly through the settings manager for Adobe Flash. If you do not allow flash cookies to take any disc space on your computer, you may not be able to take advantage of or participate in certain features of a Property.</p>
        <p>Although the Adobe Flash Player is used by the vast majority of Web sites that offer video content and/or games, it is not the only technology being used in the ever-shifting online and mobile content environment. HTML5 is an increasingly popular web standard used for presenting content, especially content delivered to mobile devices. HTML is the mark-up language used for the World Wide Web. Almost all Web pages you visit on the internet are based around HTML code. HTML5 is simply the fifth and latest iteration of this mark-up language that allows for more interactive web pages. One of the real benefits of HTML5 is its potential to standardize the highly fragmented rich-media universe. Some HTML5 code may allow your response to advertising and other activities to be monitored across websites and such information to be stored on your computer or mobile device. Technology solutions that allow users to opt-out of or block this sort of tracking are being developed through browser add-ons and other tools.</p>
        <div style={{ fontSize: "24px", fontWeight: "bold" }}>How We Use Information and When We May Share and Disclose Information Generally</div>
        <p>Use of Information</p>
        <p>We use Personal Information for internal purposes, such as to improve the Properties, to fulfill requests for products and services, to notify you of new products or services, and to otherwise communicate with you about Company, our products and our affiliated companies.</p>
        <p>We may use Usage Data for a number of purposes, such as system administration, to report aggregate information to our business partners or to audit the use of the Properties. We may associate your Usage Data with the Personal Information you provide. We may disclose to third parties certain Usage Data regarding the Properties. However, in such cases, your Usage Data will be aggregated with the Usage Data of others and will not identify you individually or include your Personal Information.</p>
        <p>Disclosure of Information</p>
        <div style={{ fontSize: "24px", fontWeight: "bold" }}>Third-Party Providers</div>
        <p>From time to time, we may establish a business relationship with other businesses whom we believe trustworthy and who have confirmed that their privacy practices are consistent with ours (“Third-Party Providers”). For example, we contract with Third-Party Providers to provide certain services, such as hosting and maintenance, data storage and management, marketing and promotions, fulfilling orders, delivering packages, sending postal mail and e-mail, removing repetitive information from customer lists, analyzing data, providing marketing assistance, providing search results and links (including paid listings and links), processing credit card payments, and providing customer service. We generally only provide our Third-Party Providers with the information necessary for them to perform these services on our behalf.   </p>
        <p>WordPress</p>
        <p>Our store is hosted on WordPress and we use the following WooCommerce integrations – WooPayments, Stripe & PayPal Payments which are all PCI-DSS compliant. We also provide a quicker alternative for Amazon Prime users with the “Buy With Prime” payment option (the data for every client is in the buy with prime console). By using these options you agree to share Personal Information such as First Name, Surname, email, and address. This information will not be used for any purpose other than opt-in email campaigns.  For more information, please visit the following links:  </p>
        <p>https://woo.com/fr-be/privacy%20policy/</p>
        <p>https://stripe.com/privacy</p>
        <p>https://www.paypal.com/us/legalhub/privacy-full</p>
        <p>https://www.amazon.com/b?ie=UTF8&node=20907011011
      
      https://buywithprime.amazon.com/knowledge-center</p>
        <p>We do not collect or store your data on a secure server behind a firewall. If you choose a direct payment gateway to complete your purchase, your credit card data is encrypted through the Payment Card Industry Data Security Standard (PCI-DSS). Your purchase transaction data is stored only as long as is necessary to complete your purchase transaction. After that is complete, your purchase transaction information is deleted. All direct payment gateways adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, MasterCard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of credit card information by our store and it’s entirely handled by third-party processors. All sales transactions on the site are securely sent to our payment processing service providers.</p>
        <div style={{ fontSize: "24px", fontWeight: "bold" }}>Joint Offerings</div>
        <p>We may provide services jointly with other companies. In some cases, in order to facilitate such services, it may be necessary to share your Personal Information with such other companies. Before we do so, we will identify the partner, present the joint service provider’s privacy policy to you before you use that service, and obtain your permission to share your Personal Information.</p>
        <div style={{ fontSize: "24px", fontWeight: "bold" }}>Advertisers</div>
        <p>We may work with third-party advertisers, search providers and ad networks (“Advertisers”). Although we do not provide Personal Information to Advertisers, these companies may collect and use information about your use of the Properties in order to provide advertisements about goods and services that may be of interest to you. Advertisements may be shown via the Properties or on third-party Web sites and services. These companies may place or recognize a unique cookie on your computer or use other technologies such as Web beacons. Our Privacy Policy does not cover any use of information that an Advertiser may collect from you.</p>
        <div style={{ fontSize: "24px", fontWeight: "bold" }}>Sweepstakes, Contests and Other Promotions</div>
        <p>If you choose to enter a contest, sweepstakes or other promotion (each, a “Promotion”), your Personal Information may be disclosed to third parties in connection with such Promotion, including without limitation, for purposes of posting your entry with attribution or otherwise as permitted in the official rules for the Promotion in question, fulfilling your prize or including your name on a winners list. Also, by entering a Promotion, we may require you to consent to the use of your Personal Information or other information, such as name, voice or likeness, in advertising, promotional and marketing materials. In addition, we may offer a Promotion sponsored by or co-branded with a third party, who may obtain the Personal Information that you submit.</p>
        <div style={{ fontSize: "24px", fontWeight: "bold" }}>Affiliated Entities and Successors</div>
        <p>We may share Personal Information and Usage Data with businesses controlling, controlled by, or under common control with Company. If Company is merged, acquired, or sold, or in the event of a transfer of some or all of our assets, we may disclose or transfer Personal Information and Usage Data in connection with such transaction.</p>
        <div style={{ fontSize: "24px", fontWeight: "bold" }}>Legal Disclosures</div>
        <p>The Company cooperates with government and law enforcement officials and private parties to enforce and comply with the law. We may disclose Personal Information (and any other information about you or content posted or transmitted by you through the Properties) to government or law enforcement officials or private parties if, in our discretion, we believe it is necessary or appropriate in order to respond to legal requests (including court orders, search warrants and subpoenas), to protect the safety, property or rights of Company or of any third party, to prevent or stop any illegal, unethical, or legally actionable activity, or to comply with the law.</p>
        <div style={{ fontSize: "24px", fontWeight: "bold" }}>External Links</div>
        <p>The Properties may contain links to, or advertisements about, non-Company Web sites or properties (“Other Sites”). Other Sites may also reference, advertise, or link to the Properties. Company does not endorse or sponsor Other Sites, is not responsible for the privacy practices or the content of Other Sites, expressly disclaims any statements or assertions made on such Other Sites, and denies all liability associated with your use of, and the content on, such Other Sites. Please be advised that the practices described in this Privacy Policy do not apply to information gathered through Other Sites. We encourage you to read the privacy policies of each and every Web site that you visit.</p>
        <div style={{ fontSize: "24px", fontWeight: "bold" }}>Third Party Services and Applications</div>
        <p>The Properties may enable you to access third-party services, social media sites, plug-ins and applications, such as Facebook, Twitter and other social networks (each a “Social Networking Site”) and/or connect your accounts from such Social Networking Sites with the Properties by an application programming interface (API) or other software. When you do so, you may be able to import information from and share content, including Personal Information, with those Social Networking Sites. Some of those Social Networking Sites may also provide us with information from your accounts (e.g., photographs, gender, birthday, location, your “friends” list, people you follow and/or who follow you, your posts and “likes” and Usage Data), which may enable us to enhance and personalize your use of the Properties. By allowing us to connect with your accounts on Social Networking Sites, you consent to our accessing the information in those accounts. You likewise consent to our publishing on the Properties any such information or actions you take on other such Social Networking Sites. If you elect to access such Social Networking Sites through the Properties or connect your accounts from such Social Networking Sites with the Properties, the information you share with such Social Networking Sites will be subject to their privacy policies and your privacy settings for such services. You also may be able to control or block such sharing by changing your privacy settings in such Social Networking Sites.</p>
        <div style={{ fontSize: "24px", fontWeight: "bold" }}>Sharing Information with Others</div>
        <p>Please be aware that whenever you share information on a publicly available page or via comments, discussion boards, or any other public forum on the Properties, or when you interact with our Properties through any Social Networking Site, that information may be accessed by others. In addition, please remember that when you share information in any other communications with third parties, including information in direct messages to other users, that information may be passed along or made public by others. This means that anyone with access to such information can potentially use it for any purpose, including sending you unsolicited communications. We reserve the right to monitor your communications with others conducted through the Properties, as well as the right to monitor and respond to your communications on our social media pages associated with any Social Networking Site. We may disclose such communications in our discretion for legal enforcement and compliance purposes as described above. Company assumes no responsibility for any action or policies of any third parties who collect any information users may disclose in user forums or other public areas, if any, of a Property or our social media pages associated with any Social Networking Site.</p>
        <div style={{ fontSize: "24px", fontWeight: "bold" }}>Security</div>
        <p>The Company recognizes the importance of safeguarding the confidentiality of your Personal Information. Accordingly, we employ commercially reasonable measures designed to protect your information from unauthorized access, disclosure and use. However, no data transmission over the Internet or other network can be guaranteed to be 100% secure. As a result, while we strive to protect information transmitted on or through the Properties, we cannot and do not guarantee the security of any information you transmit on or through the Properties, and you do so at your own risk.</p>
        <div style={{ fontSize: "24px", fontWeight: "bold" }}>Children’s Privacy</div>
        <p>The Properties are not intended for children under the age of 13, and we do not knowingly collect Personal Information from children under the age of 13. If we become aware that we have inadvertently received Personal Information from a child under the age of 13, we will delete such information from our records. In addition, we recommend that minors 13 years of age or older ask their parents for permission before sending any information about themselves to anyone over the Internet. You hereby agree that all information you provide, including but not limited to age, shall be truthful and correct.</p>
        <p>If and to the extent that we offer any program open to children under 13 years of age, we will collect Personal Information about them from parents and guardians for purposes of considering their admission, enrollment and other administrative purposes. We will not share the Personal Information of anyone under 13 years of age with any third party without the consent of a parent or legal guardian.</p>
        <p>If and to the extent that we knowingly collect, use or disclose Personal Information from children under the age of 13, we will comply with the Children’s Online Privacy Protection Act (“COPPA”) (and other applicable laws) and obtain parental consent.</p>
        <div style={{ fontSize: "24px", fontWeight: "bold" }}>Processing in the United States</div>
        <p>Please be aware that the Properties are operated solely in the United States and that your Personal Information and communications may be transferred to and maintained on servers or databases located outside your state, province, or country. By providing any information to Company, all users, including, without limitation, users in the member states of the European Economic Area, Canada or elsewhere outside of the United States, fully understand and unambiguously consent to this Privacy Policy. If you are located outside of the United States, please be advised that the laws of the United States may not be as protective of your privacy as those in your location. By using our Properties, you agree that the collection, use, transfer, and disclosure of your Personal Information and communications will be governed by the applicable laws in the United States.</p>
        <div style={{ fontSize: "24px", fontWeight: "bold" }}>Choice of Law</div>
        <p>This Privacy Policy, including all revisions and amendments thereto, is governed by the internal laws of the District of Columbia, without regard to its conflict or choice of law principles which would require application of the laws of another jurisdiction.</p>
        <div style={{ fontSize: "24px", fontWeight: "bold" }}>Privacy Policy Changes</div>
        <p>From time to time, we may change this Privacy Policy. If we decide to change this Privacy Policy, we will inform you by posting the revised Privacy Policy on the Site. Those changes will go into effect on the Revision Date shown in the revised Privacy Policy. Your continued use of our Properties constitutes your consent to the revised Privacy Policy.</p>
        <div style={{ fontSize: "24px", fontWeight: "bold" }}>Sole Statement</div>
        <p>This Privacy Policy as posted on this Site is the sole statement of our privacy policy with respect to this Site, and no summary, modification, restatement or other version thereof, or other privacy statement or policy, in any form, is valid unless we post a new or revised policy to the Site.</p>
        <div style={{ fontSize: "24px", fontWeight: "bold" }}>Unsubscribing</div>
        <p>If for any reason you would like to stop receiving any emails sent by CrampScience that notify you of upcoming events, promotions, sales, new products or contain other commercial communications, you can click the unsubscribe link found at the bottom of every email or you may contact contact our Customer Service team at info@Standout.Digital . By clicking the unsubscribe link, you will be directed to an unsubscribe page where you can remove your name and contact information from our email list.</p>
        <div style={{ fontSize: "24px", fontWeight: "bold" }}>Questions</div>
        <p>Should you have any questions or concerns about this Privacy Policy or the collection of Personal Information, please contact us at  info@Standout.Digital.</p>
        <p></p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
