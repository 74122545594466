import React, { useEffect, useState } from "react";
import Button from "../components/Button/Button";
import UserSVG from "../assets/userPic.svg";
import CategorySVG from "../assets/category.svg";
import filSVG from "../assets/file.svg";
import SMSSVG from "../assets/sms.svg";
import SimpleStoreSVG from "../assets/simpleStore.svg";
import { FaChevronDown } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import SimpleMDE from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import ScanEye from "../assets/scanEye.svg";
import Loading from "components/Loading/Loading";
import axios from "axios";
import { toast } from "react-toastify";

const CustomizeProductEmail = ({ props }) => {
  const [value, setValue] = useState("");
  const [categoriesOpen, setCategoriesOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [shouldRenderUI, setShouldRenderUI] = useState(false);
  const [data, setData] = useState({});
  const [email, setEmail] = useState({
    type: props.type,
  });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/user-data", {
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log("Data received:", response.data);

        if (response.status === 200) {
          console.log(response.data);
          setData(response.data);
          setIsLoading(false);
          setShouldRenderUI(true);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleUpdate = async () => {
    try {
      const response = await axios.post("/email/update-template", email, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("Update response:", response.data);
      if (response.status === 201) {
        toast.success("Template Updated Successfully");
      }
    } catch (error) {
      console.error("Error updating data:", error);
      alert("Failed to update data");
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      {shouldRenderUI && (
        <div className="ml-4 mt-1 flex flex-col lg:flex-row gap-10 justify-between">
          {/* right side div */}
          <div className="w-full">
            {/* banner */}
            <div className="bg-gray-100 p-5 md:p-10 md:h-72 w-full ">
              <div className="flex items-center justify-between">
                <p className="text-2xl text-[#181819E5]">
                  Customize your Product Emails
                </p>
                <img src={ScanEye} alt="" className="p-3 shadow-icon" />
              </div>

              <p className="my-6 text-base leading-5 text-[#18181992]">
                {`Tailor the emails sent to users who sign up for this element (link
            or lead magnet). Add a`}
                <br />
                {`custom image, additional links, and adjust size, color, and appearance. Utilize the following placeholders for personalized content.`}
                <br />
                {"{{ item }}"} {` = items bought with their urls`}
                <br />
                {"{{ total }}"} {` = total price of invoice`}
              </p>

              <p className="my-6 text-base leading-5 text-[#18181992]">
                Ensure URLs are valid (starting with http:// or https://)
              </p>

              {/* <Button
                text="+ Generate with AI"
                className="flex items-center gap-1 border border-gray-300 px-3 md:px-6 py-2 rounded-full font-normal text-xs bg-primary text-white"
              /> */}
              <div className="">
                <SimpleMDE
                  value={value}
                  onChange={(val) => {
                    setEmail({ ...email, email: val });
                  }}
                />
              </div>
              {/* Update button */}
              <div className="flex justify-end mt-6 mr-10">
                <button
                  className="px-6 py-3 bg-blue-600 text-white rounded-full"
                  onClick={handleUpdate}
                >
                  Update
                </button>
              </div>
            </div>

            {/* md editor */}
          </div>

          {/* left side div */}
          {/* <div className="md:w-[500px] bg-white">
            <div className="flex flex-col items-center justify-center my-10">
              <div className="relative py-2">
                <div className="absolute top-28 right-3"></div>
                <img
                  src={data.profilePic || UserSVG}
                  onError={(e) => {
                    e.target.src = UserSVG;
                  }}
                  alt=""
                  className="object-cover w-32 h-32"
                />
              </div>
              <p className="text-2xl font-medium my-3">{data.name}</p>
            </div>
            <p className="text-xl mx-10 my-5 font-medium">
              {props.sidebarTitle}
            </p>
            <div className="flex flex-col justify-center gap-y-6 mx-5">
              <div
                onClick={() => navigate(`/customize-${props.type}-lead-magnet`)}
                className="flex items-center gap-3 p-2 rounded-2xl cursor-pointer"
              >
                <img src={filSVG} alt="" />
                <p>Customize Landing Page</p>
              </div>
              <div className="flex items-center gap-3 border border-gray-500 p-2 rounded-2xl cursor-pointer">
                <img src={SMSSVG} alt="" />
                <p>Customize Emails</p>
              </div>
            </div>
          </div> */}
        </div>
      )}
    </>
  );
};

export default CustomizeProductEmail;
