import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import "tailwindcss/tailwind.css";

const FAQComponent = ({ questionsAnswers }) => {
  const [expanded, setExpanded] = useState({});

  const toggleExpand = (index) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [index]: !prevExpanded[index],
    }));
  };

  return (
    <div className="right-div flex-1 mt-6 md:mt-0">
      {questionsAnswers.map((qa, index) => (
        <div key={index} className="mb-4">
          <div
            className="flex items-center justify-between border-t-2 p-2 relative cursor-pointer hover:bg-gray-100 transition-colors"
            onClick={() => toggleExpand(index)}
          >
            <p className="text-black/80">{qa.question}</p>
            <FontAwesomeIcon
              icon={expanded[index] ? faMinus : faPlus}
              className="w-4 h-4 text-gray-500 transition duration-300"
            />
          </div>
          <div
            className={`ml-6 text-sm text-black/80 transition-all duration-300 overflow-hidden ${
              expanded[index] ? "max-h-screen" : "max-h-0"
            }`}
          >
            <p>{qa.answer}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FAQComponent;
