import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faCheck } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { FaStar } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { createClient } from 'contentful';
import './HeroComponent.css';

import UserIcon1 from "../../assets/profile1.jpg";
import UserIcon2 from "../../assets/profile2.jpg";
import UserIcon3 from "../../assets/profile3.jpg";
import UserIcon4 from "../../assets/profile4.jpg";

const client = createClient({
  space: '60rew5hef1yw',
  accessToken: 'm4B-e9c0HFWhlzeBZfi0grKH0QSIIDlLzT3TknflgnM'
});

const fetchData = async () => {
  const entry = await client.getEntry('5rJFfB9CiyPatpjYg2Bhy1');
  return entry.fields;
};

const fetchAssets = async () => {
  try {
    const assets = await client.getAssets();
    const imageAssets = assets.items.reduce((acc, asset) => {
      acc[asset.sys.id] = `https:${asset.fields.file.url}`;
      return acc;
    }, {});
    return imageAssets;
  } catch (e) {
    console.error(e);
    return {};
  }
};
const SkeletonLoader = () => {
  return (
    <div className="flex flex-col lg:flex-row gap-10">
      <div className="content-div flex-1 animate-pulse">
        <div className="bg-gray-300 h-8 w-1/2 mb-4 rounded"></div>
        <div className="bg-gray-300 h-12 w-3/4 mb-4 rounded"></div>
        <div className="bg-gray-300 h-6 w-full mb-6 rounded"></div>
        <div className="flex flex-col lg:flex-row items-start lg:items-center gap-4 mb-6">
          <div className="bg-gray-300 h-10 w-1/4 rounded"></div>
          <div className="flex flex-row -space-x-4 sm:-space-x-2 lg:-space-x-4">
            {[...Array(4)].map((_, index) => (
              <div
                key={index}
                className="bg-gray-300 rounded-full h-8 w-8 border-2 border-white"
              ></div>
            ))}
          </div>
        </div>
        <div className="bg-gray-300 h-6 w-1/2 mb-4 rounded"></div>
        <div className="bg-gray-300 h-6 w-1/3 mb-4 rounded"></div>
        <div className="bg-gray-300 h-6 w-1/3 mb-4 rounded"></div>
      </div>
      <div className="image-div flex justify-center items-center lg:justify-start lg:mt-10">
        <div className="bg-gray-300 h-60 w-full lg:w-[500px] lg:h-[500px] rounded-xl border-4 border-primary"></div>
      </div>
    </div>
  );
};
const HeroComponent = ({ animate }) => {
  const navigate = useNavigate();
  const [heading1, setHeading1] = useState("");
  const [heading2, setHeading2] = useState("");
  const [question1, setQuestion1] = useState("");
  const [button1, setButton1] = useState("");
  const [paragraph1, setParagraph1] = useState("");
  const [paragraph2, setParagraph2] = useState("");
  const [paragraph3, setParagraph3] = useState("");
  const [paragraph4, setParagraph4] = useState("");
  const [image1, setImage1] = useState("");
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const getData = async () => {
      try {
        const data = await fetchData();
        const assets = await fetchAssets();

        setHeading1(data.heading1);
        setHeading2(data.heading2);
        setQuestion1(data.question1.content[0].content[0].value);
        setButton1(data.button1);
        setParagraph1(data.paragraph1.content[0].content[0].value);
        setParagraph2(data.paragraph2.content[0].content[0].value);
        setParagraph3(data.paragraph3.content[0].content[0].value);
        setParagraph4(data.paragraph4.content[0].content[0].value);
        setImage1(assets[data.image1.sys.id] || '');
      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };

    getData();
  }, []);

  return (
    loading ? <SkeletonLoader /> : (
      <div className="flex flex-col lg:flex-row gap-10">
        <div className="content-div flex-1">
          <h1 className="text-black/80 text-[20px] leading-relaxed">{question1}</h1>
          <h3 className="main-heading text-4xl lg:text-6xl font-semibold text-primary my-4 leading-tight lg:leading-snug">
            <span className="bg-blue-800 text-white px-2 rounded mr-2">
              {heading1}
            </span>
            <br />
            {heading2}
          </h3>

          <p className="text-lg lg:text-xl mt-6 text-black/80 leading-relaxed">{paragraph1}</p>
          <div className="flex flex-col lg:flex-row items-start lg:items-center gap-4 mt-6 flex-wrap">
            <button
              className="bg-primary text-white pl-4 pr-4 pt-2 pb-2 rounded-full flex items-center text-sm lg:text-base"
              onClick={() => {
                navigate("/auth/signup");
              }}
            >
              {button1}
              <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
            </button>
            <div className="flex flex-row">
              <div className="flex flex-row -space-x-4 sm:-space-x-2 lg:-space-x-4 mt-4 lg:mt-0">
                <img
                  src={UserIcon1}
                  width={35}
                  alt=""
                  className="rounded-full border-2 border-white"
                  style={{ objectFit: "cover", height: "35px", width: "35px" }}
                />
                <img
                  src={UserIcon2}
                  width={35}
                  alt=""
                  className="rounded-full border-2 border-white"
                  style={{ objectFit: "cover", height: "35px", width: "35px" }}
                />
                <img
                  src={UserIcon3}
                  width={35}
                  alt=""
                  className="rounded-full border-2 border-white"
                  style={{ objectFit: "cover", height: "35px", width: "35px" }}
                />
                <img
                  src={UserIcon4}
                  width={35}
                  alt=""
                  className="rounded-full border-2 border-white"
                  style={{ objectFit: "cover", height: "35px", width: "35px" }}
                />
              </div>

              <div>
                <div className="flex space-x-1">
                  <FaStar className="text-yellow-500" />
                  <FaStar className="text-yellow-500" />
                  <FaStar className="text-yellow-500" />
                  <FaStar className="text-yellow-500" />
                  <FaStar className="text-yellow-500" />
                </div>
                <div className="rounded-lg lg:overflow-visible mt-4 lg:mt-0">
                  <div className="flex flex-col items-start font-bold text-blue-gray-500">
                    <p className="block antialiased font-medium text-blue-gray-500 text-sm">
                      {paragraph4}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col mt-6 lg:flex-col lg:justify-between lg:mt-4">
            <p className="flex  items-center text-sm lg:text-base">
              <FontAwesomeIcon icon={faCheck} className="text-red-700 mr-2" />
              {paragraph2}
            </p>
            <p className="flex  items-center text-sm lg:text-base mt-2 lg:mt-0">
              <FontAwesomeIcon icon={faCheck} className="text-red-700 mr-2" />
              {paragraph3}
            </p>
          </div>
        </div>
        <div className="image-div flex justify-center items-center lg:justify-start lg:mt-10">
          <img
            src={image1}
            alt="Video thumbnail"
            className="rounded-xl border-4 border-primary max-w-full max-h-full lg:max-w-[500px] lg:max-h-[500px] object-cover shadow-2xl"
          />
        </div>
      </div>
    )
  );
};

export default HeroComponent;
