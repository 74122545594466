import React, { useState, useEffect } from "react";
import Button from "components/Button/Button";
import Loading from "components/Loading/Loading";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import Template1 from "components/Templates/Template1";
import Template2 from "components/Templates/Template2";
import Template3 from "components/Templates/Template3";
import Template4 from "components/Templates/Template4";
import Template5 from "components/Templates/Template5";
import Template6 from "components/Templates/Template6";
import Template7 from "components/Templates/Template7";
import AITemplate from "components/Templates/AITemplate";
import Navbar from "components/Navbar/Navbar";
import { toast } from "react-toastify";

const PreviewTemplate = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [shouldRenderUI, setShouldRenderUI] = useState(false);
  const [data, setData] = useState(null);
  let [searchParams, setSearchParams] = useSearchParams();
  const [templateId, setTemplateId] = useState(1);
  const navigate = useNavigate();
  const [popupVisible, setPopupVisible] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [isLoadingpopup, setIsLoadingpopup] = useState(false);
  const [message, setMessage] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleSubmit = async () => {
    setIsLoadingpopup(true);
    setMessage("Template is being generated, please stay on this page.");

    try {
      const response = await axios.get(
        `/ai/create-new-template?userRequirement=${prompt}&updateTemplate=${isUpdate}`
      );
      setData({ ...data, template_html: response.data.template });
      toast.success("Template created successfully!");
      setMessage("Template created successfully!");
    } catch (error) {
      toast.error("There was an error creating the template.");
      setMessage("There was an error creating the template.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setTemplateId(searchParams.get("id"));
    const fetchData = async () => {
      try {
        const response = await axios.get("/user-data", {
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log("Data received:", response.data.template_id);

        if (response.status === 200) {
          console.log(response.data);
          setData(response.data);
          setTemplateId(response.data.template_id);
          setIsLoading(false);
          setShouldRenderUI(true);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const setTemplate = async () => {
    try {
      const formData = new FormData();
      formData.append("id", templateId);

      const response = await axios.post("/set-template", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 201) {
        toast.success("Template updated!");
        setPopupVisible(false);
      }
    } catch (error) {
      console.error("Error uploading basic_link:", error);
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      {shouldRenderUI && (
        <>
          <div className="flex flex-col lg:flex-row w-full h-full overflow-hidden">
            <div className="flex-grow bg-[#f6f7fa] px-2 lg:px-16 pt-10">
              <Navbar title="Preview Template" />
              <div
                className="overflow-y-auto overflow-x-hidden"
                style={{
                  position: "relative",
                  backgroundColor: "#ffffff",
                  padding: "20px",
                  borderRadius: "10px",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                }}
              >
                {templateId == 1 && <Template1 data={data} />}
                {templateId == 2 && <Template2 data={data} />}
                {templateId == 3 && <Template3 data={data} />}
                {templateId == 4 && <Template4 data={data} />}
                {templateId == 5 && <Template5 data={data} />}
                {templateId == 6 && <Template6 data={data} />}
                {templateId === 7 && <Template7 data={data} />}
                {templateId === -1 && <AITemplate data={data} />}
                {/* {templateId === 6 && <Template7 data={data} />} */}
              </div>
              <div className="flex flex-col md:flex-row justify-between items-center pb-5 mt-5">
                <h3 className="text-2xl font-medium"></h3>
                <div className="flex gap-5">
                  {templateId === -1 && (
                    <Button
                      onClick={() => setIsPopupOpen(true)}
                      text="Update Template using AI"
                      className="flex flex-row-reverse items-center gap-1 border border-gray-300 px-3 md:px-6 py-3 rounded-full font-bold text-xs bg-white"
                    />
                  )}
                  <Button
                    onClick={() => setPopupVisible(true)}
                    text="Change Template"
                    className="flex flex-row-reverse items-center gap-1 border border-gray-300 px-3 md:px-6 py-3 rounded-full font-bold text-xs bg-white"
                  />
                  <Button
                    onClick={() => navigate("/page-editor")}
                    text="Manage Page"
                    className="flex items-center gap-1 border border-gray-300 px-3 md:px-6 py-2 rounded-full font-bold text-xs bg-primary text-white"
                  />
                </div>
              </div>
            </div>
          </div>

          {isPopupOpen&&(<div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-lg text-center w-full max-w-md mx-4">
              <h2 className="text-2xl font-bold mb-4">
                Create or Update Template
              </h2>
              <textarea
                className="w-full p-2 mb-4 border rounded"
                placeholder="Enter your prompt here..."
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
              />
              <div className="flex items-center justify-center mb-4">
                <input
                  type="checkbox"
                  checked={isUpdate}
                  onChange={() => setIsUpdate(!isUpdate)}
                  className="mr-2"
                />
                <label className="text-sm">
                  Update Current Template Generated by AI
                </label>
              </div>
              <button
                onClick={handleSubmit}
                disabled={isLoading}
                className={`px-4 py-2 rounded ${
                  isLoading ? "bg-gray-400" : "bg-blue-500 hover:bg-blue-700"
                } text-white`}
              >
                {isLoading ? "Generating..." : "Submit"}
              </button>
              <button
                className="mt-4 px-4 py-2 bg-red-500 hover:bg-red-700 text-white rounded"
                onClick={()=>setIsPopupOpen(false)}
              >
                Close
              </button>
              {message && <p className="mt-4 text-gray-700">{message}</p>}
            </div>
          </div>)}
          {popupVisible && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "800px",
                  height: "600px",
                  backgroundColor: "white",
                  borderRadius: "10px",
                  padding: "20px",
                  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <h2 style={{ position: "absolute", top: "20px" }}>
                  Select your Template
                </h2>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    height: "100%",
                    marginTop: "60px",
                    overflow: "hidden",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginRight: "20px",
                      width: "20%",
                      overflowY: "auto",
                    }}
                  >
                    {Array.from({ length: 5 }, (_, index) => (
                      <div
                        key={index}
                        style={{
                          margin: "5px 0",
                          cursor: "pointer",
                          backgroundColor:
                            templateId === index + 1
                              ? "#e0e0e0"
                              : "transparent",
                          borderRadius: "5px",
                          textAlign: "center",
                          border:
                            templateId === index + 1
                              ? "1px solid blue"
                              : "1px solid lightgrey",
                        }}
                        onClick={() => setTemplateId(index + 1)}
                      >
                        Template {index + 1}
                      </div>
                    ))}
                    {data.subscribed && (
                      <div
                        style={{
                          margin: "5px 0",
                          cursor: "pointer",
                          backgroundColor:
                            templateId === -1 ? "#e0e0e0" : "transparent",
                          borderRadius: "5px",
                          textAlign: "center",
                          border:
                            templateId === -1
                              ? "1px solid blue"
                              : "1px solid lightgrey",
                        }}
                        onClick={() => setTemplateId(-1)}
                      >
                        AI Template
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                      height: "90%",
                      width: "90%",
                      overflowY: "auto",
                    }}
                  >
                    {templateId === 1 && <Template1 data={data} />}
                    {templateId === 2 && <Template2 data={data} />}
                    {templateId === 3 && <Template3 data={data} />}
                    {templateId === 4 && <Template4 data={data} />}
                    {templateId === 5 && <Template5 data={data} />}
                    {templateId === 6 && <Template6 data={data} />}
                    {templateId === 7 && <Template7 data={data} />}
                    {templateId === -1 && <AITemplate data={data} />}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "20px",
                    width: "100%",
                    position: "absolute",
                    bottom: "20px",
                    right: "20px",
                  }}
                >
                  <button
                    onClick={() => setPopupVisible(false)}
                    style={{
                      padding: "10px 20px",
                      marginRight: "10px",
                      backgroundColor: "transparent",
                      color: "grey",
                      border: "1px solid grey",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={setTemplate}
                    style={{
                      padding: "10px 20px",
                      backgroundColor: "#0052D4",
                      color: "white",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default PreviewTemplate;
