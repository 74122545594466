import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditSVG from "../../assets/editIcon.svg";
import SuggestionSVG from "../../assets/suggestion.svg";
import DeleteSVG from "../../assets/deleteIcon.svg";
import ProductCard from "../ProductCard/ProductCard";
import Button from "../Button/Button";
import axios from "axios";
import AISuggestions from "components/AI/Suggestion";

const AddProduct = ({ storeId, setCurrentStep, currentStep, product }) => {
  const [formData, setFormData] = useState({
    id: null,
    title: "",
    description: "",
    price: "",
    image: null,
    previewProduct: null,
    imageName: "",
    imageUrl: "",
    store_id: storeId,
  });
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [data, setData] = useState({});
  const timeoutRef = useRef(null);

  useEffect(() => {
    if (product) {
      setFormData({ ...product, image: null });
      setImageUrl(product.image);
    }
  }, [product]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/user-details", {
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.status === 200) {
          console.log(response.data);
          setData(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleFormChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });

    if (id === "title" && data.subscribed) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        fetchSuggestions(value);
      }, 500);
    }
  };

  const fetchSuggestions = async (query) => {
    try {
      const response = await axios.get(
        `/ai/autocomplete?type=product&userRequirement=${query}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setSuggestions(response.data.suggestions);
      }
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setFormData({ ...formData, title: suggestion });
    setSuggestions([]);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFormData({
      ...formData,
      image: file,
      imageName: file.name,
      imageUrl: "",
    });
    setImageUrl(URL.createObjectURL(file));
  };

  const uploadProduct = async (e) => {
    e.preventDefault();

    try {
      if (!formData.id) {
        if (formData.image || formData.imageUrl) {
          await axios.post("/product", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
        } else {
          toast.warning("Select an image!");
          return;
        }
      } else {
        await axios.post(`/product/${formData.id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        navigate(0);
      }

      toast.success("Product created!");
      setFormData({
        ...formData,
        title: "",
        description: "",
        price: "",
        image: null,
        previewProduct: null,
        imageName: "",
      });
      setImageUrl(null);
    } catch (error) {
      console.error("Error uploading category:", error);
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.error);
      } else {
        toast.error("An unexpected error occurred.");
      }
    }
  };

  const handleCancel = () => {
    setFormData({
      title: "",
      description: "",
      price: "",
      image: null,
    });
    setCurrentStep(currentStep - 1);
  };

  return (
    <div className="lg:mx-20 w-full">
      <AISuggestions
        formData={formData}
        setFormData={setFormData}
        setImageUrl={setImageUrl}
        subscribed={data.subscribed}
        type={"Product"}
      />
      <form onSubmit={uploadProduct}>
        <div className="border rounded-lg border-gray-200 bg-white p-3 lg:px-32">
          <p className="my-5 text-2xl font-normal text-black">
            Add New Product
          </p>
          <p className="block text-black/70">Title</p>
          <input
            id="title"
            type="text"
            maxlength="250"
            value={formData.title}
            onChange={handleFormChange}
            className="mt-1 px-2 block w-full rounded-md border h-10 border-gray-300 shadow-sm focus:ring-primary focus:border-primary sm:text-sm"
          />
          {suggestions.length > 0 && (
            <ul className="border rounded-md mt-1 w-full bg-white">
              {suggestions.map((suggestion, index) => (
                <li
                  key={index}
                  onClick={() => handleSuggestionClick(suggestion)}
                  className="cursor-pointer p-2 hover:bg-gray-200"
                >
                  {suggestion}
                </li>
              ))}
            </ul>
          )}
          <div className="mt-5 relative">
            <p className="text-black/70">Description</p>
            <div className="relative mt-3">
              <input
                id="description"
                type="text"
                value={formData.description}
                placeholder="Short Description Here"
                onChange={handleFormChange}
                className="pl-2 pr-8 block w-full rounded-md border h-10 border-gray-300 shadow-sm focus:ring-primary focus:border-primary sm:text-sm"
              />
            </div>
          </div>
          <div className="mt-5 relative">
            <p className="text-black/70">Product URL</p>
            <div className="relative mt-3">
              <input
                id="url"
                type="text"
                maxlength="250"
                value={formData.url}
                placeholder="Product download url"
                onChange={handleFormChange}
                className="pl-2 pr-8 block w-full rounded-md border h-10 border-gray-300 shadow-sm focus:ring-primary focus:border-primary sm:text-sm"
              />
            </div>
          </div>
          <p className="text-black/70 mt-5">Price</p>
          <div className="flex items-center gap-2 md:gap-20">
            <input
              id="price"
              type="number"
              value={formData.price}
              placeholder="10"
              onChange={handleFormChange}
              inputMode="decimal"
              className="px-2 block rounded-md border w-60 h-10 border-gray-300 shadow-sm focus:ring-primary focus:border-primary text-sm"
            />

            <div className="flex items-center flex-col">
              <label htmlFor="imageUpload" className="font-normal my-1 sm:mt-5">
                Upload Image
              </label>
              <input
                id="imageUpload"
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                className="hidden"
              />
              <label
                htmlFor="imageUpload"
                className="w-full inline-flex justify-center px-4 py-2 text-sm font-medium bg-transparent border-2 border-gray-300 rounded-3xl sm:mt-0 sm:w-auto sm:text-sm hover:bg-gray-100 hover:shadow-md cursor-pointer"
              >
                {formData.imageName || "Upload Image"}{" "}
                {/* Display the image name if available */}
              </label>
            </div>
          </div>
          {/* preview created product */}
          <p className="text-black/70 mt-5">Preview</p>
          <div className="border flex justify-center items-center flex-col md:flex-row gap-2 border-gray-200 rounded-md my-3 py-5">
            <ProductCard
              imageUrl={imageUrl}
              productName={formData.title}
              price={formData.price.toString().split(".")[0] || "00"}
              decimalPoint={formData.price.toString().split(".")[1] || "00"}
            />
          </div>

          <div className="flex justify-between">
            <div className="underline text-[#3855B3] flex flex-col leading-8">
              <Link to={`/lead-magnet-quiz/product/` + data.username}>
                View Landing Page
              </Link>
              <Link to="/customize-product-lead-magnet">
                View Landing Editor
              </Link>
              {/* <Link to="/customize-product-email">Email Editor</Link> */}
            </div>
            <div className="flex items-center gap-3 mt-20 mb-20">
              <Button
                text="Cancel"
                className="flex items-center gap-1 border border-gray-300 px-3 md:px-6 py-2 rounded-full font-normal text-xs text-black bg-white"
                onClick={handleCancel}
              />
              <Button
                text="Save"
                type="submit"
                className="flex items-center gap-1 border border-gray-300 px-3 md:px-6 py-2 rounded-full font-normal text-xs text-white bg-primary hover:bg-[#3855b3da] duration-300"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddProduct;
