import Login from "pages/Login";
import { Route, Routes, Navigate } from "react-router-dom";
import Signup from "pages/Signup";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Dashboard from "pages/Dashboard";
import Sidebar from "./components/Sidebar/Sidebar.jsx";
import CustomizeLeadMagnet from "pages/CustomizeLeadMagnet.jsx";
import CustomizeProductEmail from "pages/CustomizeProductsEmail.jsx";
import Temp from "pages/temp.jsx";
import LeadMagnet from "pages/lead-magnet.jsx";
import { ThemeProvider, createTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { HiOutlineBars3 } from "react-icons/hi2";
import SidebarForMobile from "./components/SidebarForMobile/SidebarForMobile.jsx";
import Store from "pages/Store.jsx";
import Analytics from "pages/Analytics.jsx";
import Payment from "pages/Payment.jsx";
import axios from "axios";
import LeadMagnetEbookQuiz from "pages/LeadMagnetEbookQuiz.jsx";
import LeadMagnetProductQuiz from "pages/LeadMagnetProductQuiz.jsx";
import SelectEbook from "pages/SelectEbook.jsx";
import EbookMiniLanding from "pages/EbookMiniLanding.jsx";
import EditTemplate from "pages/EditTemplate.jsx";
import PreviewTemplate from "pages/PreviewTemplate";

import PageEditor1 from "./pages/PageEditor1.jsx";
import PageEditor2 from "./pages/PageEditor2.jsx";
import PageEditor3 from "./pages/PageEditor3.jsx";
import PageEditor4 from "./pages/PageEditor4.jsx";
import UserProfile from "pages/UserProfile.jsx";
import Campaign from "pages/Campaign.jsx";
import Sales from "pages/Sales.jsx";

import Plan from "pages/Plan.jsx";
import LandingPage from "pages/LandingPage.jsx";
import ForgotPassword from "pages/ForgotPassword.jsx";
import ResetPassword from "pages/ResetPassword.jsx";
import { Helmet } from "react-helmet";
import TermsAndConditions from "pages/TermsAndConditions.jsx";
import PrivacyPolicy from "pages/PrivacyPolicy.jsx";
import FeedbackForm from "pages/Feedback.jsx";
import SupportPage from "pages/Support.jsx";
import Settings from "pages/Settings.jsx";



// axios.defaults.baseURL = "http://localhost:5000";
axios.defaults.baseURL = "https://api.linkleads.ai";
axios.defaults.headers.post["Content-Type"] = "application/json";

axios.defaults.withCredentials = true;
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      window.location.href = "/auth";
      console.log(error.response);
    }
    return Promise.reject(error);
  }
);

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#28439D",
    },
    secondary: {
      main: "#F7F8FA",
    },
  },
  typography: {
    fontFamily: "Roboto, sans-serif",
  },
});
const items = [
  { type: "video", sidebarTitle: "Lead Magnet: Video" },
  { type: "product", sidebarTitle: "Store" },
  { type: "ebook", sidebarTitle: "Lead Magnet: Ebook" },
];
function App() {
  return (
    <>
      <Helmet>
      {/* <title>LinkLeads.ai - Free Endless Sales and Leads from Your Link-in-Bio with AI</title> */}
      <meta name="description" content='Forever Free Plan. AI, Countdown timers, smart categories, and more features to finally "make it" as a creator.' />

      <meta property="og:title" content="LinkLeads.ai - Free Endless Sales and Leads from Your Link-in-Bio with AI" />
      <meta property="og:description" content='Forever Free Plan. AI, Countdown timers, smart categories, and more features to finally "make it" as a creator.' />
      </Helmet>
      <ToastContainer />
      <ThemeProvider theme={theme}>
        <Routes>
          <Route index element={<LandingPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/Terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/auth" element={<Login />} />
          <Route path="/sales" element={<Wrapper Page={Sales} />} />
          <Route path="/campaign" element={<Wrapper Page={Campaign} />} />
          <Route path="/api/auth/callback/gitlab" element={<Login />} />
          <Route path="/auth/signup" element={<Signup />} />
          <Route path="/dashboard" element={<Wrapper Page={Dashboard} />} />
          <Route path="/payment" element={<Wrapper Page={Payment} />} />
          <Route path="/lead-magnet" element={<Wrapper Page={LeadMagnet} />} />
          <Route
            path="/customize-product-lead-magnet"
            element={
              <Wrapper
                Page={CustomizeLeadMagnet}
                props={{ type: "product", sidebarTitle: "Products" }}
              />
            }
          />
          <Route
            path="/customize-ebook-lead-magnet"
            element={
              <Wrapper
                Page={CustomizeLeadMagnet}
                props={{ type: "ebook", sidebarTitle: "Lead Magnet: Ebook" }}
              />
            }
          />
          <Route
            path="/customize-video-lead-magnet"
            element={
              <Wrapper
                Page={CustomizeLeadMagnet}
                props={{ type: "video", sidebarTitle: "Lead Magnet: Video" }}
              />
            }
          />
          {items.map((item, index) => (
            <Route
              key={index}
              path={`/customize-${item.type}-email`}
              element={
                <Wrapper
                  Page={CustomizeProductEmail}
                  props={{ type: item.type, sidebarTitle: item.sidebarTitle }}
                />
              }
            />
          ))}

          <Route
            path="/lead-magnet-quiz/ebook/:username"
            element={
              <LeadMagnetEbookQuiz props={{ type: "ebook", title: "Ebook" }} />
            }
          />
          <Route
            path="/lead-magnet-quiz/video/:username"
            element={
              <LeadMagnetEbookQuiz props={{ type: "video", title: "Video" }} />
            }
          />
          <Route
            path="/lead-magnet-quiz/service/:username"
            element={
              <LeadMagnetEbookQuiz
                props={{ type: "service", title: "Service" }}
              />
            }
          />
          <Route
            path="/lead-magnet-quiz/product/:username"
            element={
              <LeadMagnetProductQuiz
                props={{ type: "product", title: "Product" }}
              />
            }
          />

          <Route path="/store" element={<Wrapper Page={Store} />} />
          <Route path="/analytics" element={<Wrapper Page={Analytics} />} />
          <Route path="/questionnaire" element={<Wrapper Page={Temp} />} />
          <Route path="/feedback" element={<Wrapper Page={FeedbackForm} />} />
          <Route path="/support" element={<Wrapper Page={SupportPage} />} />
          <Route path="/settings" element={<Wrapper Page={Settings} />} />
          <Route
            path="/select-ebook"
            element={<Wrapper Page={SelectEbook} />}
          />
          <Route
            path="/ebook-mini-landing"
            element={<Wrapper Page={EbookMiniLanding} />}
          />
          <Route path="/my-page" element={<Wrapper Page={PreviewTemplate} />} />
          <Route path="/edit-page" element={<Wrapper Page={EditTemplate} />} />
          <Route
            path="/page-editor1"
            element={<Wrapper Page={PageEditor1} />}
          />
          <Route
            path="/page-editor2"
            element={<Wrapper Page={PageEditor2} />}
          />
          <Route
            path="/page-editor3"
            element={<Wrapper Page={PageEditor3} />}
          />
          <Route path="/page-editor" element={<Wrapper Page={PageEditor4} />} />
          <Route index element={<Navigate to="/auth" />} />
          <Route path="/plans" element={<Wrapper Page={Plan} />} />
          <Route path="/:username" element={<UserProfile />} />
        </Routes>
      </ThemeProvider>
    </>
  );
}

const Wrapper = ({ Page, props }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [subscribed, setSubscribed] = useState(null);

  return (
    <>
      <div className="">
        <>
          <div
            className="sticky top-0 z-50 p-2 text-4xl bg-primary text-white lg:hidden"
            onClick={() => {
              setOpenMenu(!openMenu);
            }}
          >
            <HiOutlineBars3 />
          </div>

          {openMenu && (
            <>
              <SidebarForMobile
                subscribed={subscribed}
                openMenu={openMenu}
                setOpenMenu={setOpenMenu}
              />
            </>
          )}

          <div className="hidden lg:flex">
            <Sidebar />
          </div>
        </>

        <div className="min-h-screen flex-grow lg:ml-20">
          {/* <div className="min-h-screen flex-grow lg:ml-20"> */}
          <Page props={props}></Page>
        </div>
      </div>
    </>
  );
};

export default App;
