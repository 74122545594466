import "css/template6.css";
import UserPic from "assets/template7user.jfif";
import TemplateCover from "assets/template7covercurved.jfif";
import TemplateCover1 from "assets/template7cover.jpg";
import LinkedInSVG from "assets/linkedin.svg";
import LinkSVG from "assets/Linkblue.svg";
import React from "react";
import InstaSVG from "assets/insta.svg";
import XSVG from "assets/x.svg";
import DribbleSVG from "assets/dribble.svg";
import { useNavigate } from "react-router-dom";
import FacebookSVG from "assets/facebook.svg";

import {
  FaDribbble,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
} from "react-icons/fa6";

const Template1 = ({ data }) => {
  const navigate = useNavigate();
  const socialIcons = {
    Instagram: InstaSVG,
    Twitter: XSVG,
    Facebook: FacebookSVG,
    Dribble: DribbleSVG,
    LinkedIn: LinkedInSVG,
  };

  return (
    <div className="section-cont relative">
      <img src={TemplateCover} alt="Cover Photo" className="imgTemplate7" />
      <img
        src={TemplateCover1}
        alt="Cover Photo"
        className="imgTemplate71 overlay"
      />

      <img src={UserPic} alt="User" className="userImgtemplate7" />

      <div className="userInfoooooo">
        <h2 className="UserHeadingTemplate7 font-sans text-black/70">
          George Bellis
        </h2>
        <p className="userParagraphTemplate7 font-sans text-black/70">
          Mindful and Well being
        </p>
      </div>
      <div className="button-divvvvvv grid grid-cols-1 md:grid-cols-1 gap-3 md:gap-5 text-white">
        <button className="button11111">
          <span className="text text-black/80 font-sans">My Ebooks</span>
        </button>
        <button className="button22222">
          <span className="text text-black/80 font-sans">My Store</span>
        </button>
        <button className="button33333">
          <span className="text text-black/80 font-sans">My Videos</span>
        </button>
      </div>
      <div className="social-iconsTemplate7 gap-4">
        <FaDribbble className="h-7 w-7" />
        <FaFacebook className="h-7 w-7" />
        <FaInstagram className="h-7 w-7" />
        <FaLinkedin className="h-7 w-7" />
      </div>
      {!data.subscribed && (<p className="linkleadai-pppppp text-black/80">
        
        <a href="https://linkleads.ai" className="font-semibold">
        Powered by LinkLeads.AI
        </a>
      </p>)}
    </div>
  );
};

export default Template1;
