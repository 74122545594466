import React, { useState, useEffect } from "react";
import { FaCheck } from "react-icons/fa6";
import { createClient } from "contentful";

const client = createClient({
  space: '60rew5hef1yw',
  accessToken: 'm4B-e9c0HFWhlzeBZfi0grKH0QSIIDlLzT3TknflgnM'
});

const SkeletonLoader = () => (
  <div className="flex flex-col lg:flex-row gap-10 mt-10">
    <div className="flex-1 p-4">
      <div className="bg-gray-300 h-10 w-3/4 rounded mb-4"></div>
      <div className="bg-gray-300 h-6 w-3/4 rounded mb-4"></div>
      <div className="bg-gray-300 h-6 w-3/4 rounded mb-4"></div>
      <div className="bg-gray-300 h-6 w-3/4 rounded mb-4"></div>
      <div className="bg-gray-300 h-6 w-3/4 rounded mb-4"></div>
      <div className="bg-gray-300 h-6 w-3/4 rounded mb-4"></div>
    </div>
    <div className="flex justify-center items-center lg:justify-start flex-1 p-4">
      <div className="relative w-full max-w-full aspect-w-16 aspect-h-9">
        <div className="bg-gray-300 h-full w-full rounded-xl"></div>
      </div>
    </div>
  </div>
);

const TestimonialComponent = () => {
  const [heading1, setHeading1] = useState("");
  const [paragraph1, setParagraph1] = useState("");
  const [paragraph2, setParagraph2] = useState("");
  const [paragraph3, setParagraph3] = useState("");
  const [paragraph4, setParagraph4] = useState("");
  const [paragraph5, setParagraph5] = useState("");
  const [paragraph6, setParagraph6] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        const entry = await client.getEntry("3N4WO2t36fwfUeVELU7b0H");
        setHeading1(entry.fields.heading1);
        setParagraph1(entry.fields.paragraph1.content[0].content[0].value);
        setParagraph2(entry.fields.paragraph2.content[0].content[0].value);
        setParagraph3(entry.fields.paragraph3.content[0].content[0].value);
        setParagraph4(entry.fields.paragraph4.content[0].content[0].value);
        setParagraph5(entry.fields.paragraph5.content[0].content[0].value);
        setParagraph6(entry.fields.paragraph6.content[0].content[0].value);
        
        const imageId = entry.fields.image1.sys.id;
        const asset = await client.getAsset(imageId);
        const imageUrl = "https:" + asset.fields.file.url;
        setImageURL(imageUrl);
      } catch (error) {
        console.error("Error fetching entry data: ", error);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, []);

  return (
    loading ? <SkeletonLoader /> : (
      <div className="flex flex-col lg:flex-row gap-10 mt-10">
        <div className="flex-1 p-4">
          <div>
            <h1 className="text-3xl text-primary font-bold">{heading1}</h1>
            <p className="mt-4 text-black/80 text-lg">{paragraph1}</p>
            <p className="mt-4 text-black/80 text-lg">{paragraph2}</p>
            <p className="mt-4 text-black/80 text-lg">{paragraph3}</p>
          </div>
          <div className="mt-8">
            <p className="flex items-center mb-4 text-black/80 text-lg">
              <FaCheck className="text-green-500 mr-2" />
              {paragraph4}
            </p>
            <p className="flex items-center mb-4 text-black/80 text-lg">
              <FaCheck className="text-green-500 mr-2" />
              {paragraph5}
            </p>
            <p className="flex items-center mb-4 text-black/80 text-lg">
              <FaCheck className="text-green-500 mr-2" />
              {paragraph6}
            </p>
          </div>
        </div>
        <div className="flex justify-center items-center lg:justify-start flex-1 p-4">
          <div className="relative w-full max-w-full aspect-w-16 aspect-h-9">
            <img
              src={imageURL}
              alt="Fetched Asset"
              className="rounded-xl border-16 border-primary object-contain shadow-2xl w-full"
            />
          </div>
        </div>
      </div>
    )
  );
};

export default TestimonialComponent;
