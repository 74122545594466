import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Button from "components/Button/Button";
import { useNavigate } from "react-router-dom";

import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import Loading from "components/Loading/Loading";
const TimePopup = ({
  selectedData,
  username,
  timer,
  setTimer,
  data,
  currentStep,
  setCurrentStep,
  totalPrice,
  type,
}) => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentOptions,setPaymentOptions] = useState({});

  const [email, setEmail] = useState();


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      toast.error("Stripe not loaded");
      return;
    }
    
    if (!email) {
      toast.error("Email is required");
      return;
    }
  
    const emailPattern = /[a-zA-Z][a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;
    if (!emailPattern.test(email)) {
      toast.error("Invalid email address:" + email);
      return;
    }
    const {error: submitError} = await elements.submit();
    console.log(data.redirecting_text)
    setIsLoading(true);
    const clientSecret = paymentOptions.clientSecret
    const { error } = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        return_url: data.thank_you_user_link_text,
        payment_method_data: {
          billing_details: {
            email: email
          }
        }
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };
  const handleSubmitEmail = async () => {
    const ids = selectedData.map(ebook => ebook.id).join(',');
    if (!email) {
      toast.error("Email is required");
      return;
    }
  
    const emailPattern = /[a-zA-Z][a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;
    if (!emailPattern.test(email)) {
      toast.error("Invalid email address");
      return;
    }
    console.log(type)
    try {
      const requestData = {
        email: email,
        type: type,
        data: selectedData,
        username: username,
        id: ids
      };

      const response = await axios.post(
        "/lead-magnet/send-email",
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Update response:", response.data);
      if (response.status === 201) {
        toast.success("Emailed");
        setCurrentStep(currentStep + 1);
      }
    } catch (error) {
      console.error("Error updating data:", error);
      toast.warning("Failed to update data");
    }
  };

  let intervalId;

  const startTimer = (timer, setTimer, setCurrentStep, currentStep) => {
    if (timer >= 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => {
          if (currentStep === 6) {
            clearInterval(intervalId);
            return -2;
          }
          if (prevTimer === 0) {
            setCurrentStep((prevStep) => prevStep + 1);
            console.log("Timer end");
            clearInterval(intervalId);
            return -1;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
  };

  const stopTimer = () => {
    clearInterval(intervalId);
  };

  useEffect(() => {
    const fetchPaymentIntent = async () => {
      const ids = selectedData.map(ebook => ebook.id).join(',');

      try {
        const response = await axios.get('/payment/get-intent', {
          params: {
            username: 'yourUsername',
            type: 'ebook',
            id: ids
          }
        });
        
        if (response.data && response.data.clientSecret) {
          setPaymentOptions({
            clientSecret: response.data.clientSecret
          });
        }
      } catch (error) {
        console.error('Error fetching payment intent:', error);
      }
    };

    if (currentStep === 4) {
      // fetchPaymentIntent();
    }
  }, [currentStep, selectedData]);

  useEffect(() => {
    startTimer(timer, setTimer, setCurrentStep, currentStep);
    return () => stopTimer();
  }, [timer, setCurrentStep, currentStep]);
  const paymentElementOptions = {
    style: {
      base: {
        color: "#ffffff",
        fontFamily: "Arial, sans-serif",
        fontSize: "16px",
        "::placeholder": { color: "#ffffff" },
      },
    },
  };
  return (
    <>

    
      {currentStep === 5 && (
        <>
          {/* {data.subscription === false ? ( */}
          {true ? (
            <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
              <section className="rounded-xl h-[470px] bg-[#23439D] w-[322px] md:w-[350px] bg-no-repeat relative">
                <div className="absolute inset-0 py-10">
                  <div className="mx-6 p-3 !bg-[#435BA9] rounded-md shadow-md mt-5">
                    <p className="text-center my-2 text-white">You have</p>
                    <p className="text-[45px] text-center text-white font-semibold leading-10 tracking-widest uppercase">
                      {Math.floor(timer / 60)
                        .toString()
                        .padStart(2, 0)}{" "}
                      :{" "}
                      {Math.floor(timer % 60)
                        .toString()
                        .padStart(2, 0)}
                    </p>
                    <div className="flex items-center gap-4 justify-center text-white/70 text-[18px] my-3 uppercase">
                      <p>Minutes</p>
                      <p>Seconds</p>
                    </div>
                    <p className="text-center text-white/80 text-[18px] font-medium">
                      left to sign up to get access!
                    </p>
                  </div>

                  <p className="text-center my-3 text-white/80 text-lg font-medium">
                    {data.form_text}
                  </p>

                  <div className="flex flex-col gap-y-3 items-center justify-center my-5">
                    <input
                      style={{ height: 36 }}
                      type="email"
                      name="email"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      placeholder="example@gmail.com"
                      className="py-3 px-5 w-80 rounded-md text-white font-medium bg-[#6173AF] placeholder:text-white/70"
                    />
                    <Button
                      text="Get the Ebook"
                      onClick={(e) => {
                        handleSubmitEmail();
                      }}
                      className="py-1 px-[93px] rounded-xl text-primary font-medium bg-white mt-5"
                    />
                  </div>
                </div>
              </section>
            </div>
          ) : totalPrice > 0 ? (
            <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
              <div className="bg-[#23439D] p-6 rounded-lg relative w-full max-w-md">
                <div className="flex justify-center gap-4 mb-4">
                  <button className="py-2 px-4 bg-white text-[#23439D] rounded-md">
                    Credit Card
                  </button>
                  <button className="py-2 px-4 text-white">Paypal</button>
                </div>
                <div>
                  <div className="mx-6 p-3 !bg-[#435BA9] rounded-md shadow-md mt-5">
                    <p className="text-center my-2 text-white">You have</p>
                    <p className="text-[45px] text-center text-white font-semibold leading-10 tracking-widest uppercase">
                      {Math.floor(timer / 60)
                        .toString()
                        .padStart(2, 0)}{" "}
                      :{" "}
                      {Math.floor(timer % 60)
                        .toString()
                        .padStart(2, 0)}
                    </p>
                    <div className="flex items-center gap-4 justify-center text-white/70 text-[18px] my-3 uppercase">
                      <p>Minutes</p>
                      <p>Seconds</p>
                    </div>
                    <p className="text-center text-white/80 text-[18px] font-medium">
                      left to sign up and get access!
                    </p>
                  </div>

                  <p className="text-center my-3 text-white/80 text-lg font-medium">
                    {data.form_text}
                  </p>

                  <div className="flex flex-col gap-y-3 items-center justify-center my-5">
                    <input
                      style={{ height: 36 }}
                      type="email"
                      name="email"
                      placeholder="example@gmail.com"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      className="py-3 px-5 w-80 rounded-md text-white font-medium bg-[#6173AF] placeholder:text-white/70"
                    />
                    <form className="w-full max-w-md mx-auto p-3 bg-[#6173AF] shadow-md rounded-lg mt-3">
                      <PaymentElement />
                      <button
                        style={{ height: 36 }}
                        type="submit"
                        onClick={handleSubmit}
                        className="w-full bg-white hover:bg-gray-200 text-[#23439D] font-medium py-2 px-4 rounded-3xl mt-5"
                      >
                        Get the Ebook
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
              <section className="rounded-xl h-[470px] bg-[#23439D] w-[322px] md:w-[350px] bg-no-repeat relative">
                <div className="absolute inset-0 py-10">
                  <div className="mx-6 p-3 !bg-[#435BA9] rounded-md shadow-md mt-5">
                    <p className="text-center my-2 text-white">You have</p>
                    <p className="text-[45px] text-center text-white font-semibold leading-10 tracking-widest uppercase">
                      {Math.floor(timer / 60)
                        .toString()
                        .padStart(2, 0)}{" "}
                      :{" "}
                      {Math.floor(timer % 60)
                        .toString()
                        .padStart(2, 0)}
                    </p>
                    <div className="flex items-center gap-4 justify-center text-white/70 text-[18px] my-3 uppercase">
                      <p>Minutes</p>
                      <p>Seconds</p>
                    </div>
                    <p className="text-center text-white/80 text-[18px] font-medium">
                      left to sign up to get access!
                    </p>
                  </div>

                  <p className="text-center my-3 text-white/80 text-lg font-medium">
                    {data.form_text}
                  </p>

                  <div className="flex flex-col gap-y-3 items-center justify-center my-5">
                    <input
                      style={{ height: 36 }}
                      type="email"
                      name="email"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      placeholder="example@gmail.com"
                      className="py-3 px-5 w-80 rounded-md text-white font-medium bg-[#6173AF] placeholder:text-white/70"
                    />
                    <Button
                      text="Get the Ebook"
                      onClick={handleSubmitEmail}
                      className="py-1 px-[93px] rounded-xl text-primary font-medium bg-white mt-5"
                    />
                  </div>
                </div>
              </section>
            </div>
          )}
        </>
      )}
      {currentStep === 6 &&
        (timer !== -1 ? (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
            <div className="">
              <section className="rounded-xl h-[243px] bg-[#23439D] w-[300px] md:w-[338px] bg-no-repeat relative">
                <div className="absolute inset-0 flex flex-col justify-center">
                  <p className="text-center text-white uppercase text-4xl">
                    {data.thank_you_title}
                  </p>
                  <p className="text-md my-3 text-center text-white/80 font-medium">
                    {data.thank_you_text}
                  </p>
                  <div className="flex flex-col gap-y-3 items-center justify-center my-5">
                    <Button
                      onClick={() => {
                        window.open(
                          data.thank_you_user_link_text.startsWith("https:/")
                            ? data.thank_you_user_link_text
                            : "https://" + data.thank_you_user_link_text,
                          "_self"
                        );
                      }}
                      text="Go to my page"
                      className="uppercase py-3 px-10 rounded-md text-white font-medium bg-[#435BA9]"
                    />
                  </div>
                </div>
              </section>
            </div>
          </div>
        ) : (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-[#28439D]  flex flex-col items-center p-20 rounded-2xl">
              <p className="uppercase text-2xl font-semibold text-white mb-3">
                {data.timer_ran_out_text}
              </p>
              <Button
                text={data.restart_timer_text}
                className="uppercase px-6 py-2 text-white font-medium rounded-lg bg-[#6F7FB3CC] text-lg"
                onClick={() => navigate(0)}
              />
            </div>
          </div>
        ))}
    </>
  );
};

export default TimePopup;
