import ZapSVG from "assets/zap.svg";
import LinkSVG from "assets/Link.svg";
import ShareSVG from "assets/share.svg";
import React, { useEffect, useState } from "react";
import Button from "components/Button/Button";
import PricingSection from "components/PricingSection/PricingSection";
import { useNavigate } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
import axios from "axios";
import { toast } from "react-toastify";

const Navbar = ({ title }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [data, setData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/user-details", {
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.status === 200) {
          setData(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  
  return (
    <>
      {/* Top Navbar, Buttons */}
      <div className="flex flex-col md:flex-row justify-between items-center pb-5">
        <h3 className="text-2xl font-medium">{title}</h3>
        <div className="flex gap-5">
          <CopyToClipboard
            text={"https://linkleads.ai/" + data.username}
            onCopy={() => toast.success("Url coppied to Clipboard")}
          >
            <button>
              <img src={LinkSVG} alt="" />
            </button>
          </CopyToClipboard>

          {/* <Button
            text="Share"
            src={ShareSVG}
            className="flex flex-row-reverse items-center gap-1 border border-gray-300 px-3 md:px-6 py-2 rounded-full font-bold text-xs bg-white"
          /> */}
          {/* tobesoon-credit */}
          {/* <Button
                    text="0/20 Credits"
                    className="flex items-center gap-1 border border-gray-300 px-3 md:px-6 py-2 rounded-full font-bold text-xs bg-[#28439D1A]"
                  /> */}
          <Button
            onClick={() => setShowPopup(true)}
            text="Upgrade"
            src={ZapSVG}
            className="flex flex-row-reverse items-center gap-1 border border-gray-300 px-3 md:px-6 py-2 rounded-full font-bold text-xs bg-primary text-white"
          />
          {showPopup && (
            <div className="fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
              <div className="bg-white rounded-lg">
                <PricingSection
                  setShowPopup={setShowPopup}
                  subscribed={data.subscribed}
                  email={data.email}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {/* {Use templates here} */}
    </>
  );
};

export default Navbar;
