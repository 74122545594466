import React, { useState, useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import Loading from "components/Loading/Loading";

const SalesPage = () => {
  const navigate = useNavigate()
  const [selectedRows, setSelectedRows] = useState([]);
  const [editorData, setEditorData] = useState("");
  const [subjectData, setSubjectData] = useState("");
  const [emails, setEmails] = useState([]);
  const [isSubscribed, setisSubscribed] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchEmails = async () => {
      try {
        const response = await axios.get("/lead-magnet/get-emails");

        // setEmails(response.data.emails);
        setIsLoading(false);
        // Select all emails by default
        setSelectedRows(response.data.emails.map((email, index) => index + 1));
      } catch (error) {
        if (error.response && error.response.status === 403) {
          setisSubscribed(false);
        }
        console.error("Error fetching emails:", error);
        setIsLoading(false);
      }
    };

    fetchEmails();
  }, []);

  const handleCheckboxChange = (event, id) => {
    if (event.target.checked) {
      setSelectedRows([...selectedRows, id]);
    } else {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
    }
  };

  const editorConfiguration = {
    toolbar: ["bold", "italic", "bulletedList", "numberedList", "blockQuote"],
  };

  const handleSendEmails = async () => {
    try {
      const response = await axios.post("/lead-magnet/send-campaign", {
        emails: selectedRows.map((index) => emails[index - 1]),
        subject: subjectData,
        body: editorData,
      });
      console.log("Broadcast sent successfully:", response.data.message);
      toast.success(response.data.message);
    } catch (error) {
      console.error("Error sending broadcast:", error);
    }
  };

  return !isSubscribed ? (
    <div class="w-full h-screen flex items-center justify-center bg-gray-800 bg-opacity-75">
      <div class="bg-white p-6 rounded-lg shadow-lg max-w-sm">
        <h2 class="text-lg font-semibold mb-4">Subscribe to Unlock</h2>
        <p class="mb-6">Please subscribe to unlock this feature.</p>
        <button
          class="bg-primary text-white py-2 px-4 rounded-md w-full hover:bg-blue-600"
          onclick={()=>navigate('/plans')}
        >
          Subscribe Now
        </button>
      </div>
    </div>
  ) : isLoading ? 
  (<Loading />)
  :(
    
    <div className="flex flex-col md:flex-row">
      <div className="w-full p-4">
        <h2 className="text-lg font-semibold mb-4">Subject Editor</h2>
        <CKEditor
          editor={ClassicEditor}
          config={editorConfiguration}
          data={subjectData}
          onReady={(editor) => {
            // You can handle editor ready logic if needed
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            setSubjectData(data);
          }}
        />
        <h2 className="text-lg font-semibold mb-4 mt-8">Body Editor</h2>
        <CKEditor
          editor={ClassicEditor}
          config={editorConfiguration}
          data={editorData}
          onReady={(editor) => {
            // You can handle editor ready logic if needed
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            setEditorData(data);
          }}
        />
        <button
          className="bg-primary text-white py-2 px-4 mt-4 rounded-md hover:bg-blue-600"
          onClick={handleSendEmails}
        >
          Send Email Campaign
        </button>
      </div>
      <div className="min-w-fullp-4">
        <h2 className="text-lg font-semibold mb-4">Recipient List</h2>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <div className="overflow-x-auto">
            <table className="mx-auto divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr className="text-center">
                  <th
                    scope="col"
                    className="px-12 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Select
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {emails.map((email, index) => (
                  <tr key={index} className="text-center">
                    <td className="px-12 py-4 whitespace-nowrap text-sm text-gray-500">
                      {email}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <input
                        type="checkbox"
                        onChange={(e) => handleCheckboxChange(e, index + 1)}
                        checked={selectedRows.includes(index + 1)}
                        className="h-4 w-4 text-blue-600 focus:ring-blue-500"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default SalesPage;
