import React from 'react';

const SupportPage = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md text-center">
        <h2 className="text-2xl font-bold mb-6">Need Support?</h2>
        <p className="mb-4">
          If you need any help, please reach out to our support team.
        </p>
        <a
          href="mailto:support@linkleads.ai"
          className="text-blue-500 hover:underline"
        >
          support@linkleads.ai
        </a>
      </div>
    </div>
  );
};

export default SupportPage;
