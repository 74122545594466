import UserPic from "assets/userPic.svg";
import InstaSVG from "assets/insta.svg";
import XSVG from "assets/x.svg";
import DribbleSVG from "assets/dribble.svg";
import LinkedInSVG from "assets/linkedin.svg";
import FacebookSVG from "assets/facebook.svg";
import TemplateCover from "assets/template1.jpeg";

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

const htmlContentTemplate = `
<div style="padding: 20px; background-image: url('%%coverPic%%'); background-size: cover; background-position: center; border-radius: 15px; box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3); color: #fff;">
    <div style="text-align: center; background: rgba(0, 0, 0, 0.5); padding: 20px; border-radius: 20px; max-width: 300px; margin: auto;">
        <img src="%%profilePic%%" alt="Profile Picture" style="border-radius: 50%; width: 120px; height: 120px; border: 4px solid #fff; box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);" />
        <h1 style="font-size: 28px; margin-top: 15px;">%%name%%</h1>
        <p style="font-size: 18px; font-style: italic;">%%profession%%</p>
        <p style="margin-top: 10px; line-height: 1.5;">%%description%%</p>
    </div>
    <div style="text-align: center; margin-top: 25px;">
        %%basicLinks%%
    </div>
    <div style="text-align: center; margin-top: 25px;">
        <button id="productsButton" style="background-color: #ff6347; color: #fff; padding: 12px 24px; border: none; border-radius: 7px; margin: 8px;">Products</button>
        <button id="videosButton" style="background-color: #4682b4; color: #fff; padding: 12px 24px; border: none; border-radius: 7px; margin: 8px;">Videos</button>
    </div>
</div>
`;

const AITemplate = ({ data }) => {
  const [pageContent, setPageContent] = useState("");
  const navigate = useNavigate();
  const [prompt, setPrompt] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleSubmit = async () => {
    setIsLoading(true);
    setMessage("Template is being generated, please stay on this page.");

    try {
      const response = await axios.get(
        `/ai/create-new-template?userRequirement=${prompt}&updateTemplate=${isUpdate}`
      );
      toast.success("Template created successfully!");
    } catch (error) {
      toast.error("There was an error creating the template.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (data) {
      let backgroundStyle = "";
      if (data.background.type === "Image") {
        backgroundStyle = `background-image: url('${data.coverPic}'); background-size: cover;`;
      } else {
        backgroundStyle = `background: linear-gradient(${data.background.gradient_1}, ${data.background.gradient_2});`;
      }

      let content = data.template_html
        .replace(
          '<div style="padding: 20px;">',
          `<div style="${backgroundStyle} padding: 20px;">`
        )
        .replace("%%profilePic%%", data.profilePic || UserPic)
        .replace("%%coverPic%%", data.coverPic || TemplateCover)
        .replace("%%name%%", data.name)
        .replace("%%profession%%", data.profession)
        .replace("%%description%%", data.description)
        .replace(
          "%%basicLinks%%",
          data.basic_links
            .map(
              (link) =>
                `<a href="${
                  link.url
                }" style="margin-right: 10px;"><img src="${getIcon(
                  link.title
                )}" alt="${
                  link.title
                }" style="width: 24px; height: 24px;"/></a>`
            )
            .join("")
        );

      setPageContent(content);
    }
  }, [data]);

  useEffect(() => {
    if (pageContent) {
      const productsButton = document.getElementById("productsButton");
      const videosButton = document.getElementById("videosButton");
      const linksButton = document.getElementById("servicesButton");

      if (productsButton)
        productsButton.onclick = () =>
          navigate(`/lead-magnet-quiz/product/${data.username}`);
      if (videosButton)
        videosButton.onclick = () =>
          navigate(`/lead-magnet-quiz/video/${data.username}`);
      if (linksButton)
        linksButton.onclick = () =>
          navigate(`/lead-magnet-quiz/services/${data.username}`);
    }
  }, [pageContent, data.username, navigate]);

  const getIcon = (type) => {
    switch (type) {
      case "Instagram":
        return InstaSVG;
      case "Twitter":
        return XSVG;
      case "Facebook":
        return FacebookSVG;
      case "Dribble":
        return DribbleSVG;
      case "LinkedIn":
        return LinkedInSVG;
      default:
        return null;
    }
  };

  return <div dangerouslySetInnerHTML={{ __html: pageContent }} />;
};

export default AITemplate;
