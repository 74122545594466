import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProductCard from "../ProductCard/ProductCard";
import Button from "../Button/Button";
import axios from "axios";
import AISuggestions from "components/AI/Suggestion";

const AddService = ({ categoryId, setCurrentStep, currentStep }) => {
  const [formData, setFormData] = useState({
    id: -1,
    title: "",
    url: "",
    image: null,
    imageName: "",
    category_id: categoryId,
  });
  const [imageUrl, setImageUrl] = useState(null);
  const [data, setData] = useState({});
  const [suggestions, setSuggestions] = useState([]);
  const [timer, setTimer] = useState(null);
  const [services, setServices] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/user-details", {
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.status === 200) {
          setData(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchEbooks = async () => {
      try {
        const response = await axios.get("/service/all", {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.status === 200) {
          setServices(
            response.data.filter((ebook) => ebook.category_id === categoryId)
          );
        }
      } catch (error) {
        console.error("Error fetching ebooks:", error);
      }
    };

    fetchEbooks();
  }, []);

  const handleFormChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });

    if (id === "title" && data.subscribed) {
      clearTimeout(timer);
      const newTimer = setTimeout(async () => {
        try {
          const response = await axios.get(
            `/ai/autocomplete?type=service&userRequirement=${value}`
          );
          if (response.status === 200) {
            setSuggestions(response.data.suggestions);
          }
        } catch (error) {
          console.error("Error fetching autocomplete suggestions:", error);
        }
      }, 500);
      setTimer(newTimer);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setFormData({ ...formData, title: suggestion });
    setSuggestions([]);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFormData({
      ...formData,
      image: file,
      imageName: file.name,
      imageUrl: "",
    });
    setImageUrl(URL.createObjectURL(file));
  };

  const uploadService = async (e) => {
    e.preventDefault();

    if(!formData.url){
      toast.error("Please enter a url")
      return
    }

    try {
      if (formData.id != -1) {
        const response = await axios.post("/service/" + formData.id, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (response.status === 200) {
          toast.success("Service updated!");
          setFormData({
            id: -1,
            title: "",
            url: "",
            image: null,
            imageName: "",
            category_id: categoryId,
          });
          setImageUrl(null);
        }
        return;
      } else if (!formData.image && !formData.imageUrl) {
        toast.warning("Please select an image!");
        return;
      }

      const serviceData = new FormData();
      serviceData.append("title", formData.title);
      serviceData.append("url", formData.url);
      serviceData.append("category_id", formData.category_id);
      serviceData.append("image", formData.image);
      serviceData.append("imageUrl", formData.imageUrl);

      const response = await axios.post("/service", serviceData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 201) {
        toast.success("Service created!");
        setFormData({
          id: -1,
          title: "",
          url: "",
          image: null,
          imageName: "",
          category_id: categoryId,
        });
        setImageUrl(null);
      }

      const response2 = await axios.get("/service/all", {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response2.status === 200) {
        setServices(
          response2.data.filter((ebook) => ebook.category_id === categoryId)
        );
      }
    } catch (error) {
      console.error("Error uploading service:", error);
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.error);
      }
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/service/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      toast.success("Service deleted successfully!");
      setServices(services.filter((ebook) => ebook.id !== id));
    } catch (error) {
      console.error("Error deleting ebook:", error);
      toast.error("Error deleting service.");
    }
  };

  const handleEdit = (id) => {
    const service = services.find((ebook) => ebook.id === id);
    setFormData({
      ...formData,
      id: id,
      title: service.title,
      description: service.description,
      url: service.url,
      image: null,
      imageName: "",
      category_id: service.category_id,
    });
    setImageUrl(service.image);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleCancel = () => {
    setFormData({
      title: "",
      url: "",
      image: null,
      imageName: "",
      category_id: categoryId,
    });
    setImageUrl(null);
    setCurrentStep(currentStep - 1);
  };

  return (
    <div className="lg:mx-20 w-full">
      <AISuggestions
        formData={formData}
        setFormData={setFormData}
        setImageUrl={setImageUrl}
        subscribed={data.subscribed}
        type={"Service"}
      />
      <form onSubmit={uploadService}>
        <div className="lg:border border-gray-200 rounded-lg bg-white p-3 lg:px-32">
          <p className="lg:my-5 text-2xl font-normal text-black">
            Add New Service
          </p>
          <p className="block text-black/70 mt-3">Title</p>
          <input
            id="title"
            type="text"
            maxlength="250"
            value={formData.title}
            onChange={handleFormChange}
            className="mt-1 px-2 block w-full rounded-md border h-10 border-gray-300 shadow-sm focus:ring-primary focus:border-primary sm:text-sm"
          />
          {suggestions.length > 0 && (
            <ul className="border rounded-md mt-1 w-full bg-white">
              {suggestions.map((suggestion, index) => (
                <li
                  key={index}
                  onClick={() => handleSuggestionClick(suggestion)}
                  className="cursor-pointer p-2 hover:bg-gray-200"
                >
                  {suggestion}
                </li>
              ))}
            </ul>
          )}
          <p className="block text-black/70 mt-3">URL</p>
          <input
            id="url"
            type="text"
            maxlength="250"
            value={formData.url}
            onChange={handleFormChange}
            className="mt-1 px-2 block w-full rounded-md border h-10 border-gray-300 shadow-sm focus:ring-primary focus:border-primary sm:text-sm"
          />
          <div className="flex items-center gap-2 md:gap-20">
            <div className="flex items-center flex-col">
              <label htmlFor="imageUpload" className="font-normal my-1 sm:mt-5">
                Upload Image
              </label>
              <input
                id="imageUpload"
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                className="hidden"
              />
              <label
                htmlFor="imageUpload"
                className="w-full inline-flex justify-center px-4 py-2 text-sm font-medium bg-transparent border-2 border-gray-300 rounded-3xl sm:mt-0 sm:w-auto sm:text-sm hover:bg-gray-100 hover:shadow-md cursor-pointer"
              >
                {formData.imageName || "Upload Image"}{" "}
              </label>
            </div>
          </div>
          <p className="text-black/70 mt-5">Preview</p>
          <div className="border flex justify-center items-center flex-col md:flex-row gap-2 border-gray-200 rounded-md my-3 py-5">
            <ProductCard imageUrl={imageUrl} productName={formData.title} />
          </div>
          <div className="flex justify-between">
            <div className="underline text-[#3855B3] flex flex-col leading-8"></div>
            <div className="flex items-center gap-3 mt-20 mb-20">
              <Button
                text="Cancel"
                className="flex items-center gap-1 border border-gray-300 px-3 md:px-6 py-2 rounded-full font-normal text-xs text-black bg-white"
                onClick={handleCancel}
              />
              <Button
                text="Save"
                type="submit"
                className="flex items-center gap-1 border border-gray-300 px-3 md:px-6 py-2 rounded-full font-normal text-xs text-white bg-primary hover:bg-[#3855b3da] duration-300"
              />
            </div>
          </div>
        </div>
      </form>
      <div className="mt-10 flex gap-3 flex-wrap">
        {services.map((product) => (
          <ProductCard
            key={product.id}
            id={product.id}
            imageUrl={product.image + "?" + Math.random()}
            onEdit={handleEdit}
            onDelete={handleDelete}
            productName={product.title}
          />
        ))}
      </div>
    </div>
  );
};

export default AddService;
