import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { getData } from "country-list";
import AISVG from "../assets/aisvg.svg";
import UserIcon from "../assets/userPic.svg";
import { toast } from "react-toastify";
import Loading from "../components/Loading/Loading";
import ChatBot from "../components/ChatBox/ChatBox";
import Navbar from "../components/Navbar/Navbar";
import { useNavigate } from "react-router-dom";

const Sales = () => {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [showWithdrawPopup, setShowWithdrawPopup] = useState(false);
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [selectedCountry, setSelectedCountry] = useState({
    value: "US",
    label: "United States",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [shouldRenderUI, setShouldRenderUI] = useState(false);
  const [salesData, setSalesData] = useState([]);
  const [balance, setBalance] = useState(0);
  const [totalSalesAmount, setTotalSalesAmount] = useState(0);
  const [haveConnectAccount, setHaveConnectAccount] = useState(0);
  const [data, setData] = useState({});

  const countries = getData().map((country) => ({
    value: country.code,
    label: country.name,
  }));

  useEffect(() => {
    const fetchSales = async () => {
      try {
        const response = await axios.post("/payment/sales");
        const { sales, total_sales_amount, balance, have_connect_account } =
          response.data;
        setSalesData(sales);
        setHaveConnectAccount(have_connect_account);
        setBalance(balance);
        setTotalSalesAmount(total_sales_amount);
        setIsLoading(false);
        setShouldRenderUI(true);
      } catch (error) {
        console.error("Error fetching sales data:", error);
        setIsLoading(false);
        setShouldRenderUI(false);
      }
    };
    const fetchUserData = async () => {
      try {
        const response = await axios.get("/user-details", {
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.status === 200) {
          console.log(response.data);
          setData(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const fetchData = async () => {
      await Promise.all([fetchSales(), fetchUserData()]);

      setIsLoading(false);
      setShouldRenderUI(true);
    };

    fetchData();
  }, []);

  const updateAccount = async () => {
    try {
      setIsLoading(true);
      setShouldRenderUI(false);
      const response = await axios.get("payment/update-account");

      if (response.status === 200) {
        window.location.href = response.data.url;
      }
      console.log(response.data);
      if (response.status === 200) {
        window.location.href = response.data.url;
      }
      setShowPopup(false);
    } catch (error) {
      console.error("Error creating Stripe Connect account:", error);
      toast.error("An error have occured");
      setIsLoading(false);
      setShouldRenderUI(true);
    }
  };

  const handleConnectClick = () => {
    setShowPopup(true);
  };

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
  };

  const handleCreateAccount = async () => {
    try {
      const response = await axios.post("payment/create-account", {
        country: selectedCountry.value,
      });
      console.log(response.data);
      if (response.status === 200) {
        window.location.href = response.data.url;
      }
      setShowPopup(false);
    } catch (error) {
      console.error("Error creating Stripe Connect account:", error);
    }
  };

  const handleWithdrawClick = () => {
    setShowWithdrawPopup(true);
  };

  const handleWithdrawAmountChange = (e) => {
    setWithdrawAmount(e.target.value);
  };

  const handleWithdrawSubmit = async () => {
    const amount = parseFloat(withdrawAmount);

    if (amount > balance) {
      toast.warn("Entered amount exceeds available balance.");
      setShowWithdrawPopup(false);
      return;
    }

    if (amount < 1) {
      toast.warn("Please enter an amount greater than $1.");
      return;
    }

    try {
      const response = await axios.post("/payment/withdraw", { amount });
      if (response.status === 200) {
        toast.success("Withdrawal successful!");
        setBalance(balance - amount);
      } else {
        toast.error(response.data.error);
      }
      setShowWithdrawPopup(false);
    } catch (error) {
      console.error("Error processing withdrawal:", error);
      toast.error(error.response.data.error);
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      {shouldRenderUI && (
        <>
          <div className="flex flex-col lg:flex-row w-full h-full overflow-hidden">
            <div className="flex-grow bg-[#f6f7fa] px-2 lg:px-16 pt-10">
              <Navbar title={"My Sales"} />
              <div className="bg-white rounded-xl p-2 overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr className="text-center">
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Sale ID
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Purchase Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Amount
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Date
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Type
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {salesData.map((sale, index) => (
                      <tr key={index} className="text-center">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {sale.id}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {sale.description}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {sale.amount}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {sale.email}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {new Date(sale.date).toLocaleDateString()}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {sale.type}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="w-full min-h-screen lg:w-[350px] p-5 bg-white flex items-center flex-col">
              <div className="flex justify-end w-full">
                <button className="shadow-icon p-3">
                  <img src={AISVG} alt="" />
                </button>
              </div>
              <div className="flex flex-col gap-3 items-center relative">
                <div className="relative">
                  <img
                    src={data.profilePic || UserIcon}
                    onError={(e) => {
                      e.target.src = UserIcon;
                    }}
                    className="rounded-full object-cover w-24 h-24"
                    alt=""
                  />
                </div>
                <p className="font-semibold">@{data.username}</p>
              </div>

              <div className="border p-8 rounded-2xl mt-8 w-full">
                <h3 className="font-semibold text-black/70">
                  Total Sales: {totalSalesAmount}
                </h3>
              </div>
              <div className="border p-8 rounded-2xl mt-4 w-full">
                <h3 className="font-semibold text-black/70">
                  Total Balance: {balance}
                </h3>
              </div>

              {haveConnectAccount && (
                <button
                  className="w-full bg-blue-600 text-white hover:ring-blue-300 mt-5 block rounded-xl py-2 px-3 text-center text-lg font-semibold leading-6"
                  onClick={handleWithdrawClick}
                >
                  Withdraw
                </button>
              )}

              <button
                className={`w-full bg-blue-600 text-white hover:ring-blue-300 mt-5 block rounded-xl py-2 px-3 text-center text-lg font-semibold leading-6`}
                onClick={
                  haveConnectAccount ? updateAccount : handleConnectClick
                }
              >
                {haveConnectAccount
                  ? "Update Bank Account"
                  : "Add Bank Account"}
              </button>

              <button
                className="w-full bg-blue-600 text-white hover:ring-blue-300 mt-5 block rounded-xl py-2 px-3 text-center text-lg font-semibold leading-6"
                onClick={() => navigate("/broadcast")}
              >
                Send Email Broadcast
              </button>
            </div>
          </div>
          <div className="">
            <ChatBot />
          </div>
        </>
      )}
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg w-full max-w-md mx-4">
            <h2 className="text-lg font-semibold mb-4">Select Country</h2>
            <Select
              value={selectedCountry}
              onChange={handleCountryChange}
              options={countries}
              className="border p-2 rounded w-full mb-4"
            />
            <div className="flex justify-end">
              <button
                onClick={() => setShowPopup(false)}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mr-2"
              >
                Cancel
              </button>
              <button
                onClick={handleCreateAccount}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Create Account
              </button>
            </div>
          </div>
        </div>
      )}
      {showWithdrawPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg w-full max-w-md mx-4">
            <h2 className="text-lg font-semibold mb-4">Withdraw Amount</h2>
            <input
              type="number"
              value={withdrawAmount}
              onChange={handleWithdrawAmountChange}
              className="border p-2 rounded w-full mb-4"
              placeholder="Enter amount"
            />
            <div className="flex justify-end">
              <button
                onClick={() => setShowWithdrawPopup(false)}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mr-2"
              >
                Cancel
              </button>
              <button
                onClick={handleWithdrawSubmit}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Withdraw
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Sales;
