import "css/template7.css";
import UserPic from "assets/template7user.jfif";
import TemplateCover from "assets/template7covercurved.jfif";
import TemplateCover1 from "assets/template7cover.jpg";
import LinkedInSVG from "assets/linkedin.svg";
import LinkSVG from "assets/Linkblue.svg";
import React from "react";
import InstaSVG from "assets/insta.svg";
import XSVG from "assets/x.svg";
import DribbleSVG from "assets/dribble.svg";
import { useNavigate } from "react-router-dom";
import FacebookSVG from "assets/facebook.svg";
import { FaBook, FaVideo, FaCog, FaLink } from "react-icons/fa";

import {
  FaDribbble,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
} from "react-icons/fa6";

const Template1 = ({ data }) => {
  const navigate = useNavigate();
  const socialIcons = {
    Instagram: InstaSVG,
    Twitter: XSVG,
    Facebook: FacebookSVG,
    Dribble: DribbleSVG,
    LinkedIn: LinkedInSVG,
  };

  return (
    <div className="section-cont relative">
      <img src={TemplateCover} alt="Cover Photo" className="imgTemplate7" />
      <img
        src={TemplateCover1}
        alt="Cover Photo"
        className="imgTemplate81 overlay"
      />

      <img src={UserPic} alt="User" className="userImgtemplate7" />

      <div className="userInfoooooo">
        <h2 className="UserHeadingTemplate7 font-sans text-white">
          Zainab Abbass
        </h2>
        <p className="userParagraphTemplate8 font-sans text-white">
          UX/UI Designer
        </p>
      </div>
      <div className="button-divTemplate8 grid grid-cols-2 gap-3 md:gap-5 text-white">
        <button className="button1Template8 flex flex-col items-center shadow-md">
          <FaBook className="text-green-700 mb-2" />
          <span className="text text-green-700 font-semibold font-sans">
            Ebook
          </span>
        </button>
        <button className="button2Template8 flex flex-col items-center shadow-md">
          <FaVideo className="text-green-700 mb-2" />
          <span className="text text-green-700 font-semibold font-sans">
            Videos
          </span>
        </button>
        <button className="button3Template8 flex flex-col items-center shadow-md">
          <FaCog className="text-green-700 mb-2" />
          <span className="text text-green-700 font-semibold font-sans">
            Services
          </span>
        </button>
        <button className="button4Template8 flex flex-col items-center shadow-md">
          <FaLink className="text-green-700 mb-2" />
          <span className="text text-green-700 font-semibold font-sans">
            Links
          </span>
        </button>
      </div>

      <div className="social-iconsTemplate7 gap-4 mt-6">
        <FaDribbble className="h-7 w-7 text-white" />
        <FaFacebook className="h-7 w-7 text-white" />
        <FaInstagram className="h-7 w-7 text-white" />
        <FaLinkedin className="h-7 w-7 text-white" />
      </div>
      {!data.subscribed && (<p className="linkleadai-pppppp text-white">
        
        <a href="https://linkleads.ai" className="font-semibold">
        Powered by LinkLeads.AI
        </a>
      </p>)}
    </div>
  );
};

export default Template1;
