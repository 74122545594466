import React, { useState, useEffect } from "react";
import { createClient } from 'contentful';

const client = createClient({
  space: '60rew5hef1yw',
  accessToken: 'm4B-e9c0HFWhlzeBZfi0grKH0QSIIDlLzT3TknflgnM'
});

const SkeletonLoader = () => (
  <div className="flex flex-col justify-center items-center">
    <div className="bg-gray-300 h-12 w-3/4 mb-4 rounded"></div>
    <div className="bg-gray-300 h-6 w-1/2 mb-6 rounded"></div>
    <div className="bg-gray-300 h-60 w-full max-w-4xl rounded-xl border-16 border-primary mt-6"></div>
  </div>
);

const HowItWorksComponent = () => {
  const [heading1, setHeading1] = useState("");
  const [paragraph1, setParagraph1] = useState("");
  const [image1, setImage1] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        const entry = await client.getEntry('4s2hG5D5C51UBuu22Zfyq7');
        setHeading1(entry.fields.heading1);
        setParagraph1(entry.fields.paragraph1.content[0].content[0].value);

        const assets = await client.getAssets();
        assets.items.map((asset) => {
          if (asset.sys.id === entry.fields.image1.sys.id) {
            setImage1('https:' + asset.fields.file.url);
          }
        });
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, []);

  return (
    loading ? <SkeletonLoader /> : (
      <div className="flex flex-col justify-center items-center">
        <h1 className="text-3xl font-semibold text-primary mt-20">
          {heading1}
        </h1>
        <p className="mx-auto w-full max-w-3xl text-lg lg:text-xl mt-6 text-black/80 text-center">
          {paragraph1}
        </p>
        <img
          src={image1}
          alt="How it Works"
          className="w-full max-w-4xl rounded-xl border-16 border-primary mt-6"
          style={{ height: "auto" }}
        />
      </div>
    )
  );
};

export default HowItWorksComponent;
