import LinkedInSVG from "assets/linkedin.svg";
import LinkSVG from "assets/Linkblue.svg";
import TemplateCover from "assets/template5cover.jpeg";
import UserPic from "assets/template5user.jpeg";
import React from "react";
import InstaSVG from "assets/insta.svg";
import XSVG from "assets/x.svg";
import DribbleSVG from "assets/dribble.svg";
import { useNavigate } from "react-router-dom";

const Template5 = ({ data }) => {

  return (
    <div>
      {data.background.type === "Image" ? (
        <img
          src={data.coverPic || TemplateCover}
          onError={(e) => { e.target.src = TemplateCover; }}
          alt="Cover Photo"
          style={{ 
            width: "100%", 
            height: "100%",
            borderTopLeftRadius: "10px", 
            borderTopRightRadius: "10px",
            position: "absolute",
            top: 0,
            left: 0
          }}
        />
      ) : (
        <section
          style={{
            width: "100%",
            height: "100%",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            position: "absolute",
            top: 0,
            left: 0,
            background: data.background.type === "SingleColor"
              ? data.background.color
              : data.background.type === "GradientColor"
                ? `linear-gradient(to right, ${data.background.gradient_1}, ${data.background.gradient_2})`
                : undefined,
          }}
        />
      )}
      <InnerSection data={data} />
      
    </div>
  );
};

const InnerSection = ({data})=>{

  const navigate = useNavigate();
  const socialIcons = { Instagram: InstaSVG, Twitter: XSVG, Dribble: DribbleSVG, LinkedIn: LinkedInSVG };

  const categories = data.videos.reduce((acc, video) => {
    const category = acc.find(cat => cat.category_id === video.category_id);
    if (category) {
      category.videos.push(video);
    } else {
      const categoryName = data.categories.find(cat => cat.id === video.category_id).title || 'Unknown Category';
      acc.push({ category_id: video.category_id, category_name: categoryName, videos: [video] });
    }
    return acc;
  }, []);

  return <>
  <div className="text-center flex flex-col items-center gap-5 mt-[8rem] relative z-2">
        <img
          src={data.profilePic || UserPic}
          onError={(e) => {
            e.target.src = UserPic;
          }}
          alt="User"
          style={{
            width: "100px",
            height: "100px",
            borderRadius: "50%",
            border: "3px solid #ffffff",
            objectFit: "cover",
          }}
        />
        <div className="w-[80%] sm:max-w-[50%]">
          <div
            style={{
              color: "white",
              textAlign: "center",
            }}
          >
            <h2
              style={{
                fontSize: "20px",
                fontWeight: "700",
                lineHeight: "24px",
                color: "#3855B3",
              }}
            >
              @{data.username}
            </h2>
            <p
              style={{
                color: "#3855B3",
              }}
            >
              {data.profession}
            </p>
          </div>
          <div
            style={{
              color: "#3855B3",
            }}
          >
            <p>{data.description}</p>
          </div>
        </div>
        <div
          className="ssocial-iconsssss"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {data.basic_links.map((link, index) => {
            const Icon = socialIcons[link.title];
            return (
              <a
                key={index}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={Icon || LinkSVG}
                  alt={link.title}
                  style={{
                    margin: "0 15px",
                    width: "30px",
                    height: "30px",
                  }}
                />
              </a>
            );
          })}
        </div>
      </div>
      
      <div className="flex flex-col text-center items-center gap-5 mx-auto my-[2rem] w-[80%] sm:max-w-[50%] relative z-2" >
        <button
          style={{
            backgroundColor: "#3855B3",
            height: "50px",
            width: "100%",
            borderRadius: "50px",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px 20px",
            color: "white",
            textDecoration: "none",
            fontSize: "18px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)"
          }}
          onClick={() => navigate("/lead-magnet-quiz/product/" + data.username)}
        >
          <span>My Store</span>
        </button>
        {categories.map((category, index) => (
          <div key={index}>
            <h2 style={{ color: "#3855B3", fontSize: "18px", marginBottom: "3%" }}>{category.category_name}</h2>
            {category.videos.map((video, videoIndex) => (
              <button
                key={videoIndex}
                style={{
                  backgroundColor: "#3855B3",
                  height: "50px",
                  borderRadius: "50px",
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "10px 20px",
                  color: "white",
                  textDecoration: "none",
                  fontSize: "18px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)"
                }}
                onClick={() => navigate("/lead-magnet-quiz/video/" + data.username)}
              >
                <span>{video.title}</span>
              </button>
            ))}
          </div>
        ))}
        {data.ebooks.size !== 0 && (
          <h2 style={{ color: "#3855B3", fontSize: "18px", marginBottom: "" }}>Ebooks</h2>
        )}

{data.ebooks.map((book, index) => (
  <button
    key={index}
    style={{
      backgroundColor: "#3855B3",
      borderRadius: "50px",
      alignItems: "center",
      justifyContent: "center",
      padding: "12px 20px",  // Adjust padding as needed
      color: "white",
      textDecoration: "none",
      fontSize: "18px",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      minWidth: "150px",  // Set a minimum width to prevent the button from collapsing
      minHeight: "50px",  // Set a minimum height for consistency
      display: "flex",  // Ensure button behaves as a flex container
      flexDirection: "column",  // Align items and content vertically
    }}
    onClick={() => navigate("/lead-magnet-quiz/ebook/" + data.username)}
  >
    <span style={{ flex: 1 }}>{book.title}</span>
  </button>
))}

      </div>
      {!data.subscribed && (<p className="text-center relative z-2" style={{
        color: "#3855B3"
      }}>
        <a href="https://example.com" className="font-semibold">
        Powered by LinkLeads.AI
        </a>
      </p>)}</>
}
export default Template5;
