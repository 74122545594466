import LinkSVG from "assets/Linkwhite.svg";
import ShareSVG from "assets/share.svg";
import TemplateCover from "assets/template3cover.jpeg";
import UserPic from "assets/template3user.jpeg";
import React, { useState, useEffect } from "react";
import Button from "components/Button/Button";
import PricingSection from "components/PricingSection/PricingSection";
import Loading from "components/Loading/Loading";
import InstaSVG from "assets/insta1.svg";
import XSVG from "assets/x1.svg";
import DribbleSVG from "assets/dribble1.svg";
import LinkedInSVG from "assets/linkedin.svg";
import { useNavigate } from "react-router-dom";

const Template3 = ({ data }) => {
  const navigate = useNavigate();
  const socialIcons = {
    Instagram: InstaSVG,
    Twitter: XSVG,
    Dribble: DribbleSVG,
    LinkedIn: LinkedInSVG,
  };

  return (
    <div>
      {data.background.type === "Image" ? (
        <img
          src={data.coverPic || TemplateCover}
          onError={(e) => {
            e.target.src = TemplateCover;
          }}
          alt="Cover Photo"
          style={{
            width: "100%",
            height: "800px",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
          }}
        />
      ) : (
        <section
          style={{
            background:
              data.background.type === "SingleColor"
                ? data.background.color
                : data.background.type === "GradientColor"
                ? `linear-gradient(to right, ${data.background.gradient_1}, ${data.background.gradient_2})`
                : undefined,
            width: "100%",
            height: "800px",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
          }}
        />
      )}
      <img
        src={data.profilePic || UserPic}
        onError={(e) => {
          e.target.src = UserPic;
        }}
        alt="User"
        style={{
          position: "absolute",
          top: "180px",
          left: "50%",
          transform: "translateX(-50%)",
          width: "100px",
          height: "100px",
          borderRadius: "50%",
          border: "3px solid #ffffff",
          objectFit: "cover",
        }}
      />
      <div
        style={{
          position: "absolute",
          top: "300px",
          left: "50%",
          transform: "translateX(-50%)",
          color: "white",
          textAlign: "center",
        }}
      >
        <h2
          style={{
            fontFamily: "Roboto Slab",
            fontSize: "20px",
            fontWeight: "700",
            lineHeight: "24px",
          }}
        >
          {data.name}
        </h2>
        <p
          style={{
            fontFamily: "Roboto Serif",
            marginRight: "5%",
          }}
        >
          {data.profession}
        </p>
      </div>
      <div
        className="btn-divvvvv grid grid-cols-1 md:grid-cols-1 gap-3 md:gap-5 text-white"
        style={{
          position: "absolute",
          top: "350px",
          left: "50%",
          transform: "translateX(-50%)",
          marginTop: "3%",
          textAlign: "center",
          width: "50%",
        }}
      >
        {data.ebooks.length!=0&&(<button
          className="b1111"
          onClick={() => navigate("/lead-magnet-quiz/ebook/" + data.username)}
          style={{
            backgroundColor: "rgb(211, 206, 206)",
            height: "50px",
            fontFamily: "Ribeye Marrow",
            borderRadius: "50px",
            position: "relative",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px 20px",
            border: "none",
            color: "#3855B3",
            textDecoration: "none",
            fontSize: "18px",
          }}
        >
          <span className="text">My Ebooks</span>
        </button>)}
        {data.services.length!=0&&(<button
          className="b2222"
          onClick={() => navigate("/lead-magnet-quiz/service/" + data.username)}
          style={{
            backgroundColor: "rgb(211, 206, 206)",
            height: "50px",
            fontFamily: "Ribeye Marrow",
            borderRadius: "50px",
            position: "relative",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px 20px",
            border: "none",
            color: "#3855B3",
            textDecoration: "none",
            fontSize: "18px",
          }}
        >
          <span className="text">My Services</span>
        </button>)}
        {data.videos.length!=0&&(<button
          className="b3333"
          onClick={() => navigate("/lead-magnet-quiz/video/" + data.username)}
          style={{
            backgroundColor: "rgb(211, 206, 206)",
            height: "50px",
            fontFamily: "Ribeye Marrow",
            borderRadius: "50px",
            position: "relative",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px 20px",
            border: "none",
            color: "#3855B3",
            textDecoration: "none",
            fontSize: "18px",
          }}
        >
          <span className="text">My Videos</span>
        </button>)}
      </div>
      <div
        className="social-iconsssss"
        style={{
          position: "absolute",
          top: "600px",
          left: "49%",
          transform: "translateX(-50%)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {data.basic_links.map((link, index) => {
          const Icon = socialIcons[link.title];
          return (
            <a
              key={index}
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={Icon || LinkSVG}
                alt={link.title}
                style={{
                  margin: "0 15px",
                  width: "30px",
                  height: "30px",
                }}
              />
            </a>
          );
        })}
      </div>

      {!data.subscribed && (<p
        className="linklead-ppppp"
        style={{
          position: "absolute",
          top: "700px",
          left: "7%",
          transform: "translateX(-50%)",
          marginLeft: "42%",
          color: "white",
        }}
      >
        <a href="https://example.com" className="font-semibold">
        Powered by LinkLeads.AI
        </a>
      </p>)}
    </div>
  );
};

export default Template3;
