import React, { useState } from "react";
import AuthWindow from "../components/AuthWindow/AuthWindow";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";
import axios from "axios";
import { toast } from "react-toastify";

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email("Invalid Email").required("Email is required"),
});

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const handleSubmit = async (values) => {
    try {
      const response = await axios.post(
        "/request-password-reset",
        { email: values.email },
        {
          validateStatus: function (status) {
            return status >= 200 && status < 500;
          },
        }
      );
      if (response.status === 200) {
        toast.success("Password reset email sent successfully");
        navigate("/login");
      } else {
        setError(response.data.error);
        toast.error(response.data.error);
      }
    } catch (error) {
      console.error("Error sending reset email:", error);
      setError("An unexpected error occurred");
      toast.error("An unexpected error occurred");
    }
  };

  return (
    <div className="w-[100%] min-h-screen flex items-center">
      <AuthWindow />
      <section className="w-[100%] lg:w-[50%] min-h-screen flex flex-col items-center justify-center bg-[#FFFFFF44]">
        <figure className="lg:hidden flex gap-5 items-center mb-10">
          <img src={logo} width={50} className="mx-auto" />
          <figcaption className="text-[#10277C] font-semibold font-serif">
            Link Leads
          </figcaption>
        </figure>
        <h3 className="text-center text-[#141414E5] font-semibold text-xl">
          Forgot Password
        </h3>
        <Formik
          initialValues={{ email: "" }}
          validationSchema={ForgotPasswordSchema}
          onSubmit={handleSubmit}
          validateOnChange={true}
          validateOnBlur={true}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            touched,
            errors,
          }) => (
            <form
              onSubmit={handleSubmit}
              className="mt-10 w-[90%] md:w-[75%] lg:w-[60%] flex flex-col items-center gap-4"
            >
              <div className="w-full">
                <label htmlFor="email" className="block text-sm text-[#10277C]">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  className="w-full mt-1 p-2 border border-gray-300 rounded"
                />
                {errors.email && touched.email ? (
                  <div className="text-red-500 text-sm">{errors.email}</div>
                ) : null}
              </div>
              {error && <p className="text-red-500">{error}</p>}
              <button
                type="submit"
                className="bg-[#3855B3] text-white rounded-full w-[100%] py-2 mt-2 hover:bg-white hover:text-[#3855B3] transition-all border border-[#3855B3]"
              >
                Send Reset Email
              </button>
            </form>
          )}
        </Formik>
      </section>
    </div>
  );
};

export default ForgotPassword;
