import React, { useState, useRef, useEffect } from "react";
import InfoSVG from "../../assets/info.svg";
import CancelSVG from "../../assets/cancel.svg";

const Card = ({
  icon,
  title,
  subtitle,
  buttonText,
  PopupHeading,
  PopupText,
  onClick,
}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupStyles, setPopupStyles] = useState({});
  const [arrowStyles, setArrowStyles] = useState({});
  const popupRef = useRef(null);
  const shouldShowPopupOnRight = title === "My Product";

  useEffect(() => {
    if (isPopupOpen && popupRef.current) {
      const popupRect = popupRef.current.getBoundingClientRect();
      const cardRect = popupRef.current.parentElement.getBoundingClientRect();
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;
      const margin = 10;

      const styles = {
        left: shouldShowPopupOnRight
          ? cardRect.right + popupRect.width + margin > viewportWidth
            ? "auto"
            : "100%"
          : cardRect.left - popupRect.width - margin < 0
          ? "auto"
          : "50%",
        right: shouldShowPopupOnRight
          ? cardRect.right + popupRect.width + margin > viewportWidth
            ? "0"
            : "auto"
          : "auto",
        top: cardRect.bottom + popupRect.height + margin > viewportHeight
          ? "auto"
          : "100%",
        bottom: cardRect.bottom + popupRect.height + margin > viewportHeight
          ? "100%"
          : "auto",
        transform:
          cardRect.left - popupRect.width - margin < 0
            ? "translateX(0)"
            : "translateX(-50%)",
      };

      const arrowPos = {
        top: cardRect.bottom + popupRect.height + margin > viewportHeight ? "auto" : "-20px",
        bottom: cardRect.bottom + popupRect.height + margin > viewportHeight ? "-20px" : "auto",
        left: shouldShowPopupOnRight ? "calc(100% - 20px)" : "50%",
        transform: shouldShowPopupOnRight ? "none" : "translateX(-50%)",
      };

      setPopupStyles(styles);
      setArrowStyles(arrowPos);
    }
  }, [isPopupOpen, shouldShowPopupOnRight]);

  return (
    <div className="h-[88px] rounded-lg bg-white border border-gray-300 flex justify-between items-center px-2 sm:px-4">
      <div className="flex gap-3 items-center">
        <div className="shadow-icon p-2">
          {icon && <img src={icon} width={24} alt="" />}
        </div>
        <div>
          <p className="text-base font-semibold opacity-80">{title}</p>
        </div>
        {PopupHeading && PopupText && (
          <div className="flex cursor-pointer relative">
            <img
              src={InfoSVG}
              width={20}
              alt=""
              onMouseEnter={() => setIsPopupOpen(true)}
              onMouseLeave={() => setIsPopupOpen(false)}
            />
            {isPopupOpen && (
              <div
                ref={popupRef}
                className={`absolute w-72 bg-red-200 border border-gray-300 rounded-lg shadow-lg z-10`}
                style={popupStyles}
              >
                <div className="p-7 bg-[#D4DEFF]">
                  <div className="flex items-center justify-between">
                    <span className="text-xl font-medium">{PopupHeading}</span>
                  </div>
                  <p className="mt-4 text-gray-400">{PopupText}</p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <button
        onClick={onClick}
        className="flex items-center gap-1 border border-gray-300 px-3 py-2 md:px-4 md:py-2 rounded-full font-bold text-xs bg-primary hover:bg-[#3855b3da] duration-300 text-white"
      >
        {buttonText}
      </button>
    </div>
  );
};

export default Card;
