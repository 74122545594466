import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlay,
  faLink,
  faShoppingBag,
} from "@fortawesome/free-solid-svg-icons";
import ZapSVG from "assets/zap.svg";
import LinkSVG from "assets/Linkblue.svg";
import ShareSVG from "assets/share.svg";
import TemplateCover from "assets/template1.jpeg";
import UserPic from "assets/userPic.svg";
import InstaSVG from "assets/insta.svg";
import XSVG from "assets/x.svg";
import DribbleSVG from "assets/dribble.svg";
import LinkedInSVG from "assets/linkedin.svg";
import React from "react";
import { useNavigate } from "react-router-dom";
import FacebookSVG from "assets/facebook.svg";
import { faServicestack } from "@fortawesome/free-brands-svg-icons";

const Template1 = ({ data }) => {
  const navigate = useNavigate();
  const socialIcons = {
    Instagram: InstaSVG,
    Twitter: XSVG,
    Facebook: FacebookSVG,
    Dribble: DribbleSVG,
    LinkedIn: LinkedInSVG,
  };

  return (
    <div>
      <img
        src={data.coverPic || TemplateCover}
        onError={(e) => {
          e.target.src = TemplateCover;
        }}
        alt="Cover Photo"
        className="w-full h-[350px] rounded-t-lg"
      />
      <div className="flex flex-col text-center sm:text-left sm:flex-row gap-3 items-center max-w-[80%] sm:max-w-[70%]  mx-auto relative">
      <div className="w-1"></div>
        <div className="max-w-3/4">
        <div className="flex sm:flex-row flex-col items-center "><img
          src={data.profilePic || UserPic}
          onError={(e) => {
            e.target.src = UserPic;
          }}
          alt="User"
          className="w-[100px] h-[100px] rounded-full border-4 border-white"
        />
        <div className="flex flex-col mt-2">
          <h2 className="text-lg font-bold leading-6">{data.name}</h2>
          <p className="text-[#3855B3]">{data.profession}</p></div></div>
          <div
            style={{
              color: "#353535",
              backgroundColor: "#28439D1A",
              marginTop: "3%",
              width: "100%",
              height: "120px",
              borderRadius: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <p>{data.description}</p>
          </div>
        </div>
      </div>
      <div className="text-left mt-[2.5rem] max-w-[80%] sm:max-w-[70%]  mx-auto">
        {data.services.length != 0 && (
          <button
            className="bg-[#3855B3] w-full h-[50px] rounded-full relative inline-flex items-center justify-center px-5 py-2 border-none text-white text-base my-2"
            onClick={() =>
              navigate("/lead-magnet-quiz/service/" + data.username)
            }
          >
            <FontAwesomeIcon icon={faServicestack} className="mr-2" />
            <span className="inline-block align-middle">My Services</span>
          </button>
        )}
        {data.products.length != 0 && (
          <button
            className="bg-[#3855B3] w-full h-[50px] rounded-full relative inline-flex items-center justify-center px-5 py-2 border-none text-white text-base my-2"
            onClick={() =>
              navigate("/lead-magnet-quiz/product/" + data.username)
            }
          >
            <FontAwesomeIcon icon={faShoppingBag} className="mr-2" />
            <span className="inline-block align-middle">My Store</span>
          </button>
        )}
        {data.videos.length != 0 && (
          <button
            className="bg-[#3855B3] w-full h-[50px] rounded-full relative inline-flex items-center justify-center px-5 py-2 border-none text-white text-base my-2"
            onClick={() => navigate("/lead-magnet-quiz/video/" + data.username)}
          >
            <FontAwesomeIcon icon={faCirclePlay} className="mr-2" />
            <span className="inline-block align-middle">My Videos</span>
          </button>
        )}
      </div>
      <div className="flex justify-center items-center mt-8">
        {data.basic_links.map((link, index) => {
          const Icon = socialIcons[link.title];
          return (
            <a
              key={index}
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              className="mx-4"
            >
              <img src={Icon} alt={link.title} className="w-[30px] h-[30px]" />
            </a>
          );
        })}
      </div>
      {!data.subscribed && (
        <p className="mt-8 flex justify-center">
          <a href="https://linkleads.ai" className="text-[#3855B3] font-bold">
            Powered by LinkLeads.AI
          </a>
        </p>
      )}
    </div>
  );
};

export default Template1;
