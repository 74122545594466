import UserIcon from "../assets/userPic.svg";
import AISVG from "../assets/aisvg.svg";
import React, { useState, useEffect } from "react";
import ChatBot from "../components/ChatBox/ChatBox";
import Loading from "../components/Loading/Loading";
import { CiCreditCard1 } from "react-icons/ci";
import { FaCheckCircle, FaPaypal } from "react-icons/fa";
import { FaCcMastercard, FaCcVisa, FaCreditCard } from "react-icons/fa";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import LockIcon from "@mui/icons-material/Lock";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import BatteryChargingFullIcon from "@mui/icons-material/BatteryChargingFull";

const Payment = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [shouldRenderUI, setShouldRenderUI] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [activeButton, setActiveButton] = useState("monthly");

  const handleButtonClick = (button) => {
    setActiveButton(button);
  };
  const handleCardNumberChange = (e) => {
    const input = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
    const formattedInput = input.match(/.{1,4}/g)?.join(" ") || "";
    setCardNumber(formattedInput);
  };

  useEffect(() => {
    // Simulate initial loading time
    const initialLoadingTimeout = setTimeout(() => {
      setIsLoading(false);
      setShouldRenderUI(true);
    }, 1000);
    // Cleanup function to clear timeout
    return () => clearTimeout(initialLoadingTimeout);
  }, []);

  return (
    <>
      {isLoading && <Loading />}
      {shouldRenderUI && (
        <>
          <div className="flex flex-col lg:flex-row w-full h-full overflow-hidden">
            <div className="flex-grow bg-[#f6f7fa] px-2 lg:px-16 pt-10">
              <div className="">
                <div className="mt-2 mb-2 font-semibold text-[20px] text-black/70">
                  <h1></h1>
                </div>
                <div className="mt-5 bg-white">
                  <Timeline position="alternate">
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot color="primary">
                          <LockIcon />
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <h3 className="font-semibold">Unlock Now</h3>
                        <p className="text-black/70">
                          Get instant access to all Pro features
                        </p>
                      </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot color="secondary">
                          <AccessAlarmIcon />
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <h3 className="font-semibold">Day 28</h3>
                        <p className="text-black/70">
                          We'll send a reminder email that your trial is ending
                          soon
                        </p>
                      </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot color="info">
                          <BatteryChargingFullIcon />
                        </TimelineDot>
                      </TimelineSeparator>
                      <TimelineContent>
                        <h3 className="font-semibold">Day 30</h3>
                        <p className="text-black/70">
                          Your Pro plan starts and your credit card will be
                          charged. Cancel anytime before.
                        </p>
                      </TimelineContent>
                    </TimelineItem>
                  </Timeline>
                </div>
              </div>
            </div>

            <div className="w-full lg:w-[350px] p-5 bg-white h-auto">
              <div className="flex justify-end">
                <button className="shadow-icon p-3">
                  <img src={AISVG} alt="" />
                </button>
              </div>
              <div className="flex flex-col gap-3 items-center relative">
                <img src={UserIcon} width={60} alt="" />
                <p className="font-semibold">@harrison123</p>
                <p className="text-[14px] text-black/80">Business Analyst</p>
              </div>
            </div>
          </div>
          <div>
            <ChatBot />
          </div>
        </>
      )}
    </>
  );
};

export default Payment;
