import React from "react";

const ReviewComponent = () => {
  return (
    <div className="relative bg-white flex flex-col justify-center items-center mt-10 w-full min-h-screen">
      <h1 className="text-3xl font-semibold text-primary mb-6">
        People Love Us Because They Have More Leads and Money Now
      </h1>
      <div className="relative w-full">
        <iframe
          src="https://widget.senja.io/widget/6422e4bb-086b-4582-945d-ec7b785c33dc"
          className="w-full h-[calc(100vh-4rem)]"
          frameBorder="0"
          allow="fullscreen"
        ></iframe>
      </div>
    </div>
  );
};

export default ReviewComponent;
