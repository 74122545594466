import React, { useState, useEffect } from "react";
import { FaCheck } from "react-icons/fa6";
import { createClient } from "contentful";

const client = createClient({
  space: '60rew5hef1yw',
  accessToken: 'm4B-e9c0HFWhlzeBZfi0grKH0QSIIDlLzT3TknflgnM'
});

const TestimonialComponent = () => {
  const [heading1Data, setHeading1Data] = useState("");
  const [paragraph1Data, setParagraph1Data] = useState("");
  const [paragraph4Data, setParagraph4Data] = useState("");
  const [paragraph5Data, setParagraph5Data] = useState("");
  const [paragraph6Data, setParagraph6Data] = useState("");
  const [paragraph2Data, setParagraph2Data] = useState("");
  const [paragraph3Data, setParagraph3Data] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        const entry = await client.getEntry("2h0yH0Esi7xFG6yT1GMutT");
        setHeading1Data(entry.fields.heading1);
        setParagraph1Data(entry.fields.paragraph1.content[0].content[0].value);
        setParagraph4Data(entry.fields.paragraph4.content[0].content[0].value);
        setParagraph5Data(entry.fields.paragraph5.content[0].content[0].value);
        setParagraph6Data(entry.fields.paragraph6.content[0].content[0].value);
        setParagraph2Data(entry.fields.paragraph2.content[0].content[0].value);
        setParagraph3Data(entry.fields.paragraph3.content[0].content[0].value);

        const imageId = entry.fields.image1.sys.id;
        const asset = await client.getAsset(imageId);
        const imageUrl = "https:" + asset.fields.file.url;
        setImageURL(imageUrl);
      } catch (error) {
        console.error("Error fetching entry data: ", error);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, []);

  const Skeleton = ({ height, width, className }) => (
    <div
      className={`bg-gray-200 animate-pulse ${className}`}
      style={{ height, width }}
    />
  );

  return (
    <div className="flex flex-col lg:flex-row gap-10 mt-10">
      <div className="flex justify-center items-center lg:justify-start flex-1 p-4">
        <div className="relative w-full max-w-full aspect-w-16 aspect-h-9">
          {loading ? (
            <Skeleton height="100%" width="100%" className="rounded-xl" />
          ) : (
            <img
              src={imageURL}
              alt="Fetched Asset"
              className="rounded-xl border-16 border-primary object-contain shadow-2xl w-full"
            />
          )}
        </div>
      </div>
      <div className="flex-1 p-4">
        <div>
          {loading ? (
            <Skeleton height="40px" width="300px" className="mb-4" />
          ) : (
            <h1 className="text-3xl text-primary font-bold">{heading1Data}</h1>
          )}
          {loading ? (
            <>
              <Skeleton height="20px" width="100%" className="mt-4 mb-2" />
              <Skeleton height="20px" width="100%" className="mt-4 mb-2" />
              <Skeleton height="20px" width="100%" className="mt-4 mb-2" />
            </>
          ) : (
            <>
              <p className="mt-4 text-black/80 text-lg">{paragraph1Data}</p>
              <p className="mt-4 text-black/80 text-lg">{paragraph2Data}</p>
              <p className="mt-4 text-black/80 text-lg">{paragraph3Data}</p>
            </>
          )}
        </div>
        <div className="mt-8">
          {loading ? (
            <>
              <Skeleton height="20px" width="100%" className="mb-4" />
              <Skeleton height="20px" width="100%" className="mb-4" />
              <Skeleton height="20px" width="100%" />
            </>
          ) : (
            <>
              <p className="flex items-center mb-4 text-black/80 text-lg">
                <FaCheck className="text-green-500 mr-2" />
                {paragraph4Data}
              </p>
              <p className="flex items-center mb-4 text-black/80 text-lg">
                <FaCheck className="text-green-500 mr-2" />
                {paragraph5Data}
              </p>
              <p className="flex items-center text-black/80 text-lg">
                <FaCheck className="text-green-500 mr-2" />
                {paragraph6Data}
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TestimonialComponent;
