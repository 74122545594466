import ZapSVG from "assets/zap.svg";
import LinkSVG from "assets/Link.svg";
import ShareSVG from "assets/share.svg";
import React, { useState, useEffect } from "react";
import Button from "components/Button/Button";
import PricingSection from "components/PricingSection/PricingSection";
import Loading from "components/Loading/Loading";
import axios from "axios";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Template1 from "components/Templates/Template1";
import Template2 from "components/Templates/Template2";
import Template3 from "components/Templates/Template3";
import Template4 from "components/Templates/Template4";
import Template5 from "components/Templates/Template5";
import Template6 from "components/Templates/Template6";
import Template7 from "components/Templates/Template7";
import AITemplate from "components/Templates/AITemplate";

const UserProfile = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [shouldRenderUI, setShouldRenderUI] = useState(false);
  const [openAIModel, setOpenAIModel] = useState(false);
  const [data, setData] = useState(null);
  const { username } = useParams();
  const [templateId, setTemplateId] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/user-data?username=" + username, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log("Data received:", response.data.data);

        if (response.status === 200) {
          console.log(response.data);
          setData(response.data);
          setTemplateId(response.data.template_id);
          setIsLoading(false);
          setShouldRenderUI(true);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const addView = async () => {
      try {
        console.log(username)
        const requestBody = {
          page_type: "profile",
          analytic_type: "view",
          username: username,
        };

        const response = await axios.post("/analytics", requestBody);
      } catch (error) {
        console.error("Error making request:", error);
      }
    };


    fetchData();
    addView();
  }, []);

  return (
    <>
      {isLoading && <Loading />}
      {shouldRenderUI && (
        <>
          <div
            className={
              `flex flex-col lg:flex-row w-full h-full overflow-hidden font-` +
              data.font
            }
          >
            <div className="flex-grow">
              {/* Top Navbar, Buttons */}

              {/* {Use templates here} */}
              {templateId == 1 && <Template1 data={data} />}
              {templateId == 2 && <Template2 data={data} />}
              {templateId == 3 && <Template3 data={data} />}
              {templateId == 4 && <Template4 data={data} />}
              {templateId == 5 && <Template5 data={data} />}
              {templateId == 6 && <Template6 data={data} />}
              {templateId == 7 && <Template7 data={data} />}
              {templateId == -1 && <AITemplate data={data} />}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default UserProfile;
