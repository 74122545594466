import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { createClient } from "contentful";

const client = createClient({
  space: "60rew5hef1yw",
  accessToken: "m4B-e9c0HFWhlzeBZfi0grKH0QSIIDlLzT3TknflgnM",
});

const PlanComponent = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({
    heading: "",
    paragraph: "",
    saleMessage: "",
    freePlanName: "",
    proPlanName: "",
    premiumPlanName: "",
    freePlanPrice: "",
    priceUnit: "",
    callToAction: "",
    freePlanFeatures: [],
    proPlanFeatures: [],
    premiumPlanFeatures: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const entry = await client.getEntry("5cSXMdRrZ6SSFYDYCXXvAM");

        setData({
          heading: entry.fields.heading,
          paragraph: entry.fields.paragraph,
          saleMessage: entry.fields.sale,
          freePlanName: entry.fields.planName1,
          proPlanName: entry.fields.planName2,
          premiumPlanName: entry.fields.planName3,
          PlanPrice1: entry.fields.price1,
          PlanPrice2: entry.fields.price2,
          PlanPrice3: entry.fields.price3,
          discount1: entry.fields.discount,
          discount2: entry.fields.discount2,
          discount3: entry.fields.discount3,
          priceUnit: "/ Month",
          callToAction: "Generate My Mega Prompts Now",
          freePlanFeatures: [
            entry.fields.feature1,
            entry.fields.feature2,
            entry.fields.feature3,
            entry.fields.feature4,
            entry.fields.feature5,
            entry.fields.feature6,
            entry.fields.feature7,
            entry.fields.feature8,
            entry.fields.feature9,
            entry.fields.feature10,
          ].filter(Boolean),
          proPlanFeatures: [
            entry.fields.feature11,
            entry.fields.feature12,
            entry.fields.feature13,
            entry.fields.feature14,
            entry.fields.feature15,
            entry.fields.feature16,
            entry.fields.feature17,
            entry.fields.feature18,
            entry.fields.feature19,
            entry.fields.feature20,
          ].filter(Boolean),
          premiumPlanFeatures: [
            entry.fields.feature21,
            entry.fields.feature22,
            entry.fields.feature23,
            entry.fields.feature24,
            entry.fields.feature25,
            entry.fields.feature26,
            entry.fields.feature27,
            entry.fields.feature28,
            entry.fields.feature29,
            entry.fields.feature30,
          ].filter(Boolean),
        });
      } catch (error) {
        console.error("Error fetching entry data: ", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <div className="flex flex-col items-center justify-center mt-14 space-y-6">
        <div className="text-center">
          <h1 className="text-3xl mb-4 text-primary font-bold">
            {data.heading}
          </h1>
          <p className="mb-4 text-black/80 text-lg">{data.paragraph}</p>
          <p className="bg-white shadow-lg pl-2 pr-2 text-[18px] rounded-full text-red-600 inline-block mt-4">
            {data.saleMessage}
          </p>
        </div>
      </div>

      <div className="flex flex-col items-start justify-center mt-10 space-y-6 md:space-y-0 md:flex-row md:space-x-4">
        <div className="flex flex-col w-full md:w-72 max-w-full h-full rounded-xl border-2 border-primary p-4 justify-between">
          <div className="overflow-auto">
            <p className="text-2xl font-bold text-primary">
              {data.freePlanName}
            </p>
            <p className="text-2xl font-bold text-primary">
              {data.PlanPrice1}
              <sup className="text-xs"> {data.priceUnit}</sup>
            </p>
            <button
              className="w-full rounded-full py-2 bg-primary text-white border-2 border-primary text-[14px] mt-4 mb-4"
              onClick={() => navigate("/auth/signup")}
            >
              {data.callToAction}
            </button>
            {data.freePlanFeatures.map((item, index) => (
              <p className="flex items-center mt-2" key={index}>
                <span className="w-4 h-4 rounded-full bg-primary text-white flex items-center justify-center mr-2">
                  ✓
                </span>
                <span className="text-md">{item}</span>
              </p>
            ))}
          </div>
        </div>
        <div className="flex flex-col w-full md:w-72 max-w-full h-full rounded-xl border-2 border-primary p-4 justify-between">
          <div className="overflow-auto">
            <p className="text-2xl font-bold text-primary">
              {data.proPlanName}
            </p>
            <p className="text-2xl font-bold text-primary">
              {data.discount2 &&<span className="line-through text-gray-500">
                ${data.PlanPrice2}
              </span>}
              <span>${(data.PlanPrice2 * (1-data.discount2)).toFixed(2)}</span>
              <sup className="text-xs">{data.priceUnit}</sup>
              {data.discount2 &&<span className="text-red-500 text-xs ml-1">
                {data.discount2*100}% OFF
              </span>}
            </p>

            <button
              className="w-full rounded-full py-2 bg-primary text-white border-2 border-primary text-[14px] mt-4 mb-4"
              onClick={() => navigate("/auth/signup")}
            >
              {data.callToAction}
            </button>
            {data.proPlanFeatures.map((item, index) => (
              <p className="flex items-center mt-2" key={index}>
                <span className="w-4 h-4 rounded-full bg-primary text-white flex items-center justify-center mr-2">
                  ✓
                </span>
                <span className="text-md">{item}</span>
              </p>
            ))}
          </div>
        </div>
        <div className="flex flex-col w-full md:w-72 max-w-full h-full rounded-xl border-2 border-primary p-4 justify-between">
          <div className="overflow-auto">
            <p className="text-2xl font-bold text-primary">
              {data.premiumPlanName}
            </p>
            <p className="text-2xl font-bold text-primary">
              {data.discount3 && <span className="line-through text-gray-500">
                ${data.PlanPrice3}
              </span>}
              <span>${(data.PlanPrice3 * (1-data.discount3)).toFixed(2)}</span>
              <sup className="text-xs">{data.priceUnit}</sup>
              {data.discount3 && (
                <span className="text-red-500 text-xs ml-1">
                  {data.discount3*100}% OFF
                </span>
              )}
            </p>
            <button
              className="w-full rounded-full py-2 bg-primary text-white border-2 border-primary text-[14px] mt-4 mb-4"
              onClick={() => navigate("/auth/signup")}
            >
              {data.callToAction}
            </button>
            {data.premiumPlanFeatures.map((item, index) => (
              <p className="flex items-center mt-2" key={index}>
                <span className="w-4 h-4 rounded-full bg-primary text-white flex items-center justify-center mr-2">
                  ✓
                </span>
                <span className="text-md">{item}</span>
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanComponent;
