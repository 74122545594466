import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import "./analytics.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import LineGraph from "../components/LineGraph/LineGraph";
import Loading from "../components/Loading/Loading";
import Navbar from "components/Navbar/Navbar";
import EyeSVG from "../assets/eye.svg";
import Dashboard2SVG from "../assets/dashboard2.svg";
import ClickSVG from "../assets/click.svg";
import GraphSVG from "../assets/graph.svg";
import Card from "../components/CardAnalytics/CardAnalytics";
import ChatBot from "../components/ChatBox/ChatBox";

const Analytics = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [shouldRenderUI, setShouldRenderUI] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/analytics", {
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.status === 200) {
          const data = response.data;
          console.log(data);
          setData(data);
          // Handle data setting here
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        // Simulate initial loading time
        setTimeout(() => {
          setIsLoading(false);
          setShouldRenderUI(true);
        }, 1000);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {isLoading && <Loading />}
      {shouldRenderUI && (
        <>
          <div className="flex flex-col lg:flex-row w-full h-full overflow-hidden">
            <div className="flex-grow bg-[#f6f7fa] px-2 lg:px-16 pt-10">
              {/* Top Navbar, Buttons */}
              <Navbar title={"Analytics"} />

              {/* <div className="grid grid-cols-1 md:grid-cols-5 gap-3 md:gap-5 mb-10">
                <Card
                  icon={Dashboard2SVG}
                  title="3"
                  subtitle="Category Count"
                  subtitle1="11%"
                />
                <Card
                  icon={EyeSVG}
                  title="5K"
                  subtitle="Total Views"
                  subtitle1="11%"
                />
                <Card
                  icon={EyeSVG}
                  title="5K"
                  subtitle="Unique Views"
                  subtitle1="11%"
                />
                <Card
                  icon={ClickSVG}
                  title="1,345"
                  subtitle="Total Clicks"
                  subtitle1="11%"
                />
                <Card
                  icon={GraphSVG}
                  title="20.00%"
                  subtitle="CTR"
                  link="/store"
                  subtitle1="11%"
                />
              </div> */}

              {/* Line Graph Section */}
              {Object.keys(data).map(
  (key, index) =>
    key !== "Profile" && (
      <React.Fragment key={index}>
        <div className="dashboard-container">
          <div className="content-container">
            {Object.keys(data[key]).length !== 0 ? (
              <>
                <div className="my-2 mb-2 font-semibold text-[20px] text-black/70">
                  <h1>{key}</h1>
                </div>
                {Object.keys(data[key]).map((subKey, subIndex) => (
                  <div key={subIndex} className={`section-container my-5`}>
                    <div className="section-header">
                      <h5 className="font-semibold">{subKey}</h5>
                      {/* Toggle button without useState */}
                      <button
                        className="section-button"
                        onClick={() => {
                          const graphSection = document.querySelector(
                            `.section-container2-${subKey.replace(
                              /[^\w]/g,
                              "-"
                            )}`
                          );
                          const isOpen = graphSection.classList.contains(
                            "closed"
                          );
                          graphSection.classList.toggle("closed", !isOpen);
                        }}
                      >
                        <IoIosArrowDown />
                      </button>
                    </div>
                    <div
                      className={`section-container2 section-container2-${subKey.replace(
                        /[^\w]/g,
                        "-"
                      )} closed`}
                    >
                      <LineGraph
                        graph={data[key][subKey]}
                        clicks={data[key][subKey].reduce(
                          (sum, val) => sum + val.clicks,
                          0
                        )}
                        views={data[key][subKey].reduce(
                          (sum, val) => sum + val.views,
                          0
                        )}
                        ctr={(
                          (data[key][subKey].reduce(
                            (sum, val) => sum + val.clicks,
                            0
                          ) /
                            data[key][subKey].reduce(
                              (sum, val) => sum + val.views,
                              1
                            )) *
                          100
                        ).toFixed(1)}
                        conversion_rate={(
                          (data[key][subKey].reduce(
                            (sum, val) => sum + val.buys,
                            0
                          ) /
                            data[key][subKey].reduce(
                              (sum, val) => sum + val.clicks,
                              1
                            )) *
                          100
                        ).toFixed(1)}
                        sales={data[key][subKey]
                          .reduce((sum, val) => sum + val.buys, 0)
                          .toFixed(1)}
                      />
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div className="my-2 mb-2 font-semibold text-[20px] text-black/70">
                <h1>No stats available for {key}</h1>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    )
)}

            </div>
          </div>
          <div className="">
            <ChatBot />
          </div>
        </>
      )}
    </>
  );
};

export default Analytics;
