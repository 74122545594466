import React, { useEffect, useState } from "react";
import UserSVG from "../assets/userPic.svg";
import Tabs from "../components/Tabs/Tabs";
import CategorySVG from "../assets/category.svg";
import filSVG from "../assets/file.svg";
import SMSSVG from "../assets/sms.svg";
import SimpleStoreSVG from "../assets/simpleStore.svg";
import { FaChevronDown } from "react-icons/fa6";
import Banner from "../components/Banner/Banner";
import { useNavigate } from "react-router-dom";
import TurtleSVG from "../assets/Turtle.svg";
import axios from "axios";
import Loading from "../components/Loading/Loading";

const specialOfferData = {
  title:
    "In-depth guides or reports that provide valuable information on a specific topic relevant to the target audience.",
  discount: "Deep Blue Home",
  image: TurtleSVG,
  buttonText: "Get Access",
};

const CustomizeLeadMagnet = ({ props }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [shouldRenderUI, setShouldRenderUI] = useState(false);
  const [data, setData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/user-data", {
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log("Data received:", response.data);

        if (response.status === 200) {
          console.log(response.data);
          setData(response.data);
          setIsLoading(false);
          setShouldRenderUI(true);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {isLoading && <Loading />}
      {shouldRenderUI && (
        <div className="lg:ml-4 flex flex-col lg:flex-row gap-10 justify-between bg-[#F8F8FA] h-[100vh] w-200">
          {/* right side div */}

          <div className="w-full flex flex-col lg:p-10">
            {/* banner */}
            <Banner />

            {/* tabs */}
            <div className="border border-gray-200 mt-5 rounded-lg">
              <Tabs specialOffer={specialOfferData} type={props.type} />
            </div>
          </div>

          {/* left side div */}
          {/* <div className="md:w-[500px] bg-white h-100">
            <div className="flex flex-col items-center justify-center my-10">
              <div class="relative py-2">
                <div class="absolute top-28 right-3">
                </div>
                <img src={data.profilePic || UserSVG}  onError={(e) => { e.target.src = UserSVG; }} alt="" className="object-cover w-32 h-32" />
              </div>
              <p className="text-2xl font-medium my-3">{data.name}</p>
            </div>
            <p className="text-xl mx-10 my-5 font-medium">{props.sidebarTitle}</p>
            <div className="flex flex-col justify-center gap-y-6 mx-5">
              <div className="flex items-center gap-3 border border-gray-200 p-2 rounded-2xl cursor-pointer">
                <img src={filSVG} alt="" />
                <p>Customize Landing Page</p>
              </div>
              <div
                onClick={() => navigate(`/customize-${props.type}-email`)}
                className="flex items-center gap-3 border border-gray-200 p-2 rounded-2xl cursor-pointer"
              >
                <img src={SMSSVG} alt="" />
                <p>Customize Emails</p>
              </div>
            </div>
          </div> */}
        </div>
      )}
    </>
  );
};

export default CustomizeLeadMagnet;
