import React, { useState, useEffect, useRef } from "react";
import Button from "../components/Button/Button";
import ChatBot from "../components/ChatBox/ChatBox";
import PricingSection from "../components/PricingSection/PricingSection";
import ReviewComponent from "components/LandingPage/ReviewComponent";
import HeroComponent from "components/LandingPage/HeroComponent";
import HowItWorksComponent from "components/LandingPage/HowItWorksComponent";
import ProgressComponent from "components/LandingPage/ProgressComponent";
import TestimonialComponent from "components/LandingPage/TestimonialComponent";
import TestimonialComponent2 from "components/LandingPage/TestimonialComponent2";
import TestimonialComponent3 from "components/LandingPage/TestimonialComponent3";
import TestimonialComponent4 from "components/LandingPage/TestimonialComponent4";
import PlanComponent from "components/LandingPage/PlanComponent";
import QuestionsComponent from "components/LandingPage/QuestionsComponent";
import Footer from "components/LandingPage/Footer";
import { useNavigate } from "react-router-dom";
import ReviewSenja from "components/LandingPage/ReviewSenja";

const Components = ({ planRef }) => (
  <>
    <HeroComponent />
    <HowItWorksComponent />
    <ProgressComponent />
    <TestimonialComponent />
    <TestimonialComponent2 />
    <TestimonialComponent3 />
    <TestimonialComponent4 />
    <ReviewSenja />
    {/* <ReviewComponent /> */}
    <div ref={planRef}>
      <PlanComponent />
    </div>
    <QuestionsComponent />
    {/* <Footer /> */}
  </>
);
const fetchData = async () => {
  // Replace this with your actual API call
  return {
    metadata: {
      tags: [],
      concepts: [],
    },
    sys: {
      space: {
        sys: {
          type: "Link",
          linkType: "Space",
          id: "60rew5hef1yw",
        },
      },
      id: "3p35erZeVmS1QIoCitgNXv",
      type: "Entry",
      createdAt: "2024-06-23T10:24:09.828Z",
      updatedAt: "2024-06-26T07:16:08.734Z",
      environment: {
        sys: {
          id: "master",
          type: "Link",
          linkType: "Environment",
        },
      },
      publishedVersion: 107,
      publishedAt: "2024-06-26T06:00:06.196Z",
      firstPublishedAt: "2024-06-23T10:24:26.072Z",
      createdBy: {
        sys: {
          type: "Link",
          linkType: "User",
          id: "2xTSDwO7xjsrdGNkP7SKyt",
        },
      },
      updatedBy: {
        sys: {
          type: "Link",
          linkType: "User",
          id: "2xTSDwO7xjsrdGNkP7SKyt",
        },
      },
      publishedCounter: 9,
      version: 119,
      publishedBy: {
        sys: {
          type: "Link",
          linkType: "User",
          id: "2xTSDwO7xjsrdGNkP7SKyt",
        },
      },
      automationTags: [],
      fieldStatus: {
        "*": {
          "en-US": "changed",
        },
      },
      contentType: {
        sys: {
          type: "Link",
          linkType: "ContentType",
          id: "linkleads",
        },
      },
      urn: "crn:contentful:::content:spaces/60rew5hef1yw/environments/master/entries/3p35erZeVmS1QIoCitgNXv",
    },
    fields: {
      section1: {
        "en-US": "Linkleads",
      },
      secction2: {
        "en-US": "Prompt generator",
      },
      section3: {
        "en-US": "Pricing",
      },
      section4: {
        "en-US": "Login",
      },
      section5: {
        "en-US": "Try Free",
      },
    },
  };
};

const LandingPage = () => {
  const navigate = useNavigate();
  const [scrolling, setScrolling] = useState(false);
  const [section1Data, setSection1Data] = useState("");
  const [section2Data, setSection2Data] = useState("");
  const [section3Data, setSection3Data] = useState("");
  const [section4Data, setSection4Data] = useState("");
  const [section5Data, setSection5Data] = useState("");
  const planRef = useRef(null);

  useEffect(() => {
    const getData = async () => {
      const data = await fetchData();
      setSection1Data(data.fields.section1["en-US"]);
      // setSection2Data(data.fields.secction2["en-US"]);
      setSection3Data(data.fields.section3["en-US"]);
      setSection4Data(data.fields.section4["en-US"]);
      setSection5Data(data.fields.section5["en-US"]);
    };

    getData();
  }, []);
const handleHashChange = () => {
      if (window.location.hash === "#pricing" && planRef.current) {
        planRef.current.scrollIntoView({ behavior: "smooth" });
      }
    };
  useEffect(()=>{
    

    window.addEventListener("hashchange", handleHashChange);

    // Handle initial load with hash
    handleHashChange();

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  },[]);
  return (
    <>
      <div className="flex flex-col lg:flex-row w-full h-full overflow-hidden font-plus-jakarta-sans">
        <div className="flex-grow bg-white px-2 lg:px-16 pt-10 pb-36">
          <div className="flex flex-col md:flex-row justify-between items-center p-2 border rounded-full mb-6">
            <h3 className="text-lg md:text-2xl font-medium ml-4 mb-2 md:mb-0 text-primary">
              {section1Data}
            </h3>
            
            <div className="flex gap-2 md:gap-5 mr-4">
              <button
                onClick={() => navigate("/auth")}
                className="flex flex-row-reverse items-center gap-1 border border-gray-300 px-2 md:px-3 py-1 md:py-2 rounded-full font-bold text-xs md:text-sm bg-white"
              >
                {" "}
                {section4Data}
              </button>
              <button
                onClick={() => navigate("/auth/signup")}
                className="flex flex-row-reverse items-center gap-1 border border-gray-300 px-2 md:px-3 py-1 md:py-2 rounded-full font-bold text-xs md:text-sm bg-primary text-white"
              >
                {" "}
                {section5Data}
              </button>
            </div>
          </div>
          <Components planRef={planRef} />
        </div>
      </div>
    </>
  );
};

export default LandingPage;
