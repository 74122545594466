import React, { useState, useEffect } from "react";
import { FaCheck } from "react-icons/fa6";
import { createClient } from "contentful";

const client = createClient({
  space: "60rew5hef1yw",
  accessToken: "m4B-e9c0HFWhlzeBZfi0grKH0QSIIDlLzT3TknflgnM"
});

const TestimonialComponent = () => {
  const [heading1Data, setHeading1Data] = useState("");
  const [paragraph1Data, setParagraph1Data] = useState("");
  const [paragraph2Data, setParagraph2Data] = useState("");
  const [paragraph3Data, setParagraph3Data] = useState("");
  const [paragraph4Data, setParagraph4Data] = useState("");
  const [paragraph5Data, setParagraph5Data] = useState("");
  const [imageURL, setImageURL] = useState("");

  useEffect(() => {
    const getData = async () => {
      try {
        const entry = await client.getEntry("5HzFJTzclSA0WToSTwJ2jc");
        setHeading1Data(entry.fields.heading1);
        setParagraph1Data(entry.fields.paragraph1.content[0].content[0].value);
        setParagraph2Data(entry.fields.paragraph2.content[0].content[0].value);
        setParagraph3Data(entry.fields.paragraph3.content[0].content[0].value);
        setParagraph4Data(entry.fields.paragraph4.content[0].content[0].value);
        setParagraph5Data(entry.fields.paragraph5.content[0].content[0].value);

        const imageId = entry.fields.image1.sys.id;
        const asset = await client.getAsset(imageId);
        const imageUrl = "https:" + asset.fields.file.url;
        setImageURL(imageUrl);
      } catch (error) {
        console.error("Error fetching entry data: ", error);
      }
    };

    getData();
  }, []);

  return (
    <div className="flex flex-col lg:flex-row gap-10 mt-10">
      <div className="flex justify-center items-center lg:justify-start flex-1 p-4">
        <div className="relative w-full max-w-full aspect-w-16 aspect-h-9">
          <img
            src={imageURL}
            alt="Fetched Asset"
            className="rounded-xl border-16 border-primary object-contain shadow-2xl w-full"
          />
        </div>
      </div>
      <div className="flex-1 p-4">
        <div>
          <h1 className="text-3xl text-primary font-bold">{heading1Data}</h1>
          <p className="mt-4 text-black/80 text-lg">{paragraph1Data}</p>
          <p className="mt-4 text-black/80 text-lg">{paragraph2Data}</p>
        </div>
        <div className="mt-8">
          <p className="flex items-center mb-4 text-black/80 text-lg">
            <FaCheck className="text-green-500 mr-2" />
            {paragraph3Data}
          </p>
          <p className="flex items-center mb-4 text-black/80 text-lg">
            <FaCheck className="text-green-500 mr-2" />
            {paragraph4Data}
          </p>
          <p className="flex items-center text-black/80 text-lg">
            <FaCheck className="text-green-500 mr-2" />
            {paragraph5Data}
          </p>
        </div>
      </div>
      
    </div>
  );
};

export default TestimonialComponent;
