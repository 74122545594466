// index.js

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, useLocation } from "react-router-dom";
import "./index.css";
import App from "./App";
import posthog from 'posthog-js'

posthog.init('phc_sKY85r9pXDkt01VytNoDIrMyiW4P0KCoSkCprrpf6On',
    {
        api_host: 'https://us.i.posthog.com',
        person_profiles: 'identified_only' // or 'always' to create profiles for anonymous users as well
    }
)

// Function to load Google Fonts
const loadGoogleFonts = () => {
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = 'https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Montserrat:wght@400;700&family=Poppins:wght@400;700&family=Nunito:wght@400;700&family=Tilt+Warp:wght@400;700&family=Plus+Jakarta+Sans:wght@400;700&display=swap';
  document.head.appendChild(link);
};

// Call the function to load Google Fonts
loadGoogleFonts();

// Taku analytics script component
const TakuAnalyticsScript = () => {
  const location = useLocation();

  React.useEffect(() => {
    // Load Taku analytics script
    (function(t,e,a,n){
      function c(){
        if(!e.getElementById(a)){
          var t=e.getElementsByTagName(n)[0],
              c=e.createElement(n);
          c.type="text/javascript";
          c.async=!0;
          c.src="https://cdn.taku-app.com/js/latest.js";
          t.parentNode.insertBefore(c,t)
        }
      }
      if("function"!==typeof t.Taku){
        var s=function(){s.q.push(arguments)};
        s.q=[];
        t.Taku=s;
        "complete"===e.readyState ? c() : t.attachEvent ? t.attachEvent("onload",c) : t.addEventListener("load",c,!1)
      }
    })(window, document, "taku-js", "script");

    window.Taku('news:boot', {
      api_public_key: "528eb3db697bf02ab58d5bd6cab886c5",
    });

    // Clean up function (optional)
    return () => {
      // Any clean up code if needed
    };
  }, [location.pathname]);

  return null; // This component doesn't render anything in the DOM
};

// Root rendering for React app
const root = ReactDOM.createRoot(document.getElementById("root"));
// Define your routes
const routesWithoutAnalytics = [
  '/',
  '/dashboard',
  '/lead-magnet',
  '/store',
  '/analytics',
  '/questionnaire',
  '/feedback',
  '/support',
  '/select-ebook',
  '/ebook-mini-landing',
  '/my-page'
];

root.render(
  <BrowserRouter>
    <App />
    {/* Conditional rendering of Taku analytics script */}
    {routesWithoutAnalytics.includes(window.location.pathname) === true && (
      <TakuAnalyticsScript />
    )}
  </BrowserRouter>
)