import React, { useState, useEffect } from "react";
import moneyBackSVG from "../../assets/money-back.svg";
import UserIcon1 from "../../assets/profile1.jpg";
import UserIcon2 from "../../assets/profile2.jpg";
import UserIcon3 from "../../assets/profile3.jpg";
import UserIcon4 from "../../assets/profile4.jpg";
import FAQComponent from "./FAQComponent";
import { FaStar } from "react-icons/fa6";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { createClient } from "contentful";

const client = createClient({
  space: "60rew5hef1yw",
  accessToken: "m4B-e9c0HFWhlzeBZfi0grKH0QSIIDlLzT3TknflgnM"
});

const QuestionsComponent = () => {
  const navigate = useNavigate();
  const [heading, setHeading] = useState("");
  const [paragraph, setParagraph] = useState("");
  const [discountText, setDiscountText] = useState("");
  const [discountCode, setDiscountCode] = useState("");
  const [questionsAnswers, setQuestionsAnswers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const entry = await client.getEntry("4Mm26dUQQS3DfxW1jdBmhP");

        setHeading(entry.fields.heading);
        setParagraph(entry.fields.paragraph);
        setDiscountText(entry.fields.codeText)
        setDiscountCode(entry.fields.discountCode)
        console.log(entry.fields)
        const qa = [];
        for (let i = 1; i <= 10; i++) {
          const question = entry.fields[`question${i}`];
          const answer = entry.fields[`answer${i}`];
          if (question && answer) {
            qa.push({ question, answer });
          }
        }
        setQuestionsAnswers(qa);
      } catch (error) {
        console.error("Error fetching entry data: ", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <div className="mt-20">
        <div className="flex justify-center gap-10 items-center">
          <div>
            <img src={moneyBackSVG} className="mt-6" />
          </div>
          <div className="flex justify-center items-center gap-4 mt-8">
            <div className="flex -space-x-4 sm:-space-x-2 lg:-space-x-4 mt-4 lg:mt-0">
              <img
                src={UserIcon1}
                width={35}
                alt=""
                className="rounded-full border-2 border-white"
                style={{ objectFit: "cover", height: "35px", width: "35px" }}
              />
              <img
                src={UserIcon2}
                width={35}
                alt=""
                className="rounded-full border-2 border-white"
                style={{ objectFit: "cover", height: "35px", width: "35px" }}
              />
              <img
                src={UserIcon3}
                width={35}
                alt=""
                className="rounded-full border-2 border-white"
                style={{ objectFit: "cover", height: "35px", width: "35px" }}
              />
              <img
                src={UserIcon4}
                width={35}
                alt=""
                className="rounded-full border-2 border-white"
                style={{ objectFit: "cover", height: "35px", width: "35px" }}
              />
            </div>

            <div className="rounded-lg lg:overflow-visible">
              <div className="flex flex-col items-start font-bold text-blue-gray-500">
                <div className="inline-flex items-center">
                  <div className="flex space-x-1">
                    <FaStar className="text-yellow-500" />
                    <FaStar className="text-yellow-500" />
                    <FaStar className="text-yellow-500" />
                    <FaStar className="text-yellow-500" />
                    <FaStar className="text-yellow-500" />
                  </div>
                </div>
                <p className="block antialiased font-medium text-blue-gray-500 text-[14px]">
                  Loved by 100+ customers
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-between items-start p-4 md:p-10">
          <div className="left-div flex-1 md:mr-10">
            <h1 className="text-2xl md:text-4xl text-primary mb-4">
              {heading}
            </h1>
            <p className="text-[16px]">{paragraph}</p>
          </div>

          <FAQComponent questionsAnswers={questionsAnswers} />
        </div>
      </div>

      <div>
        <div className="flex flex-col justify-center items-center mt-10">
          <h1 className="text-3xl text-primary text-center mx-auto">
          {discountText}
          </h1>
          <button className="bg-primary text-white pl-4 pr-4 pt-2 pb-2 rounded-full flex items-center mt-6" onClick={() => navigate('/auth/signup')}>
            {discountCode}
            <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuestionsComponent;
