import React, { useState, useEffect } from "react";
import AuthWindow from "../components/AuthWindow/AuthWindow";
import { Formik } from "formik";
import AuthForm from "../components/AuthForm/AuthForm";
import * as Yup from "yup";
import axios from "axios";
import { useParams, Link, useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";
import { toast } from "react-toastify";

const ResetPasswordSchema = Yup.object().shape({
  new_password: Yup.string()
    .min(8, "Password must be at least 8 characters long.")
    .required("New Password is required"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("new_password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

const ResetPassword = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [error, setError] = useState("");

  const handleSubmit = async (values) => {
    try {
      const response = await axios.post(
        "/reset-password",
        {
          token: token,
          new_password: values.new_password,
        },
        {
          validateStatus: function (status) {
            return status >= 200 && status < 500;
          },
        }
      );
      if (response.status === 200) {
        const data = response.data;
        toast.success(data.message);
        navigate("/login");
      } else {
        setError("Failed to reset password");
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      setError("An unexpected error occurred");
    }
  };

  return (
    <div className="w-[100%] min-h-screen flex items-center">
      <AuthWindow />
      <section className="w-[100%] lg:w-[50%] min-h-screen flex flex-col items-center justify-center bg-[#FFFFFF44]">
        <figure className="lg:hidden flex gap-5 items-center mb-10">
          <img src={logo} width={50} className="mx-auto" alt="Logo" />
          <figcaption className="text-[#10277C] font-semibold font-serif">
            Link Leads
          </figcaption>
        </figure>
        <h3 className="text-center text-[#141414E5] font-semibold text-xl">
          Reset Password
        </h3>
        <Formik
          initialValues={{ new_password: "", confirm_password: "" }}
          validationSchema={ResetPasswordSchema}
          onSubmit={handleSubmit}
          validateOnChange={true}
          validateOnBlur={true}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            touched,
            errors,
          }) => (
            <form
              onSubmit={handleSubmit}
              className="mt-10 w-[90%] md:w-[75%] lg:w-[60%] flex flex-col items-center gap-4"
            >
              <div className="w-full">
                <label htmlFor="new_password" className="block text-sm font-medium text-gray-700">
                  New Password
                </label>
                <input
                  id="new_password"
                  name="new_password"
                  type="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.new_password}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                {touched.new_password && errors.new_password && <p className="text-red-500">{errors.new_password}</p>}
              </div>
              <div className="w-full">
                <label htmlFor="confirm_password" className="block text-sm font-medium text-gray-700">
                  Confirm Password
                </label>
                <input
                  id="confirm_password"
                  name="confirm_password"
                  type="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirm_password}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                {touched.confirm_password && errors.confirm_password && <p className="text-red-500">{errors.confirm_password}</p>}
              </div>
              {error && <p className="text-red-500">{error}</p>}
              <button
                type="submit"
                className="bg-[#3855B3] text-white rounded-full w-[100%] py-2 mt-2 hover:bg-white hover:text-[#3855B3] transition-all border border-[#3855B3]"
              >
                Reset Password
              </button>
              <Link to="/login" className="mt-3 text-sm text-[#6F74DD] hover:underline">
                Cancel
              </Link>
            </form>
          )}
        </Formik>
      </section>
    </div>
  );
};

export default ResetPassword;
