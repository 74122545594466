import React, { useState, useEffect } from "react";
import CloseIcon from "../../assets/close.svg";
import { FaArrowRight, FaCircleCheck } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import BuyCredits from "../BuyCredits/BuyCredits";
import "./pricing.css";
import axios from "axios";
import { toast } from "react-toastify";
import { createClient } from "contentful";
import Loading from "components/Loading/Loading";

const client = createClient({
  space: "60rew5hef1yw",
  accessToken: "m4B-e9c0HFWhlzeBZfi0grKH0QSIIDlLzT3TknflgnM",
});

const PricingSection = ({ setShowPopup, subscribed, email }) => {
  const navigate = useNavigate();
  const [showBuyCreditsPopup, setShowBuyCreditsPopup] = useState(false);
  const [scrollPos, setScrollPos] = useState(0);
  const [stripeData, setStripeData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [shouldRenderUI, setShouldRenderUI] = useState(false);
  const [plansList, setPlansList] = useState([]);
  const [sessionUrls, setSessionUrls] = useState({});
  const [data, setData] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const fetchData = async () => {
    try {
      const entry = await client.getEntry("5cSXMdRrZ6SSFYDYCXXvAM");
      const stripeResponse = await axios.get("/payment/get-stripe-data");
      setPlansList((prev) => [
        ...prev,
        ...Object.keys(stripeResponse.data.session_urls).map(Number),
      ]);
      setSessionUrls(stripeResponse.data.session_urls);

      setData({
        heading: entry.fields.heading,
        paragraph: entry.fields.paragraph,
        saleMessage: entry.fields.sale,
        plans: {
          0: {
            name: entry.fields.planName1,
            price: entry.fields.price1,
            discount: entry.fields.discount,
            features: [
              entry.fields.feature1,
              entry.fields.feature2,
              entry.fields.feature3,
            ].filter(Boolean),
          },
          1: {
            name: entry.fields.planName2,
            price: entry.fields.price2,
            discount: entry.fields.discount2,
            features: [
              entry.fields.feature1,
              entry.fields.feature2,
              entry.fields.feature3,
            ].filter(Boolean),
          },
          2: {
            name: entry.fields.planName3,
            price: entry.fields.price3,
            discount: entry.fields.discount3,
            features: [
              entry.fields.feature1,
              entry.fields.feature2,
              entry.fields.feature3,
            ].filter(Boolean),
          },
        },
      });

      setShouldRenderUI(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handlePlanRedirectOrRequest = async (planNumber) => {
    if (plansList.includes(0)) {
      const confirmed = window.confirm("Are you sure you want to proceed?");
      if (!confirmed) return;
    }

    const url = sessionUrls[planNumber];
    if (url) {
      if (!plansList.includes(0) && (planNumber === 1 || planNumber === 2)) {
        window.location.href = url;
      } else {
        setIsButtonDisabled(true);
        try {
          const response = await axios.get(url);
          toast.success(response.data.message);
        } catch {
          toast.error("Failed to retrieve the link");
        } finally {
          setIsButtonDisabled(false);
        }
      }
    }
    fetchData();
  };

  return (
    <>
      {isLoading && <Loading />}
      {shouldRenderUI && data && (
        <section
          id="pricingSectionContainer"
          className="flex flex-col text-base max-h-screen overflow-y-scroll lg:overflow-hidden h-[90vh] lg:h-full rounded-xl pricing-section-container"
          onWheel={(e) => e.preventDefault()}
        >
          <div className="flex items-center justify-between p-5 pt-3 bg-white sticky top-0 z-10">
            <p className="text-lg font-medium font-semibold text-[#3855B3]">
              {data.heading}
            </p>
            <div onClick={() => setShowPopup(false)} className="cursor-pointer">
              <img src={CloseIcon} alt="" />
            </div>
          </div>
          <div className="h-1 border-b border-b-[#D9D9D9] " />
          <div className="flex items-center justify-center mt-10 pb-10">
            <div className="flex flex-col items-center justify-center">
              <p className="text-xl font-medium text-center flex gap-2 justify-center mb-1 font-semibold">
                {data.paragraph}
              </p>
              <p className="text-xl font-medium text-center flex gap-2 justify-center mb-1 font-semibold">
                {data.saleMessage}
              </p>
              <div className="mt-5 grid gap-5 !mx-10 lg:grid-cols-2">
                {Object.entries(data.plans).map(
                  ([key, { name, price, discount, features }]) => (
                    <div
                      key={key}
                      className={`ring-1 ring-gray-200 rounded-3xl p-5 xl:px-5 border border-gray-400 flex flex-col justify-between`}
                    >
                      <div>
                        <h3 className="text-[#3855B3] text-2md font-semibold leading-8">
                          {name}
                        </h3>
                        <p className="mt-1 flex items-baseline gap-x-1">
                          {key != 0 && (
                            <span className="text-[20px] font-bold tracking-tight text-gray-500 line-through">
                              ${price}
                            </span>
                          )}
                          <span className="text-[25px] font-bold tracking-tight text-[#3855B3]">
                            ${(price * (1-discount)).toFixed(2)}
                          </span>
                          {key != 0 && discount!=0 && (
                            <span className="text-[12px] font-bold tracking-tight text-red-500">
                              {discount*100}% OFF
                            </span>
                          )}
                        </p>
                      </div>
                      <ul className="mt-3 space-y-3 text-sm leading-6 text-gray-600">
                        {features.map((desc, idx) => (
                          <li
                            key={idx}
                            className="flex items-center gap-x-3 text-[16px] font-semibold"
                          >
                            <FaCircleCheck />
                            <span>{desc}</span>
                          </li>
                        ))}
                      </ul>
                      <button
                        onClick={() => handlePlanRedirectOrRequest(Number(key))}
                        className={`w-full bg-blue-600 text-white hover:ring-blue mt-5 block rounded-xl py-2 px-3 text-center text-lg font-semibold leading-6 ${
                          !sessionUrls[key]
                            ? "opacity-60 cursor-not-allowed"
                            : ""
                        }`}
                        disabled={!sessionUrls[key] || isButtonDisabled}
                      >
                        {sessionUrls[key] ? `Get ${name}` : "Already Using"}
                      </button>
                    </div>
                  )
                )}
              </div>

              <Link
                to="/plans"
                className="text-center underline mt-5 text-blue-500"
              >
                View all plans
              </Link>
            </div>
          </div>
          {showBuyCreditsPopup && (
            <BuyCredits setShowBuyCreditsPopup={setShowBuyCreditsPopup} />
          )}
        </section>
      )}
    </>
  );
};

export default PricingSection;
