import "css/template4.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faLinkedin,
  faDribbble,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import LinkedInSVG from "assets/linkedin.svg";
import ZapSVG from "assets/zap.svg";
import LinkSVG from "assets/Linkblue.svg";
import ShareSVG from "assets/share.svg";
import TemplateCover from "assets/template4cover.jpeg";
import UserPic from "assets/template4user.png";
import React, { useState, useEffect } from "react";
import Button from "components/Button/Button";
import PricingSection from "components/PricingSection/PricingSection";
import Loading from "components/Loading/Loading";
import InstaSVG from "assets/insta.svg";
import XSVG from "assets/x.svg";
import DribbleSVG from "assets/dribble.svg";
import { useNavigate } from "react-router-dom";

const Template4 = ({ data }) => {
  const navigate = useNavigate();
  const socialIcons = {
    Instagram: InstaSVG,
    Twitter: XSVG,
    Dribble: DribbleSVG,
    LinkedIn: LinkedInSVG,
  };
  return (
    <div>
      {data.background.type === "Image" ? (
        <img
          src={data.coverPic || TemplateCover}
          onError={(e) => {
            e.target.src = TemplateCover;
          }}
          alt="Cover Photo"
          style={{
            width: "100%",
            height: "110vh",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        />
      ) : (
        <section
          style={{
            background:
              data.background.type === "SingleColor"
                ? data.background.color
                : data.background.type === "GradientColor"
                ? `linear-gradient(to right, ${data.background.gradient_1}, ${data.background.gradient_2})`
                : undefined,
            width: "100%",
            height: "110vh",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        />
      )}
      <div className="text-center flex flex-col items-center gap-5 mt-[8rem] relative z-2">
        <img
          src={data.profilePic || UserPic}
          onError={(e) => {
            e.target.src = UserPic;
          }}
          alt="User"
          style={{
            width: "100px",
            height: "100px",
            borderRadius: "20%",
            border: "3px solid #ffffff",
            objectFit: "cover",
          }}
        />
        <div className="w-[80%] sm:max-w-[50%]">
          <div
            style={{
              color: "white",
              textAlign: "center",
            }}
          >
            <h2
              style={{
                fontSize: "20px",
                fontWeight: "700",
                lineHeight: "24px",
                color: "#3855B3",
              }}
            >
              @{data.username}
            </h2>
            <p
              style={{
                color: "#3855B3",
              }}
            >
              {data.profession}
            </p>
          </div>
          <div
            style={{
              color: "#3855B3",
            }}
          >
            <p>{data.description}</p>
          </div>
        </div>
        <div
          className="ssocial-iconsssss"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {data.basic_links.map((link, index) => {
            const Icon = socialIcons[link.title];
            return (
              <a
                key={index}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={Icon || LinkSVG}
                  alt={link.title}
                  style={{
                    margin: "0 15px",
                    width: "30px",
                    height: "30px",
                  }}
                />
              </a>
            );
          })}
        </div>
      </div>
      <div
        className="flex flex-col text-center items-center gap-5 text-white mx-auto my-[2rem] w-[80%] sm:max-w-[50%] relative z-2"
      >
        {data.ebooks.length!=0&&(<button
          className="bb1111"
          onClick={() => navigate("/lead-magnet-quiz/ebook/" + data.username)}
          style={{
            backgroundColor: "rgb(247, 247, 247)",
            height: "50px",
            borderRadius: "50px",
            width: "100%",
            padding: "10px 20px",
            border: "1px solid rgb(197, 195, 195)",
            color: "#3855B3",
            textDecoration: "none",
            fontSize: "18px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <span className="text">My Ebooks</span>
        </button>)}
        {data.stores.length!=0&&(<button
          className="bb2222"
          onClick={() => navigate("/lead-magnet-quiz/product/" + data.username)}
          style={{
            backgroundColor: "rgb(247, 247, 247)",
            height: "50px",
            borderRadius: "50px",
            width: "100%",
            padding: "10px 20px",
            border: "1px solid rgb(197, 195, 195)",
            color: "#3855B3",
            textDecoration: "none",
            fontSize: "18px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <span className="text">My Store</span>
        </button>)}
        {data.videos.length!=0&&(<button
          className="bb3333"
          onClick={() => navigate("/lead-magnet-quiz/video/" + data.username)}
          style={{
            backgroundColor: "rgb(247, 247, 247)",
            height: "50px",
            borderRadius: "50px",
            width: "100%",
            padding: "10px 20px",
            border: "1px solid rgb(197, 195, 195)",
            color: "#3855B3",
            textDecoration: "none",
            fontSize: "18px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <span className="text">My Videos</span>
        </button>)}
      </div>

      {!data.subscribed && (<p
        className="text-center relative z-2"
        style={{
          color: "#3855B3",
        }}
      >
        <a href="https://example.com" className="font-semibold">
        Powered by LinkLeads.AI
        </a>
      </p>)}
    </div>
  );
};

export default Template4;
