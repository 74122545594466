import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { createClient } from "contentful";
import Loading from "../components/Loading/Loading";
import { FaCheckCircle } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";

const client = createClient({
  space: "60rew5hef1yw",
  accessToken: "m4B-e9c0HFWhlzeBZfi0grKH0QSIIDlLzT3TknflgnM"
});

const Plan = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [shouldRenderUI, setShouldRenderUI] = useState(false);
  const [toggle, setToggle] = useState("left");
  const [data, setData] = useState(null);
  const [sessionUrls, setSessionUrls] = useState({});
  const [responseMessage, setResponseMessage] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [plansList, setplansList] = useState([]);
  const [couponCode, setCouponCode] = useState("");

  const fetchData = async () => {
    try {
      const entry = await client.getEntry("5cSXMdRrZ6SSFYDYCXXvAM");
      const stripeResponse = await axios.get("/payment/get-stripe-data");
      setplansList((prev) => [
        ...Object.keys(stripeResponse.data.session_urls).map(Number)
      ]);
      setSessionUrls(stripeResponse.data.session_urls);
      setShouldRenderUI(true);

      setData({
        heading: entry.fields.heading,
        paragraph: entry.fields.paragraph,
        saleMessage: entry.fields.sale,
        plans: {
          0: {
            name: entry.fields.planName1,
            price: entry.fields.price1,
            discount: entry.fields.discount,
            features: [
              entry.fields.feature1,
              entry.fields.feature2,
              entry.fields.feature3,
              entry.fields.feature4,
              entry.fields.feature5,
              entry.fields.feature6,
              entry.fields.feature7,
              entry.fields.feature8,
              entry.fields.feature9,
              entry.fields.feature10
            ].filter(Boolean)
          },
          1: {
            name: entry.fields.planName2,
            price: entry.fields.price2,
            discount: entry.fields.discount2,
            features: [
              entry.fields.feature11,
              entry.fields.feature12,
              entry.fields.feature13,
              entry.fields.feature14,
              entry.fields.feature15,
              entry.fields.feature16,
              entry.fields.feature17,
              entry.fields.feature18,
              entry.fields.feature19,
              entry.fields.feature20
            ].filter(Boolean)
          },
          2: {
            name: entry.fields.planName3,
            price: entry.fields.price3,
            discount: entry.fields.discount3,
            features: [
              entry.fields.feature21,
              entry.fields.feature22,
              entry.fields.feature23,
              entry.fields.feature24,
              entry.fields.feature25,
              entry.fields.feature26,
              entry.fields.feature27,
              entry.fields.feature28,
              entry.fields.feature29,
              entry.fields.feature30
            ].filter(Boolean)
          }
        }
      });

      setSessionUrls(stripeResponse.data.session_urls);
      setShouldRenderUI(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handlePlanRedirectOrRequest = async (planNumber) => {
    if (plansList.includes(0)) {
      const confirmed = window.confirm("Are you sure you want to proceed?");
      if (!confirmed) return;
    }

    const url = sessionUrls[planNumber];
    if (url) {
      if (!plansList.includes(0) && (planNumber === 1 || planNumber === 2)) {
        window.location.href = url;
      } else {
        setIsButtonDisabled(true);
        try {
          const response = await axios.get(url);
          toast.success(response.data.message);
        } catch (error) {
          if (error && error.response && error.response.data)
            toast.error(error.response.data.message);
        } finally {
          setIsButtonDisabled(false);
        }
      }
    }
    fetchData();
  };

  const handleApplyCoupon = async () => {
    try {
      const response = await axios.post("/payment/apply_coupon", {
        coupon_code: couponCode
      });

      setSessionUrls(response.data.session_urls);
      toast.success("Coupon applied!");
    } catch (error) {
      toast.error(error.response?.data?.error || "Failed to apply coupon");
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      {shouldRenderUI && data && (
        <div className="flex flex-col justify-center items-center min-h-screen px-4">
          <div className="mb-4">
            <input
              type="text"
              placeholder="Enter coupon code"
              value={couponCode}
              onChange={(e) => setCouponCode(e.target.value)}
              className="p-2 border border-gray-300 rounded-md"
            />
            <button
              onClick={handleApplyCoupon}
              className="ml-2 p-2 bg-primary text-white rounded-md"
            >
              Apply Coupon
            </button>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0 mt-8 items-end">
            {Object.entries(data.plans).map(([key, { name, price, discount, features }]) => (
              <div
                key={key}
                className={`bg-white w-full md:w-80 p-4 border border-primary rounded-3xl relative`}
              >
                {key === "1" && (
                  <div className="bg-primary h-20 w-full rounded-t-3xl absolute top-0 left-0">
                    <h1 className="text-white text-center mt-4 text-[30px]">
                      Most Popular
                    </h1>
                  </div>
                )}
                {key === "2" && (
                  <div className="bg-primary h-20 w-full rounded-t-3xl absolute top-0 left-0">
                    <h1 className="text-white text-center mt-4 text-[30px]">
                      Best Value
                    </h1>
                  </div>
                )}
                <div className={`relative pt-20`}>
                  <p className="flex items-start mb-2 font-semibold text-primary text-[20px]">
                    {name}
                  </p>
                  <h2 className="text-3xl font-bold my-4 text-primary">
                    <p className="mt-1 flex items-baseline gap-x-1">
                      {key != 0 && discount && (
                        <span className="text-[20px] font-bold tracking-tight text-gray-500 line-through">
                          ${price}
                        </span>
                      )}
                      <span className="text-[25px] font-bold tracking-tight text-[#3855B3]">
                        ${(price * (1-discount)).toFixed(2)}
                      </span>
                      {key != 0 && discount &&(
                        <span className="text-[12px] font-bold tracking-tight text-red-500">
                          {discount*100}% OFF
                        </span>
                      )}
                    </p>
                  </h2>
                  {features.map((desc, idx) => (
                    <p
                      key={idx}
                      className="flex items-start mb-4 text-[15px] font-semibold text-black/90"
                    >
                      <FaCheckCircle className="text-primary mr-4 flex-shrink-0 w-5 h-5" />{" "}
                      {desc}
                    </p>
                  ))}
                  <button
                    onClick={() => handlePlanRedirectOrRequest(Number(key))}
                    className={`w-full bg-primary text-white hover:ring-blue-300 mt-5 block rounded-xl py-2 px-3 text-center text-lg font-semibold leading-6 ${
                      !sessionUrls[key]
                        ? "opacity-60 cursor-not-allowed"
                        : ""
                    }`}
                    disabled={!sessionUrls[key] || isButtonDisabled}
                  >
                    {sessionUrls[key] ? `Get ${name}` : "Already Using"}
                  </button>
                  {responseMessage && (
                    <p className="text-center mt-2 text-red-500">
                      {responseMessage}
                    </p>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Plan;
