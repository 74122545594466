import React, { useEffect } from "react";

const FeedbackForm = () => {
  useEffect(() => {
    const scriptId = "featurebase-sdk";
    const initializeFeaturebase = () => {
      if (!document.getElementById(scriptId)) {
        const script = document.createElement("script");
        script.id = scriptId;
        script.src = "https://do.featurebase.app/js/sdk.js";
        document.getElementsByTagName("script")[0].parentNode.insertBefore(script, document.getElementsByTagName("script")[0]);
      }
    };

    if (document.readyState === "complete" || document.readyState === "interactive") {
      initializeFeaturebase();
    } else {
      document.addEventListener("DOMContentLoaded", initializeFeaturebase);
    }

    window.Featurebase = window.Featurebase || function () {
      (window.Featurebase.q = window.Featurebase.q || []).push(arguments);
    };

    window.Featurebase("embed", {
      organization: "linkleads",
      basePath: null,
      theme: "light",
      initialPage: "Board",
      hideMenu: false,
      hideLogo: false,
      filters: null,
      jwtToken: null,
      metadata: null,
      locale: "en",
    });
  }, []);

  return <div data-featurebase-embed></div>;
};

export default FeedbackForm;


// import React, { useEffect } from "react";

// const YourComponent = () => {
//   useEffect(() => {
//     const win = window;

//     if (typeof win.Featurebase !== "function") {
//       win.Featurebase = function () {
//         (win.Featurebase.q = win.Featurebase.q || []).push(arguments);
//       };
//     }
//     win.Featurebase("initialize_feedback_widget", {
//       organization: "linkleads",
//       theme: "light",
//       placement: "right", // optional - remove to hide the floating button
//       email: "aientrepreneurs@standout.digital", // optional
//       defaultBoard: "yourboardname", // optional - preselect a board
//       locale: "en", // Change the language
//       metadata: null, // Attach session-specific metadata to feedback
//     });
//   }, []);

//   return (
//     <>
//       <script src="https://do.featurebase.app/js/sdk.js" id="featurebase-sdk"></script>
//       <div>
//         <button data-featurebase-feedback>Open Widget</button>
//       </div>
//     </>
//   );
// };

// export default YourComponent;
