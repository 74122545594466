import "./pageeditor4.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faRedo,
  faUndo,
  faEye,
  faLink,
} from "@fortawesome/free-solid-svg-icons";
import DeleteSVG from "assets/deleteIcon.svg";
import DribbleSVG from "assets/dribble1.svg";
import LinkedInSVG from "assets/linkedin.svg";
import EditSVG from "assets/editIcon.svg";
import { FaArrowLeft, FaPlus, FaEdit } from "react-icons/fa";
import UserIcon from "assets/userPic.svg";
import AISVG from "assets/aisvg.svg";
import ChatBot from "../components/ChatBox/ChatBox";
import TemplateCover from "assets/template1.jpeg";
import UserPic from "assets/userPic.svg";
import InstaSVG from "assets/instagramLogo.svg";
import XSVG from "assets/twitterLogo.svg";
import GoogleSVG from "assets/googleLogo.svg";
import LinkSVG from "assets/Linkblue.svg";
import TikTokSVG from "assets/tiktokLogo.svg";
import WhatsappSVG from "assets/whatsappLogo.svg";
import FacebookSVG from "assets/facebookLogo.svg";
import React, { useState, useEffect } from "react";
import Loading from "../components/Loading/Loading";
import CategorySVG from "assets/category.svg";
import pageeditorIMG from "assets/pageeditorIMG.png";
import foodSVG from "assets/food.svg";
import PersonalSVG from "assets/personalInfo.svg";
import colorSVG from "assets/color.svg";
import NewLinkModel from "../components/Models/NewLink";
import CategoryModal from "../components/Models/Category";
import NewStoreModel from "../components/Models/NewStore";
import {
  FaChevronDown,
  FaChevronUp,
  FaFont,
  FaUpload,
  FaLink,
  FaCcMastercard,
} from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const Button = ({ text, icon, className, onClick }) => (
  <button className={className} onClick={onClick}>
    {text}
    {icon}
  </button>
);

const Template1 = () => {
  const navigate = useNavigate();
  const [openCategoryModel, setOpenCategoryModel] = useState(false);
  const [openStoreModel, setOpenStoreModel] = useState(false);
  const [openLinkModel, setOpenLinkModel] = useState(false);
  const [showBackgroundOptions, setShowBackgroundOptions] = useState(false);
  const [showUploadSubCards, setShowUploadSubCards] = useState(false);
  const [images, setImages] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [shouldRenderUI, setShouldRenderUI] = useState(false);
  const [openAIModel, setOpenAIModel] = useState(false);
  const [showManageLinks, setShowManageLinks] = useState(false);
  const [showNewLinks, setShowNewLinks] = useState(false);
  const [showModifyTemplateOptions, setShowModifyTemplateOptions] =
    useState(false);

  const [showEditProfile, setShowEditProfile] = useState(false);
  const [showEditProfilee, setShowEditProfilee] = useState(false);

  const handleColorChange = (event) => {
    setData({ ...data, color: event.target.value });
  };
  const [showFonts, setShowFonts] = useState(false);
  const [showColor, setShowColor] = useState(false);
  const [data, setData] = useState({
    coverPic: null,
    coverPicCover: true,
    profilePic: null,
    profilePicCover: true,
    color: "",
    background: {
      type: "Image",
      color: "#F8F5FF",
      gradient_1: "#06b6d4",
      gradient_2: "#3b82f6",
    },
  });
  const handleBackgroundOptionChange = (event) => {
    const value = event.target.value;
    setData({ ...data, background: { ...data.background, type: value } });
  };
  const [cropConfig, setCropConfig] = useState({
    imageKey: null,
    crop: { x: 0, y: 0 },
    zoom: 1,
    croppedImage: null,
  });
  const [showCropper, setShowCropper] = useState(false);

  const handleBackgroundToggle = () => {
    setShowBackgroundOptions(!showBackgroundOptions);
  };
  const handleUploadSubCardsToggle = () =>
    setShowUploadSubCards(!showUploadSubCards);

  const handleImageUpload = async (event, imageKey) => {
    const file = event.target.files[0];
    console.log("data");
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        console.log(imageKey);
        setImages((prevImages) => ({
          ...prevImages,
          [imageKey]: reader.result,
        }));
        console.log(images);
        setData({ ...data, [imageKey]: file });
        // handleSubmit(false);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleFontChange = (font) => {
    setData({ ...data, font });
  };

  const handleDeleteStore = async (storeId) => {
    try {
      await axios.delete(`/store/${storeId}`);
      setData({
        ...data,
        stores: data.stores.filter((store) => store.id !== storeId),
      });
    } catch (error) {
      console.error("Error deleting store:", error.message);
    }
  };
  const startCrop = (imageKey) => {
    setCropConfig((prevConfig) => ({
      ...prevConfig,
      imageKey,
      crop: { x: 0, y: 0 },
      zoom: 1,
    }));
    setShowCropper(true);
  };
  const handleToggle = () => {
    setShowModifyTemplateOptions(!showModifyTemplateOptions);
  };
  const handleManageLinksClick = () => {
    setShowManageLinks(true);
  };
  const handleDeleteCategory = async (categoryId) => {
    try {
      await axios.delete(`/category/${categoryId}`);
      setData({
        ...data,
        categories: data.categories.filter(
          (category) => category.id !== categoryId
        ),
      });
    } catch (error) {
      console.error("Error deleting category:", error.message);
    }
  };
  const handleBackClick = () => {
    setShowModifyTemplateOptions(false);
    setShowManageLinks(false);
    setShowNewLinks(false);
  };

  const handleCreateNewClick = () => {
    setShowNewLinks(!showNewLinks);
  };

  const handleEditProfileClick = () => {
    setShowEditProfile(!showEditProfile);
  };

  const handleEditProfileClickk = () => {
    setShowEditProfilee(!showEditProfilee);
  };

  const handleSubmit = async (showdetail = true) => {
    try {
      const formData = new FormData();

      // Append JSON data fields to formData
      Object.entries(data).forEach(([key, value]) => {
        if (key !== "coverPic" && key != "profilePic" && key != "background") {
          formData.append(key, value);
        } else if (key === "background")
          formData.append(key, JSON.stringify(value));
      });

      formData.append("coverPic", data.coverPic);
      formData.append("profilePic", data.profilePic);

      const response = await axios.post("user-data", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(response.data);
      if (response.status === 201) {
        console.log(response.data);
        if (showdetail) {
          toast.success("Data Updated!");
        }
      } else {
        toast.error(response.data.error);
      }
    } catch (error) {
      console.error("There was an error!", error);
      if (error.response.status < 500) toast.error(error.response.data.error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/user-data", {
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log("Data received:", response.data);

        if (response.status === 200) {
          console.log(response.data);
          setData(response.data);
          setImages({
            profilePic: response.data.profilePic,
            coverPic: response.data.coverPic,
          });
          setIsLoading(false);
          setShouldRenderUI(true);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {isLoading && <Loading />}
      {shouldRenderUI && (
        <div className={"font-" + data.font}>
          <div className="flex flex-col lg:flex-row w-full h-full overflow-hidden">
            <div className="flex-grow bg-[#f6f7fa] px-2 lg:px-10 pt-10">
              {/* <div className="bg-blue-200 bg-opacity-30 shadow-md p-6 md:p-16 flex flex-col md:flex-row items-start md:items-center rounded-md gap-6">
                <div className="flex-1">
                  <h2 className="text-[22px] font-semibold text-black/70">
                    Pick Link in Bio Templates
                  </h2>
                  <p className="pt-5">
                    Apply a template to your Link in bio page to get started.
                    You can change your links and appearance anytime.
                  </p>
                  <Button
                    onClick={() => {
                      navigate("/my-page");
                    }}
                    text="Preview"
                    icon={
                      <FontAwesomeIcon
                        icon={faEye}
                        className="text-white-600"
                      />
                    }
                    className="flex flex-row-reverse items-center gap-1 border border-gray-300 px-3 md:px-4 py-2 rounded-full font-bold text-xs bg-primary text-white mt-4"
                  />
                </div>
                <div className="flex justify-center md:justify-end">
                  <img
                    src={pageeditorIMG}
                    alt="User"
                    className="h-40 w-52 md:h-50 md:w-64"
                  />
                </div>
              </div> */}

              <div className="section-container mt-6">
                {data.background.type === "Image" ? (
                  <img
                    src={images.coverPic || TemplateCover}
                    onError={(e) => {
                      e.target.src = TemplateCover;
                    }}
                    alt="Cover Photo"
                    className="imgg w-full max-h-[350px] h-auto "
                  />
                ) : (
                  <section
                    style={{
                      background:
                        data.background.type === "SingleColor"
                          ? data.background.color
                          : data.background.type === "GradientColor"
                          ? `linear-gradient(to right, ${data.background.gradient_1}, ${data.background.gradient_2})`
                          : undefined,
                    }}
                    className="imgg"
                  />
                )}
                {/* <img src={images.coverPic || TemplateCover} alt="Cover Photo" className="imgg" /> */}

                <div className="userInfoo">
                <div className="userText">
                <img
                  src={images.profilePic || UserIcon}
                  onError={(e) => {
                    e.target.src = UserIcon;
                  }}
                  width={60}
                  alt=""
                  className="userImgg"
                  style={{
                    borderRadius: "70%",
                    objectFit: "cover",
                    width: 100,
                    height: 100,
                  }}
                /></div>
                  <div className="userText">
                    <h2 className="UserHeadingg">{data.name}</h2>
                    <p>@{data.username}</p>
                  </div>
                  <div className="buttonContainer">
                    <Button
                      text={!showEditProfile?"Edit Profile":"Edit Template"}
                      className="flex items-center gap-1 border border-gray-300 px-3 md:px-6 py-2 rounded-full font-bold text-xs bg-primary text-white"
                      onClick={handleEditProfileClick}
                    />
                  </div>
                </div>

                <div className="userDescription">
                  <p>{data.description}</p>
                </div>
                <div className="social-iconss">
                  {data.basic_links.map((basic_link) => {
                    const images = {
                      Instagram: InstaSVG,
                      Twitter: XSVG,
                      Whatsapp: WhatsappSVG,
                      Tiktok: TikTokSVG,
                      Facebook: FacebookSVG,
                      Dribble: DribbleSVG,
                      LinkedIn: LinkedInSVG,
                    };
                    return (
                      <div
                        className="icon-container"
                        onClick={() => {
                          window.open(
                            basic_link.url.startsWith("https:/")
                              ? basic_link.url
                              : "https://" + basic_link.url,
                            "_self"
                          );
                        }}
                      >
                        <img
                          src={images[basic_link.title] || LinkSVG}
                          alt="Instagram"
                          className="insta"
                        />
                      </div>
                    );
                  })}
                </div>
                <div className="p-6 flex flex-col md:flex-row justify-center gap-6">
                  <div className="border p-4 w-full md:w-60 h-auto rounded-xl flex flex-col">
                    {data.categories.length == 0 ? (
                      <div className="w-full rounded-xl flex flex-col items-center justify-center text-center">
                        <p className="category-paragraph text-2xl">+</p>
                        <h2 className="text-[20px] mt-2">
                          No category created yet
                        </h2>
                        <p className="pt-3 text-[14px] text-black/70">
                          Simply create your first Category. Just click on the
                          button
                        </p>
                        <button
                          onClick={() => setOpenCategoryModel(true)}
                          value="+ Add Category"
                          className="flex items-center gap-1 border border-gray-300 px-3 md:px-6 py-2 rounded-full font-bold text-xs bg-primary text-white mt-4"
                        >
                          + Add Category
                        </button>
                      </div>
                    ) : (
                      <div className="flex items-center justify-between w-full">
                        <h2 className="text-[20px]">My Category</h2>
                        <p
                          onClick={() => setOpenCategoryModel(true)}
                          className="text-2xl w-8 h-8 rounded-full bg-gray-200  text-blue-600 flex items-center justify-center cursor-pointer"
                        >
                          +
                        </p>
                      </div>
                    )}

                    <div className="flex mt-4 ml-0 flex-wrap items-end justify-center gap-2">
                      {data.categories.map((category) => (
                        <div
                          key={category.id}
                          className="flex flex-col items-center justify-center border-2 border-primary px-3 py-3 rounded-xl w-20"
                        >
                          <img
                            src={category.image + "?" + Math.random()}
                            alt={category.id}
                            style={{ height: "40px" }}
                          />
                          <p className="mt-4">{category.title}</p>
                          <div className="flex items-center gap-3 text-xl my-3">
                            {/* <button
                              className="shadow-icon cursor-pointer bg-none border-none"
                              onClick={() => setOpenCategoryModel(true)}
                            >
                              <img src={EditSVG} />
                            </button> */}
                            <button
                              className="shadow-icon cursor-pointer bg-none border-none"
                              onClick={() => handleDeleteCategory(category.id)}
                            >
                              <img src={DeleteSVG} />
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="border p-4 w-full md:w-60 h-auto rounded-xl flex flex-col">
                    {data.stores.length == 0 ? (
                      <div className="w-full rounded-xl flex flex-col items-center justify-center text-center">
                        <p className="text-2xl">+</p>
                        <h2 className="text-[20px] mt-2">
                          No store created yet
                        </h2>
                        <p className="pt-3 text-[14px] text-black/70">
                          Simply create your first Store. Just click on the
                          button
                        </p>
                        <button
                          onClick={() => setOpenStoreModel(true)}
                          value="+ Add Store"
                          className="flex items-center gap-1 border border-gray-300 px-3 md:px-6 py-2 rounded-full font-bold text-xs bg-primary text-white mt-4"
                        >
                          + Add Store
                        </button>
                      </div>
                    ) : (
                      <div className="flex items-center justify-between w-full">
                        <h2 className="text-[20px]">My Stores</h2>
                        <p
                          onClick={() => setOpenStoreModel(true)}
                          className="text-2xl w-8 h-8 rounded-full bg-gray-200  text-blue-600 flex items-center justify-center cursor-pointer"
                        >
                          +
                        </p>
                      </div>
                    )}
                    <div className="flex mt-4 ml-0 flex-wrap items-end justify-center gap-2">
                      {data.stores.map((store) => (
                        <div
                          key={store.id}
                          className="flex flex-col items-center justify-center border-2 border-primary px-3 py-3 rounded-xl w-20"
                        >
                          <img
                            src={store.image + "?" + Math.random()}
                            alt="Preview"
                            style={{ height: "40px" }}
                          />
                          <p className="mt-4">{store.title}</p>
                          <div className="flex items-center gap-3 text-xl my-3">
                            <button
                              className="shadow-icon cursor-pointer bg-none border-none"
                              onClick={() => setOpenStoreModel(true)}
                            >
                              {/* <img src={EditSVG} alt="" /> */}
                            </button>
                            <button
                              className="shadow-icon cursor-pointer bg-none border-none"
                              onClick={() => handleDeleteStore(store.id)}
                            >
                              <img src={DeleteSVG} alt="" />
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col md:flex-row justify-between items-center pb-5 mt-5">
                <h3 className="text-2xl font-medium"></h3>
                <div className="flex gap-5">
                </div>
              </div>
            </div>
            {/* right sidebar */}
            <div className="w-full lg:w-[350px] p-5 bg-white">
            <div className="w-[230px]">
            </div>
              <div
                onClick={() => setOpenAIModel(!openAIModel)}
                className="flex justify-end"
              >
                <button className="shadow-icon p-3">
                  <img src={AISVG} alt="" />
                </button>
              </div>

              <div className="flex flex-col gap-3 items-center relative">
                <img
                  src={images.profilePic || UserIcon}
                  onError={(e) => {
                    e.target.src = UserIcon;
                  }}
                  width={60}
                  alt=""
                  style={{
                    borderRadius: "70%",
                    objectFit: "cover",
                    width: 100,
                    height: 100,
                  }}
                />

                <p>@{data.username}</p>

                {/* Toggle content based on showEditProfile state */}
                {showEditProfile ? (
                  <div className="w-full">
                    <h2 className="text-xl mt-5">Personal Information</h2>
                    <div
                      className="flex items-center justify-between gap-3 border border-gray-200 p-2 rounded-2xl cursor-pointer mt-5"
                      onClick={handleEditProfileClickk}
                    >
                      <div className="flex items-center gap-2 p-2">
                        <div className="ml-1 w-12 h-12 bg-gray-200 rounded-full flex items-center justify-center shadow-md">
                          <img
                            src={PersonalSVG}
                            className="w-6 h-6"
                            alt="Personal SVG"
                          />
                        </div>
                        <p className="ml-1">Personal Details</p>
                      </div>
                      <FaChevronDown className="mr-2" />
                    </div>
                    {showEditProfilee && (
                      <>
                        <div className="border p-2 rounded-xl mt-1">
                          <div className="border rounded-xl p-2 my-2">
                            <p className="ml-2 sm:ml-4 font-semibold text-black/70">
                              Username
                            </p>
                            <input
                              type="text"
                              placeholder={data.username}
                              value={data.username}
                              onChange={(e) => {
                                setData({ ...data, username: e.target.value });
                              }}
                              className="border border-2 border-gray-300 p-1 rounded-md ml-2 sm:ml-4 mt-2 shadow-sm w-full sm:w-auto"
                            />
                            <p className="text-sm text-red-500 mt-1 ml-2 sm:ml-4">
                              If you change the username, your link will be
                              changed.
                            </p>
                          </div>

                          <div className="border rounded-xl p-2 my-2">
                            <p className="ml-2 sm:ml-4 font-semibold text-black/70">
                              Name
                            </p>
                            <input
                              type="text"
                              placeholder={data.name}
                              value={data.name}
                              onChange={(e) => {
                                setData({ ...data, name: e.target.value });
                              }}
                              className="border border-2 border-gray-300 p-1 rounded-md ml-2 sm:ml-4 mt-2 shadow-sm w-full sm:w-auto"
                            />
                          </div>
                          <div className="border rounded-xl p-2 my-2">
                            <p className="ml-2 sm:ml-4 font-semibold text-black/70">
                              Profession
                            </p>
                            <input
                              type="text"
                              placeholder="Software Engineer"
                              value={data.profession}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  profession: e.target.value,
                                });
                              }}
                              className="border border-2 border-gray-300 p-1 rounded-md ml-2 sm:ml-4 mt-2 shadow-sm w-full sm:w-auto"
                            />
                          </div>
                          <div className="border rounded-xl p-2 mt-2">
                            <p className="ml-2 sm:ml-4 font-semibold text-black/70">
                              Description
                            </p>
                            <textarea
                              placeholder="I'm a frontend developer"
                              value={data.description}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  description: e.target.value,
                                });
                              }}
                              className="border border-2 border-gray-300 p-1 rounded-md ml-2 sm:ml-4 mt-2 shadow-sm w-full sm:w-auto"
                              style={{ resize: "vertical", maxHeight: "10em" }}
                            />
                          </div>
                        </div>
                      </>
                    )}

                    <div
                      className="flex items-center justify-between gap-3 border border-gray-200 p-2 rounded-2xl cursor-pointer mt-5"
                      onClick={() => setShowColor(!showColor)}
                    >
                      <div className="flex items-center gap-2 p-2">
                        <div className="ml-1 w-12 h-12 bg-gray-200 rounded-full flex items-center justify-center shadow-md">
                          <img
                            src={colorSVG}
                            className="w-6 h-6"
                            alt="Color SVG"
                          />
                        </div>
                        <p className="ml-1">Pick Color</p>
                      </div>
                      <FaChevronDown className="mr-2" />
                    </div>
                    {showColor && (
                      <div className="flex flex-col gap-3 border border-gray-200 p-2 rounded-xl mt-2">
                        <div className="border border-2 border-grey-200 rounded-xl p-4 flex flex-wrap justify-center sm:justify-start">
                          <div className="flex items-center">
                            <input
                              type="radio"
                              id="color-blue"
                              name="color"
                              value="#0000FF"
                              className="hidden"
                              checked={data.color === "#0000FF"}
                              onChange={handleColorChange}
                            />
                            <label
                              htmlFor="color-blue"
                              className={`w-8 h-8 bg-blue-600 rounded-full m-2 cursor-pointer flex items-center justify-center ${
                                data.color === "#0000FF"
                                  ? "ring-2 ring-offset-2 ring-blue-600"
                                  : ""
                              }`}
                            ></label>
                            <input
                              type="radio"
                              id="color-orange"
                              name="color"
                              value="#FF7F00"
                              className="hidden"
                              checked={data.color === "#FF7F00"}
                              onChange={handleColorChange}
                            />
                            <label
                              htmlFor="color-orange"
                              className={`w-8 h-8 bg-orange-600 rounded-full m-2 cursor-pointer flex items-center justify-center ${
                                data.color === "#FF7F00"
                                  ? "ring-2 ring-offset-2 ring-orange-600"
                                  : ""
                              }`}
                            ></label>

                            <input
                              type="radio"
                              id="color-yellow"
                              name="color"
                              value="#FFD700"
                              className="hidden"
                              checked={data.color === "#FFD700"}
                              onChange={handleColorChange}
                            />
                            <label
                              htmlFor="color-yellow"
                              className={`w-8 h-8 bg-yellow-600 rounded-full m-2 cursor-pointer flex items-center justify-center ${
                                data.color === "#FFD700"
                                  ? "ring-2 ring-offset-2 ring-yellow-600"
                                  : ""
                              }`}
                            ></label>

                            <input
                              type="radio"
                              id="color-pink"
                              name="color"
                              value="#FF69B4"
                              className="hidden"
                              checked={data.color === "#FF69B4"}
                              onChange={handleColorChange}
                            />
                            <label
                              htmlFor="color-pink"
                              className={`w-8 h-8 bg-pink-600 rounded-full m-2 cursor-pointer flex items-center justify-center ${
                                data.color === "#FF69B4"
                                  ? "ring-2 ring-offset-2 ring-pink-600"
                                  : ""
                              }`}
                            ></label>
                          </div>
                        </div>
                      </div>
                    )}

                    <div
                      className="flex items-center justify-between gap-3 border border-gray-200 p-2 rounded-2xl cursor-pointer mt-5"
                      onClick={() => setShowFonts(!showFonts)}
                    >
                      <div className="flex items-center gap-2 p-2">
                        <div className="ml-1 w-12 h-12 bg-gray-200 rounded-full flex items-center justify-center shadow-md">
                          <FaFont className="text-blue-600" />
                        </div>
                        <p className="ml-1">Pick Font</p>
                      </div>
                      <FaChevronDown className="mr-2" />
                    </div>
                    {showFonts && (
                      <div className="flex flex-col gap-3 border border-gray-200 p-2 rounded-xl mt-2">
                        <div
                          className={`border border-2 border-grey-200 rounded-xl p-4 cursor-pointer ${
                            data.font === "roboto" ? "ring-2 ring-blue-600" : ""
                          }`}
                          onClick={() => handleFontChange("roboto")}
                        >
                          <h4 className="font-roboto text-[22px] text-black/70 pb-1 border-b border-b-2 border-gray-400">
                            Roboto
                          </h4>
                          <p className="font-roboto mt-2 text-[18px]">
                            Ali Hamza
                          </p>
                          <p className="font-roboto mt-2">
                            I'm a frontend developer, crafting captivating user
                            interfaces with HTML, CSS, and JavaScript.
                          </p>
                        </div>

                        <div
                          className={`border border-2 border-grey-200 rounded-xl p-4 cursor-pointer ${
                            data.font === "montserrat"
                              ? "ring-2 ring-blue-600"
                              : ""
                          }`}
                          onClick={() => handleFontChange("montserrat")}
                        >
                          <h4 className="font-montserrat text-[22px] text-black/70 pb-1 border-b border-b-2 border-gray-400">
                            Montserrat
                          </h4>
                          <p className="font-montserrat mt-2 text-[18px]">
                            Ali Hamza
                          </p>
                          <p className="font-montserrat mt-2">
                            I'm a frontend developer, crafting captivating user
                            interfaces with HTML, CSS, and JavaScript.
                          </p>
                        </div>

                        <div
                          className={`border border-2 border-grey-200 rounded-xl p-4 cursor-pointer ${
                            data.font === "poppins"
                              ? "ring-2 ring-blue-600"
                              : ""
                          }`}
                          onClick={() => handleFontChange("poppins")}
                        >
                          <h2 className="font-poppins text-[22px] text-black/70 pb-1 border-b border-b-2 border-gray-400">
                            Poppins
                          </h2>
                          <p className="font-poppins mt-2 text-[18px]">
                            Ali Hamza
                          </p>
                          <p className="font-poppins mt-2">
                            I'm a frontend developer, crafting captivating user
                            interfaces with HTML, CSS, and JavaScript.
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                ) : null}

                {showManageLinks ? (
                  <div>
                    <div className="flex items-center mb-5">
                      <button
                        onClick={handleBackClick}
                        className="flex items-center gap-2 text-blue-600"
                      >
                        <FaArrowLeft />
                        <span>Back</span>
                      </button>
                    </div>
                    <h2 className="text-2xl mb-5">Manage Basic Links</h2>
                    <div className="flex flex-col gap-4">
                      <div
                        className="flex items-center justify-between gap-3 border border-gray-200 p-2 rounded-2xl cursor-pointer"
                        onClick={handleCreateNewClick}
                      >
                        <div className="flex items-center gap-2 p-2">
                          <div className="ml-1 w-12 h-12 bg-gray-200 rounded-full flex items-center justify-center shadow-md">
                            <FaPlus className="text-blue-600" />
                          </div>
                          <p className="ml-1">Create new Basic Links</p>
                        </div>
                        <FaChevronDown className="mr-2" />
                      </div>
                      {showNewLinks && (
                        <>
                          <div className="border p-2 rounded-xl">
                            {data.basic_links.map((basic_link) => {
                              const images = {
                                Instagram: InstaSVG,
                                Twitter: XSVG,
                                Whatsapp: WhatsappSVG,
                                Tiktok: TikTokSVG,
                                Facebook: FacebookSVG,
                                Dribble: DribbleSVG,
                                LinkedIn: LinkedInSVG,
                              };
                              return (
                                <div className="flex flex-col items-start gap-2 p-4 border rounded-md shadow-md">
                                  <div className="flex items-center gap-2">
                                    <div className="w-12 h-12 bg-gray-100 rounded-md flex items-center justify-center shadow-sm">
                                      <img
                                        src={
                                          images[basic_link.title] || LinkSVG
                                        }
                                        style={{ height: "40px" }}
                                      />
                                    </div>
                                    <p className="text-lg font-medium">
                                      {basic_link.title}
                                    </p>
                                  </div>
                                  <input
                                    disabled
                                    type="text"
                                    placeholder="example13"
                                    value={basic_link.url}
                                    className="mt-2 p-2 border rounded-md w-full"
                                  />
                                </div>
                              );
                            })}

                            <button
                              disabled={
                                data.basic_links_limit ==
                                data.basic_links.length
                                  ? true
                                  : false
                              }
                              onClick={() => setOpenLinkModel(true)}
                              className="flex flex-row-reverse items-center gap-1 border border-gray-300 px-3 md:px-4 py-2 rounded-full font-bold text-xs bg-primary text-white mt-4"
                            >
                              Add Link
                            </button>
                          </div>
                        </>
                      )}

                      {/* <div
                        className="flex items-center justify-between gap-3 border border-gray-200 p-2 rounded-2xl cursor-pointer"
                        onClick={handleCreateNewClick1}
                      >
                        <div className="flex items-center gap-2 p-2">
                          <div className="ml-1 w-12 h-12 bg-gray-200 rounded-full flex items-center justify-center shadow-md cursor-pointer">
                            <FaEdit className="text-blue-600" />
                          </div>
                          <p className="ml-1 ">Manage Basic Links</p>
                        </div>
                        <FaChevronDown className="mr-2" />
                      </div>
                      {showNewLinks1 && (
                        <>
                          <div className="border p-2 rounded-xl">
                            <div
                              className="flex items-center justify-between gap-3 border border-gray-200 p-2 rounded-2xl cursor-pointer"
                              onClick={handleCustomLinkClick1}
                            >
                              <div className="flex items-center gap-2 p-2">
                                <div className="ml-1 w-10 h-10 bg-gray-200 rounded-xl flex items-center justify-center shadow-md">
                                  <FaLink className="text-blue-600" />
                                </div>
                                <p className="ml-1">Custom Link</p>
                              </div>
                              <FaChevronDown className="mr-2" />
                            </div>
                            {showCustomLinkInput1 && (
                              <div className="mt-2 flex flex-col gap-2">
                                <input
                                  type="text"
                                  placeholder="Enter custom link"
                                  value={customLink1}
                                  onChange={(e) =>
                                    setCustomLink1(e.target.value)
                                  }
                                  className="border p-2 rounded-md"
                                />
                                <button
                                  onClick={handleSaveCustomLink1}
                                  className="bg-blue-600 text-white p-2 rounded-md"
                                >
                                  Save Link
                                </button>
                              </div>
                            )}

                            <div
                              className="mt-1 flex items-center justify-between gap-3 border border-gray-200 p-2 rounded-2xl cursor-pointer"
                              onClick={handleInstagramClick1}
                            >
                              <div className="flex items-center gap-2 p-2">
                                <div className="ml-1 w-10 h-10 bg-gray-200 rounded-xl flex items-center justify-center shadow-md">
                                  <img src={InstaSVG} alt="Instagram" />
                                </div>
                                <p className="ml-1">Instagram</p>
                              </div>
                              <FaChevronDown className="mr-2" />
                            </div>
                            {showInstagramInput1 && (
                              <div className="mt-2 flex flex-col gap-2">
                                <input
                                  type="text"
                                  placeholder="Enter Instagram link"
                                  value={instagramLink1}
                                  onChange={(e) =>
                                    setInstagramLink1(e.target.value)
                                  }
                                  className="border p-2 rounded-md"
                                />
                                <button
                                  onClick={handleSaveInstagramLink1}
                                  className="bg-blue-600 text-white p-2 rounded-md"
                                >
                                  Save Link
                                </button>
                              </div>
                            )}
                          </div>
                        </>
                      )} */}
                    </div>
                  </div>
                ) : showModifyTemplateOptions && !showEditProfile ? (
                  <div className="w-full">
                    <div className="flex items-center mb-5">
                      <button
                        onClick={handleBackClick}
                        className="flex items-center gap-2 text-blue-600"
                      >
                        <FaArrowLeft />
                        <span>Back</span>
                      </button>
                    </div>
                    <h2 className="text-2xl mb-5">Modify Template</h2>
                    <div className="flex flex-col gap-4">
                      <>
                        <div
                          className="flex items-center justify-between gap-3 border border-gray-200 p-2 rounded-2xl cursor-pointer"
                          onClick={handleUploadSubCardsToggle}
                        >
                          <div className="flex items-center gap-2 p-2 ">
                            <div className=" ml-1 w-12 h-12 bg-white-200 rounded-full flex items-center justify-center shadow-md">
                              <FaUpload className="text-blue-600" />
                            </div>
                            <p className="ml-1">Upload Images</p>
                          </div>
                          {showUploadSubCards ? (
                            <FaChevronUp className="mr-2" />
                          ) : (
                            <FaChevronDown className="mr-2" />
                          )}
                        </div>

                        {showUploadSubCards && (
                          <div className="flex flex-col gap-3 border border-gray-200 p-2 rounded-2xl">
                            <div className="flex flex-col items-center gap-3 border border-gray-200 p-2 rounded-xl">
                            <div>
  <p className="text-black/70 pb-2 border-b border-gray-400">
    Upload Cover Picture
    <br />
    <span className="text-red-500 text-sm font-semibold">Recommended size: 1200x350 pixels</span>
  </p>
</div>

                              <div className="flex items-center">
                                <label className="choose-image-button text-[12px] text-gray-600 border border-gray-400 rounded p-2 cursor-pointer mr-14">
                                  Choose File
                                  <input
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) =>
                                      handleImageUpload(e, "coverPic")
                                    }
                                    className="hidden"
                                  />
                                </label>
                                {/* Empty div to push the delete icon to the right */}
                                {/* <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-6 w-6 cursor-pointer text-red-600"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M6 18L18 6M6 6l12 12"
                                  />
                                </svg> */}
                              </div>

                              {images.coverPic && (
                                <>
                                  <img
                                    src={images.coverPic}
                                    alt="Preview"
                                    className="image-preview cursor-pointer"
                                    onClick={() => startCrop("coverPic")}
                                  />
                                </>
                              )}
                              {/* {tobesoon} */}
                              {/* <div className="flex items-center gap-6 border-t-2 border-grey-300 pt-3">
                                <label className="flex items-center gap-2">
                                  <input
                                    onChange={(e) => {
                                      setData({
                                        ...data,
                                        coverPicCover: !data.coverPicCover,
                                      });
                                    }}
                                    name="cover"
                                    type="radio"
                                    className="form-checkbox h-4 w-4 text-blue-500"
                                  />
                                  Repeat
                                </label>
                                <label className="flex items-center gap-2">
                                  <input
                                    onChange={(e) => {
                                      setData({
                                        ...data,
                                        coverPicCover: !data.coverPicCover,
                                      });
                                    }}
                                    type="radio"
                                    name="cover"
                                    defaultChecked
                                    className="form-checkbox h-4 w-4 text-blue-500"
                                  />
                                  Cover
                                </label>
                              </div> */}
                            </div>
                            <div className="flex flex-col items-center gap-3 border border-gray-200 p-2 rounded-xl">
                              <div>
                                <p className="text-black/70 pb-2 border-b border-gray-400">
                                  Upload Profile Picture
                                </p>{" "}
                                {/* Added border-bottom style */}
                              </div>
                              <div className="flex items-center">
                                <label className="choose-image-button text-[12px] text-gray-600 border border-gray-400 rounded p-2 cursor-pointer mr-14">
                                  Choose File
                                  <input
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) =>
                                      handleImageUpload(e, "profilePic")
                                    }
                                    className="hidden"
                                  />
                                </label>
                                {/* Empty div to push the delete icon to the right */}
                                {/* <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-6 w-6 cursor-pointer text-red-600"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M6 18L18 6M6 6l12 12"
                                  />
                                </svg> */}
                              </div>
                              {images.profilePic && (
                                <>
                                  <img
                                    src={images.profilePic}
                                    alt="Preview"
                                    className="image-preview cursor-pointer"
                                    onClick={() => startCrop("profilePic")}
                                  />
                                </>
                              )}

                              {/* {tobesoon} */}
                              {/* <div className="flex items-center gap-6 border-t-2 border-grey-300 pt-3">
                                <label className="flex items-center gap-2">
                                  <input
                                    onChange={(e) => {
                                      setData({
                                        ...data,
                                        profilePicCover: !data.profilePicCover,
                                      });
                                    }}
                                    name="profile"
                                    type="radio"
                                    className="form-checkbox h-4 w-4 text-blue-500"
                                  />
                                  Repeat
                                </label>
                                <label className="flex items-center gap-2">
                                  <input
                                    onChange={(e) => {
                                      setData({
                                        ...data,
                                        profilePicCover: !data.profilePicCover,
                                      });
                                    }}
                                    type="radio"
                                    name="profile"
                                    defaultChecked
                                    className="form-checkbox h-4 w-4 text-blue-500"
                                  />
                                  Cover
                                </label>
                              </div> */}
                            </div>
                          </div>
                        )}

                        <div
                          className={`flex flex-col gap-3 border border-gray-200 p-2 rounded-2xl cursor-pointer ${
                            showBackgroundOptions ? "border-b-0" : ""
                          }`}
                          onClick={handleBackgroundToggle}
                        >
                          <div className="flex items-center justify-between gap-2">
                            <div className="flex items-center gap-2 p-2 ">
                              <div className=" ml-1 w-12 h-12 bg-white-200 rounded-full flex items-center justify-center shadow-md">
                                <FaLink className="text-blue-600" />
                              </div>
                              <p className="ml-1">Background</p>
                            </div>
                            {showBackgroundOptions ? (
                              <FaChevronUp className="mr-2" />
                            ) : (
                              <FaChevronDown className="mr-2" />
                            )}
                          </div>
                        </div>
                        {showBackgroundOptions && (
                          <div className="border border-t-2 border-gray-200 p-2 mb-5 rounded-b-2xl">
                            <div className="flex items-center gap-2 ml-6">
                              <input
                                type="radio"
                                id="singleColor"
                                name="background"
                                value="SingleColor"
                                checked={data.background.type == "SingleColor"}
                                onChange={handleBackgroundOptionChange}
                              />
                              <label htmlFor="singleColor">Single Color</label>
                            </div>
                            <div className="flex items-center gap-2 mt-2 ml-6">
                              <input
                                type="radio"
                                id="gradientColor"
                                name="background"
                                value="GradientColor"
                                checked={
                                  data.background.type == "GradientColor"
                                }
                                onChange={handleBackgroundOptionChange}
                              />
                              <label htmlFor="GradientColor">
                                Gradient Color
                              </label>
                            </div>
                            <div className="flex items-center gap-2 mt-2 ml-6">
                              <input
                                type="radio"
                                id="image"
                                name="background"
                                value="Image"
                                checked={data.background.type == "Image"}
                                onChange={handleBackgroundOptionChange}
                              />
                              <label htmlFor="image">Image</label>
                            </div>
                            {data.background.type == "SingleColor" && (
                              <>
                                <div className="flex items-center gap-2 mt-4 ml-6">
                                  <label htmlFor="image">
                                    Choose background Color
                                  </label>
                                </div>{" "}
                                <div className="flex items-center gap-2 mt-4 ml-6">
                                  <div className="h-6 w-6 overflow-hidden rounded-full inline-flex items-center relative">
                                    <input
                                      onChange={(e) =>
                                        setData({
                                          ...data,
                                          background: {
                                            ...data.background,
                                            color: e.target.value,
                                          },
                                        })
                                      }
                                      type="color"
                                      defaultValue={data.background.color}
                                      className="absolute h-16 w-16 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 overflow-hidden border-none m-0 p-0 cursor-pointer"
                                    />
                                  </div>
                                </div>
                              </>
                            )}

                            {data.background.type == "GradientColor" && (
                              <>
                                <div className="flex items-center gap-2 mt-4 ml-6">
                                  <label htmlFor="image">
                                    Choose gradient Color
                                  </label>
                                </div>{" "}
                                <div className="flex items-center gap-2 mt-4 ml-6">
                                  <div className="h-6 w-6 overflow-hidden rounded-full inline-flex items-center relative">
                                    <input
                                      onChange={(e) =>
                                        setData({
                                          ...data,
                                          background: {
                                            ...data.background,
                                            gradient_1: e.target.value,
                                          },
                                        })
                                      }
                                      type="color"
                                      defaultValue={data.background.gradient_1}
                                      className="absolute h-16 w-16 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 overflow-hidden border-none m-0 p-0 cursor-pointer"
                                    />
                                  </div>
                                  <div className="h-6 w-6 overflow-hidden rounded-full inline-flex items-center relative">
                                    <input
                                      onChange={(e) =>
                                        setData({
                                          ...data,
                                          background: {
                                            ...data.background,
                                            gradient_2: e.target.value,
                                          },
                                        })
                                      }
                                      type="color"
                                      defaultValue={data.background.gradient_2}
                                      className="absolute h-16 w-16 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 overflow-hidden border-none m-0 p-0 cursor-pointer"
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        )}
                      </>
                    </div>
                  </div>
                ) : !showEditProfile ? (
                  <>
                    <div
                      className="flex flex-col sm:flex-row items-center justify-between gap-3 border border-gray-200 p-2 rounded-2xl cursor-pointer mt-5 w-full"
                      onClick={handleToggle}
                    >
                      <div className="flex items-center gap-2">
                        <img src={CategorySVG} alt="" />
                        <p className="font-semibold text-black/70">
                          Modify Template
                        </p>
                      </div>
                    </div>
                    <div
                      className="flex flex-col sm:flex-row items-center justify-between gap-3 border border-gray-200 p-2 rounded-2xl cursor-pointer w-full"
                      onClick={handleManageLinksClick}
                    >
                      <div className="flex items-center gap-2 p-2">
                        <div className="ml-1 w-12 h-12 bg-gray-200 rounded-full flex items-center justify-center shadow-md">
                          <FaLink className="text-orange-600" />
                        </div>
                        <p className="ml-1 text-center sm:text-left">
                          Manage Basic Links
                        </p>
                      </div>
                    </div>
                    {/* {tobesoon-credit} */}
                    {/* <div className="flex items-center justify-between gap-3 border border-gray-200 p-2 rounded-2xl w-full cursor-pointer">
                      <div className="flex items-center gap-2">
                        <div className="ml-2 w-12 h-12 bg-gray-200 rounded-full flex items-center justify-center shadow-md">
                          <FaCcMastercard className="text-purple-600" />
                        </div>
                        <div className="column ml-2">
                          <p>Buy Credits</p>
                          <p className="text-[13px] mt-3 text-black/80">
                            Available: 16
                          </p>
                          <a href="#" className="text-primary underline">
                            Buy Credits
                          </a>
                        </div>
                      </div>
                      <FaChevronDown className="mr-2" />
                    </div> */}
                  </>
                ) : (
                  <></>
                )}
                <Button
                  onClick={handleSubmit}
                  text="Publish"
                  className="flex self-start gap-1 border border-gray-300 px-3 md:px-6 py-2 rounded-full font-bold text-xs bg-primary text-white"
                />
                <CategoryModal
                  openCategoryModel={openCategoryModel}
                  onClose={() => setOpenCategoryModel(false)}
                />
                <NewStoreModel
                  openStoreModel={openStoreModel}
                  onClose={() => setOpenStoreModel(false)}
                />
                <NewLinkModel
                  openLinkModel={openLinkModel}
                  onClose={() => setOpenLinkModel(false)}
                />
              </div>
            </div>
          </div>
          <div className="">
            <ChatBot />
          </div>
        </div>
      )}
    </>
  );
};

export default Template1;
