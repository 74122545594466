import React, { useState, useEffect } from "react";
import { FaLink } from "react-icons/fa6";
import { BiSad, BiSolidDislike } from "react-icons/bi";
import { createClient } from 'contentful';

const client = createClient({
  space: '60rew5hef1yw',
  accessToken: 'm4B-e9c0HFWhlzeBZfi0grKH0QSIIDlLzT3TknflgnM'
});

const SkeletonLoader = () => (
  <div className="flex flex-col justify-center items-center p-4 md:p-10">
    <div className="bg-gray-300 h-10 w-1/2 rounded mb-4"></div>
    <div className="flex flex-col md:flex-row space-y-10 md:space-y-0 md:space-x-10 lg:space-x-20 mt-10 md:mt-20">
      <div className="flex flex-col items-center flex-1">
        <div className="bg-gray-300 w-14 h-14 rounded-full flex justify-center items-center mb-4"></div>
        <div className="bg-gray-300 w-3/4 h-6 rounded"></div>
      </div>
      <div className="flex flex-col items-center flex-1">
        <div className="bg-gray-300 w-14 h-14 rounded-full flex justify-center items-center mb-4"></div>
        <div className="bg-gray-300 w-3/4 h-6 rounded"></div>
      </div>
      <div className="flex flex-col items-center flex-1">
        <div className="bg-gray-300 w-14 h-14 rounded-full flex justify-center items-center mb-4"></div>
        <div className="bg-gray-300 w-3/4 h-6 rounded"></div>
      </div>
    </div>
  </div>
);

const ProgressComponent = () => {
  const [scrolling, setScrolling] = useState(false);
  const [section18Data, setSection18Data] = useState("");
  const [section19Data, setSection19Data] = useState("");
  const [section20Data, setSection20Data] = useState("");
  const [section21Data, setSection21Data] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        const entry = await client.getEntry('5osmEo9ZeiE9t3HqCzOkyX');
        setSection18Data(entry.fields.heading1);
        setSection19Data(entry.fields.paragraph1.content[0].content[0].value);
        setSection20Data(entry.fields.paragraph2.content[0].content[0].value);
        setSection21Data(entry.fields.paragraph3.content[0].content[0].value);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isVisible = currentScrollPos > 50;
      setScrolling(isVisible);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    loading ? <SkeletonLoader /> : (
      <div
        className={`flex flex-col justify-center items-center p-4 md:p-10 transition-transform duration-500 ${
          scrolling ? "translate-y-0 opacity-100" : "translate-y-20 opacity-0"
        }`}
      >
        <h1 className="text-2xl md:text-3xl font-semibold mt-10 md:mt-20 text-primary text-center">
          {section18Data}
        </h1>
        <div className="flex flex-col md:flex-row space-y-10 md:space-y-0 md:space-x-10 lg:space-x-20 mt-10 md:mt-20">
          <div className="flex flex-col items-center flex-1">
            <div className="bg-gray-100 w-14 h-14 rounded-full flex justify-center items-center">
              <FaLink className="w-9 h-9 fill-blue-800" />
            </div>
            <h3 className="text-black/80 font-semibold text-[16px] md:text-[18px] text-center mt-5 md:mt-10">
              {section19Data}
            </h3>
          </div>

          <div className="flex flex-col items-center flex-1">
            <div className="bg-gray-100 w-14 h-14 rounded-full flex justify-center items-center">
              <BiSad className="w-9 h-9 fill-blue-800" />
            </div>
            <h3 className="text-black/80 font-semibold text-[16px] md:text-[18px] text-center mt-5 md:mt-10">
              {section20Data}
            </h3>
          </div>

          <div className="flex flex-col items-center flex-1">
            <div className="bg-gray-100 w-14 h-14 rounded-full flex justify-center items-center">
              <BiSolidDislike className="w-9 h-9 fill-red-600" />
            </div>
            <h3 className="font-semibold text-[16px] text-black/80 md:text-[18px] text-center mt-5 md:mt-10">
              {section21Data}
            </h3>
          </div>
        </div>
      </div>
    )
  );
};

export default ProgressComponent;
