import React from "react";

const Loading = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 pointer-events-none">
      <div className="absolute inset-0" />
      <div className="relative z-10 text-center  bg-gray-400 p-3 bg-opacity-30 flex flex-col  items-center justify-center">
        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-[#10277C] border-solid mb-4" />
        <div className="text-xl font-semibold text-white">Loading...</div>
      </div>
    </div>
  );
};

export default Loading;
