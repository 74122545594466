import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faLinkedin,
  faDribbble,
  faTwitter,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";

import TemplateCover from "assets/template2cover.jpeg";
import UserPic from "assets/template2user.jpeg";
import React from "react";
import { useNavigate } from "react-router-dom";
import { faLink } from "@fortawesome/free-solid-svg-icons";

const Template2 = ({ data }) => {
  const navigate = useNavigate();
  const socialIcons = {
    Instagram: faInstagram,
    Twitter: faTwitter,
    Facebook: faFacebook,
    Dribble: faDribbble,
    LinkedIn: faLinkedin,
  };

  return (
    <div class="grid">
      {data.background.type === "Image" ? (
        <img
          src={data.coverPic || TemplateCover}
          onError={(e) => {
            e.target.src = TemplateCover;
          }}
          alt="Cover Photo"
          className="w-full h-96 object-cover col-start-1 row-start-1"
        />
      ) : (
        <div
          className="w-full h-96 object-cover col-start-1 row-start-1"
          style={{
            background:
              data.background.type === "SingleColor"
                ? data.background.color
                : data.background.type === "GradientColor"
                ? `linear-gradient(to right, ${data.background.gradient_1}, ${data.background.gradient_2})`
                : "green",
          }}
        ></div>
      )}
      <div className="flex flex-row items-center justify-center col-start-1 row-start-1 text-white mt-24">
        <div  className="flex flex-row items-start justify-center col-start-1 row-start-1 text-white"><img
          src={data.profilePic || UserPic}
          alt="User"
          onError={(e) => {
            e.target.src = UserPic;
          }}
          className="rounded-full w-24 h-24 object-cover border-4 border-white mx-5"
        />
        <div className="w-1/2 text-sm">
          <h2 className="text-xl font-bold my-2">{data.name}</h2>
          <p className=" my-2">{data.profession}</p>
          
          <p className=" my-2 wei">{data.description}</p>
        </div></div>
      </div>

      <div className="flex flex-col items-center p-5 mx-11">
        {data.basic_links.map((link, index) => {
          const Icon = socialIcons[link.title];
          return (
            <button
              key={index}
              onClick={() => {
                window.open(link.url);
              }}
              className="rounded-full w-full max-w-screen-sm my-2 p-2 flex items-center justify-center bg-white border border-gray-300 cursor-pointer text-lg"
            >
              <FontAwesomeIcon icon={Icon || faLink} className="mr-2" />
              <span>{link.title}</span>
            </button>
          );
        })}
      </div>
      {!data.subscribed && (<p className="text-center p-5 text-gray-500">
        <a href="https://linkleads.ai" className="font-bold text-black">
        Powered by LinkLeads.AI
        </a>
      </p>)}
    </div>
  );
};

export default Template2;
