import { Link, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Logo from "../../assets/logo.png";
import DashboardIcon from "../../assets/dashboardIcon.svg";
import MyPageIcon from "../../assets/myPage.svg";
import PageEditorIcon from "../../assets/editPage.svg";
import LeadMagnetIcon from "../../assets/leadMgnt.svg";
import ProductsIcon from "../../assets/products.svg";
import AnalyticsIcon from "../../assets/analytics.svg";
import FeedbackIcon from "../../assets/feedback.svg";
import SupportIcon from "../../assets/support.svg";
import CampaignIcon from "../../assets/email2.svg";
import logoutIcon from "../../assets/logout.svg";
import SettingIcon from "../../assets/setting.svg";

const Sidebar = () => {
  const { pathname } = useLocation();
  const [activeItem, setActiveItem] = useState(null);
  const [sidebarHeight, setSidebarHeight] = useState(window.innerHeight);

  const data = [
    { src: DashboardIcon, text: "Dashboard", to: "/dashboard" },
    { src: LeadMagnetIcon, text: "Lead Magnet", to: "/lead-magnet" },
    { src: CampaignIcon, text: "Campaign", to: "/campaign" },
    { src: MyPageIcon, text: "My Page", to: "/my-page" },
    { src: PageEditorIcon, text: "Page Editor", to: "/page-editor" },
    // { src: ProductsIcon, text: "Store", to: "/store" },
    { src: AnalyticsIcon, text: "Analytics", to: "/analytics" },
    { src: FeedbackIcon, text: "Feedback", to: "/feedback" },
    { src: SupportIcon, text: "Support", to: "/support" },
    { src: SettingIcon, text: "Settings", to: "/settings" },
  ];

  const handleClick = (index) => {
    setActiveItem(index);
  };

  const handleLogout = async () => {
    try {
      const response = await axios.post("/logout");
      if (response.status === 201) {
        window.location.href = "/auth";
      }
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  useEffect(() => {
    const currentItemIndex = data.findIndex((item) => item.to === pathname);
    setActiveItem(currentItemIndex);
  }, [pathname]);

  useEffect(() => {
    const updateSidebarHeight = () => {
      setSidebarHeight(window.innerHeight);
    };

    window.addEventListener("resize", updateSidebarHeight);

    return () => window.removeEventListener("resize", updateSidebarHeight);
  }, []);

  return (
    <div
      className="w-20 bg-[#10277C] flex flex-col items-center fixed overflow-y-auto"
      style={{ height: sidebarHeight }}
    >
      <button onClick={handleLogout} className="mt-4 w-5">
        <img src={logoutIcon} alt="Logout" />
      </button>
      <p className={`mt-2 text-[10px] text-gray-400`}>Logout</p>
      <Link to="/dashboard">
        <img src={Logo} width={45} alt="Logo" className="my-12" />
      </Link>
      {data.map((item, index) => (
        <Link
          to={item.to}
          key={index}
          className="flex flex-col items-center justify-center cursor-pointer py-3"
          onClick={() => handleClick(index)}
        >
          <div
            className="mt-2"
            style={
              activeItem === index
                ? {
                    transform: "scale(1.5)",
                    transition: "transform 0.3s ease",
                  }
                : {}
            }
          >
            <img src={item.src} alt={item.text} />
          </div>
          <p
            className={`mt-2 text-[10px] ${
              activeItem === index
                ? "bg-transparent text-white"
                : "text-gray-400"
            }`}
            style={
              activeItem === index
                ? {
                    transform: "scale(1.2)",
                    transition: "transform 0.3s ease",
                  }
                : {}
            }
          >
            {item.text}
          </p>
        </Link>
      ))}
    </div>
  );
};

export default Sidebar;
