import React, { useState } from "react";
import axios from "axios";
import ProductCard from "../ProductCard/ProductCard";
import SuggestionSVG from "../../assets/suggestion.svg";
import { toast } from "react-toastify";

const AISuggestions = ({ formData, setFormData, setImageUrl, subscribed, type }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [suggestions, setSuggestions] = useState([
    {
      description:
        "Celebrate your victory with this limited edition Ryze Buffs T-Shirt. Made from high-quality cotton, it's perfect for any League of Legends fan.",
      image:
        "https://oaidalleapiprodscus.blob.core.windows.net/private/org-4RFm8s26xv5bdMZwxZ7zQrfJ/user-cwQXawQuFFYqrYM75rtLvPaO/img-9hN9oU0rK5RliVjFX9dCHqwJ.png?st=2024-08-29T16%3A30%3A52Z&se=2024-08-29T18%3A30%3A52Z&sp=r&sv=2024-08-04&sr=b&rscd=inline&rsct=image/png&skoid=d505667d-d6c1-4a0a-bac7-5c84a87759f8&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2024-08-28T23%3A10%3A52Z&ske=2024-08-29T23%3A10%3A52Z&sks=b&skv=2024-08-04&sig=CY0J5bD2fJDKiy2MWDWFEduLg54xDcrRrT0vMuTz3Tg%3D",
      price: 24.99,
      title: "Ryze Buffs Commemorative T-Shirt",
    },
    {
      description:
        "Capture the moment of your epic win with this vibrant Ryze Victory Poster. Perfect for your gaming room or office.",
      image:
        "https://oaidalleapiprodscus.blob.core.windows.net/private/org-4RFm8s26xv5bdMZwxZ7zQrfJ/user-cwQXawQuFFYqrYM75rtLvPaO/img-FrD41ZdEiE3rQ3QqwCrsuZzy.png?st=2024-08-29T16%3A31%3A00Z&se=2024-08-29T18%3A31%3A00Z&sp=r&sv=2024-08-04&sr=b&rscd=inline&rsct=image/png&skoid=d505667d-d6c1-4a0a-bac7-5c84a87759f8&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2024-08-28T23%3A12%3A01Z&ske=2024-08-29T23%3A12%3A01Z&sks=b&skv=2024-08-04&sig=sD2PTf1JvNNf0x5cTHEQOSyzqWOaeE5Sz5SPE6yYX5A%3D",
      price: 14.99,
      title: "Ryze Victory Poster",
    },
    {
      description:
        "Enhance your gaming setup with this Ryze Buffs Gaming Mouse Pad. Designed for precision and comfort, it's a must-have for any serious player.",
      image:
        "https://oaidalleapiprodscus.blob.core.windows.net/private/org-4RFm8s26xv5bdMZwxZ7zQrfJ/user-cwQXawQuFFYqrYM75rtLvPaO/img-NWEEpuYugtE62tlbzSz0G25v.png?st=2024-08-29T16%3A31%3A09Z&se=2024-08-29T18%3A31%3A09Z&sp=r&sv=2024-08-04&sr=b&rscd=inline&rsct=image/png&skoid=d505667d-d6c1-4a0a-bac7-5c84a87759f8&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2024-08-28T23%3A11%3A14Z&ske=2024-08-29T23%3A11%3A14Z&sks=b&skv=2024-08-04&sig=t6uW%2B6GkzaQJRMRekJVJdh%2BZtTenQfQ1hyC8/CbsIyE%3D",
      price: 19.99,
      title: "Ryze Buffs Gaming Mouse Pad",
    },
  ]);
  const [loading, setLoading] = useState(false);

  const handleButtonClick = () => {
    setShowPopup(true);
  };

  const handleCancel = () => {
    setShowPopup(false);
    setPrompt("");
  };

  const handleEnter = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/ai/suggestions", {
        params: { userRequirement: prompt, "type": type },
      });
      setSuggestions(response.data.suggestions);
      setPrompt("");
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error)
        toast.error(error.response.data.error);
      console.log("Error fetching AI suggestions:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSuggestionClick = async (suggestion) => {
    const updatedFormData = {
      ...formData,
      title: suggestion.title,
      description: suggestion.description,
      price: parseFloat(suggestion.price),
      imageUrl: suggestion.image,
      imageName: "",
      image: null,
    };

    setFormData(updatedFormData);
    setImageUrl(suggestion.image);
    setShowPopup(false);
  };

  return !subscribed ? (
    <></>
  ) : (
    <div className="p-4 bg-gray-100 border border-blue-300 rounded-lg flex items-center justify-between my-10 px-10 lg:px-20">
      <div>
        <h2 className="text-2xl font-bold mb-2">Generate Products</h2>
        <p className="text-gray-600">
          Generate the suggestions for your product title, description, and
          price
        </p>
        <button
          onClick={handleButtonClick}
          disabled={loading}
          className={`mt-4 px-4 py-2 rounded transition duration-200 ${
            loading
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-blue-600 text-white hover:bg-blue-700"
          }`}
        >
          Generate Ideas
        </button>
      </div>
      <div>
        <img src={SuggestionSVG} alt="Preview" className="w-48 h-auto" />
      </div>

      {showPopup && suggestions.length === 0 && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-20">
          <div className="bg-white p-6 rounded shadow-lg w-96">
            <h2 className="text-xl font-bold mb-4">
              Tell us about your product
            </h2>
            <input
              type="text"
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              placeholder="Enter your details here"
              className="w-full p-2 border rounded mb-4"
            />
            <div className="flex justify-end space-x-2">
              <button
                onClick={handleCancel}
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 transition duration-200"
              >
                Cancel
              </button>
              <button
                onClick={handleEnter}
                disabled={loading}
                className={`bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-200 ${
                  loading ? "bg-gray-400 cursor-not-allowed" : ""
                }`}
              >
                Enter
              </button>
            </div>
            {loading && (
              <div className="mt-4 text-center text-gray-600">
                Generating ideas, please wait...
              </div>
            )}
          </div>
        </div>
      )}

      {suggestions.length > 0 && showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-30 h-full">
          <div className="bg-white p-6 rounded shadow-lg w-full m-20 h-5/6 ml-36">
            <h3 className="text-2xl font-bold mb-4">Suggestions</h3>
            <div className="flex flex-wrap md:grid-cols-2 lg:grid-cols-3 gap-4 justify-center overflow-scroll h-5/6 items-center">
              {suggestions.map((suggestion, index) => (
                <div
                  key={index}
                  onClick={() => handleSuggestionClick(suggestion)}
                  className="cursor-pointer"
                >
                  <ProductCard
                    imageUrl={suggestion.image}
                    productName={suggestion.title}
                    price={suggestion.price.toString().split(".")[0] || "00"}
                    decimalPoint={
                      suggestion.price.toString().split(".")[1] || "00"
                    }
                  />
                </div>
              ))}
            </div>
            <div className="flex justify-end space-x-3">
              <div className="flex justify-end mt-4">
                <button
                  onClick={() => setShowPopup(false)}
                  className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 transition duration-200"
                >
                  Close
                </button>
              </div>
              <div className="flex justify-end mt-4">
                <button
                  onClick={() => {
                    setShowPopup(true);
                    setSuggestions([]);
                  }}
                  className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 transition duration-200"
                >
                  Generate a new idea
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AISuggestions;
