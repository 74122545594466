import React from 'react';

const TermsAndConditions = () => {
  return (
    <div style={{
      fontFamily: 'Arial, sans-serif',
      margin: '0 auto',
      padding: '20px',
      maxWidth: '800px',
      lineHeight: '1.6',
      color: '#333'
    }}>
      <div style={{ textAlign: "center" }}>
  <div style={{ fontSize: "20px", fontWeight: "bold" }}>Terms & Conditions</div>
  <p>Last Updated: January 24, 2024</p>
  <div style={{ fontSize: "24px", fontWeight: "bold" }}>INTRODUCTION AND ACCEPTANCE</div>
  <p>Digital Transformation Pro and/or its affiliates (“Digital Transformation Pro”, “we”, “us, and “our”) provide website features and other products and services to you when you visit or shop at LinkLeads.Ai   (“Website”), use Digital Transformation Pro’s products or services or use Digital Transformation Pro’s mobile/tablet applications (collectively, “Services”). Digital Transformation Pro provides the Services subject to the following terms and conditions (“Terms of Use”).</p>
  <p>PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING OUR SERVICES. BY USING ANY OF OUR SERVICES (OTHER THAN TO READ THESE TERMS OF USE FOR THE FIRST TIME) YOU ARE AGREEING TO COMPLY WITH THESE TERMS OF USE, WHICH MAY CHANGE FROM TIME TO TIME. YOU SHOULD PERIODICALLY VISIT THIS PAGE TO REVIEW SO THAT YOU ARE AWARE OF ANY CHANGES, AS THEY ARE BINDING ON YOU. IF YOU DO NOT AGREE TO BE BOUND BY THESE TERMS OF USE, DO NOT USE OUR SERVICES.</p>
  <p>While Digital Transformation Pro uses reasonable efforts to include accurate and up to date information in connection with the Services, we make no representations or warranties as to the accuracy, correctness, reliability or otherwise with respect to such information, and assume no liability or responsibility for any omissions or errors (including without limitation typographical errors and technical errors) in connection with the Services.</p>
  <p>Please review our Privacy Policy, which also governs your use of Services, to understand certain of our practices, and our Refund Policy which also governs the terms of any refunds we may provide for your purchase of Services.  By using this Website, you accept and agree to be bound and abide by the Privacy Policy, which is incorporated herein by reference.  If you do not agree to the Privacy Policy, you must not access or use the Website.</p>
  <div style={{ fontSize: "24px", fontWeight: "bold" }}>INTELLECTUAL PROPERTY</div>
  <p>This Website, our Services and associated content (and any derivative works or enhancements of the same) including, but not limited to, all text, illustrations, files, images, software, scripts, graphics, designs, photos, sounds, music, videos, information, content, materials, products, services, URLs, technology, documentation, and interactive features included with or available through our Services and all intellectual property rights to the same, including, without limitation, all trademarks, service marks, trade names and trade dress that may appear in our Services are owned by us, our licensors, our affiliates, or identified third parties (collectively, the “Service Content”). Except for the limited use rights granted to you in these Terms of Use, you shall not acquire any right, title or interest in our Services or any Service Content. Any rights not expressly granted in these Terms of Use are expressly reserved.</p>
  <div style={{ fontSize: "24px", fontWeight: "bold" }}>ACCESS AND USE</div>
  <p>Our Services are provided for your personal, non-commercial use only. When using our Services, you agree to comply with all applicable federal, state, and local laws including, without limitation, copyright law. Except as expressly permitted in these Terms of Use or as we may otherwise permit, you may not use, reproduce, duplicate, distribute, create derivative works based upon, publicly display, publicly perform, publish, transmit, or otherwise exploit Service Content for any purpose whatsoever without obtaining prior written consent from us or, in the case third-party content, its applicable owner. In certain instances, we may suggest, ask or otherwise permit you to download, install or print Service Content. In such a case, you may do so only in the manner authorized and for your non-commercial use only. We neither warrant nor represent that your use of the Service Content will not infringe rights of third parties. You acknowledge that you do not acquire any ownership rights by downloading, installing or printing Service Content.</p>
  <p>Furthermore, except as expressly permitted in these Terms of Use, you may not:</p>
  <p>remove, alter, cover, or distort any copyright, trademark, or other proprietary rights notice we include in or through our Services or Service Content;</p>
  <p>circumvent, disable or otherwise interfere with our security-related features including, without limitation, any features that prevent or restrict the use of or copying of any software or other Service Content;</p>
  <p>use an automatic device (such as a robot or spider) or manual process to copy or “scrape” the Website or Service Content for any purpose without our express written permission;</p>
  <p>collect or harvest any personally identifiable information or non-personally identifiable information from our Services including, without limitation, user names, passwords, email addresses;</p>
  <p>solicit other users to join or become members of any commercial online service or other organization without our prior written approval;</p>
  <p>attempt to or interfere with the proper working of our Services or impair, overburden, or disable the same;</p>
  <p>decompile, reverse engineer, or disassemble any portion of our software or other Service Content, or our Services;</p>
  <p>use network-monitoring software to determine architecture of or extract usage data from our Services;</p>
  <p>encourage conduct that violates any local, state or federal law, either civil or criminal, or impersonate another user, person, or entity (e.g., using another person’s Membership);</p>
  <p>violate U.S. export laws, including, without limitation, violations of the Export Administration Act and the Export Administration Regulations administered by the Department of Commerce; or</p>
  <p>engage in any conduct that restricts or inhibits any other user from using or enjoying our Services.</p>
  <p>You agree to fully cooperate with us to investigate any suspected or actual activity that is in breach of these Terms of Use.</p>
  <div style={{ fontSize: "24px", fontWeight: "bold" }}>USER REGISTRATION & PROMOTIONAL MESSAGES</div>
  <p>IN ORDER TO PURCHASE OUR PRODUCTS OR ACCESS OR USE SOME FEATURES OF OUR SERVICES, YOU MAY HAVE TO BECOME A REGISTERED USER. IF YOU ARE UNDER THE AGE OF THIRTEEN (13), THEN YOU ARE NOT PERMITTED TO REGISTER AS A USER, SIGN UP FOR ANY PROMOTIONAL MESSAGES (SEE BELOW), OR OTHERWISE PROVIDE US ANY PERSONAL INFORMATION.</p>
  <p>If you become a registered user, you will provide true, accurate and complete registration information and, if such information changes, you will promptly update the relevant registration information. During registration, you will create a user name and password (a “Membership”). You are solely responsible for safeguarding and maintaining the confidentiality of your Membership. You are solely responsible for the activity that occurs under your Membership, whether or not you have authorized the activity. You agree to contact us immediately at info@standout.digital if you become aware of any breach of security or unauthorized use of your Membership.</p>
  <p>Promotional Messages. Our Services may include sending you promotional e-mails and/or newsletters, which you consent to receive. These are typically recurring message programs that, upon registration, will be sent to you until you unsubscribe or opt-out via any of our disclosed methods. Our use of the information you provide is governed by our Privacy Policy.</p>
  <p>Emails/Newsletters. You may opt-out of receiving our e-mails or newsletters as set forth in our Privacy Policy.</p>
  <div style={{ fontSize: "24px", fontWeight: "bold" }}>PAYMENT</div>
  <p>Terms of payment for our products are within Digital Transformation Pro’s sole discretion. Payment for the products will be made by credit card. The contract for sale of goods between us and any customer is not entered into until we charge a valid credit card. The posting of prices on our Website is not an offer for sale of goods, and the order confirmation is not an acceptance of an offer to purchase goods. We generally charge your credit card once your order has been accepted.</p>
  <p>You agree to provide current, complete and accurate purchase and account information for all purchases made on our Website. You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed.</p>
  <div style={{ fontSize: "24px", fontWeight: "bold" }}>TAXES</div>
  <p>You are responsible for sales and all other taxes associated with any order to purchase our products, however designated, except for taxes on Digital Transformation Pro’s net income.</p>
  <div style={{ fontSize: "24px", fontWeight: "bold" }}>PRODUCTS; NOT FOR RESALE</div>
  <p>Digital Transformation Pro’s policy is one of on-going product updates and revisions. Digital Transformation Pro may revise or discontinue products at any time.  Digital Transformation Pro reserves the right to limit order quantity at any time without advance notice. Prices and promotions are subject to change without advance notice, at our sole discretion. You agree and represent that you are buying for your own internal use. If you wish to purchase any product for resale please contact Digital Transformation Pro at info@standout.digital.  Because customer service is paramount to our business, we reserve the right to refuse to sell products to you if it reasonably appears to us that you intend to resell the products. In addition, we reserve the right to limit quantities of items purchased by each customer. In addition, we reserve the right to cancel any order, or part of an order, or refuse service to anyone at our sole discretion. Such reasons may include, but are not limited to, unauthorized coupon use, product availability, price discrepancy or pricing error. We also reserve the right to change the terms of any offer or promotion at any time without notice.</p>
  <div style={{ fontSize: "24px", fontWeight: "bold" }}>RISK OF LOSS</div>
  <p>All items purchased from Digital Transformation Pro are made pursuant to a shipment contract. This means that the risk of loss and title for such items pass to you upon our delivery to you. In the event that your product is damaged during shipment, Digital Transformation Pro will replace the product at no charge.  Please contact Digital Transformation Pro within 4 days of receipt of product at info@standout.digital if such damage occurs.</p>
  <div style={{ fontSize: "24px", fontWeight: "bold" }}>ELECTRONIC COMMUNICATIONS</div>
  <p>When you use any Service, including purchasing any product, or send e-mails, text messages, and other communications from your desktop or mobile device to us, you are communicating with us electronically. You consent to receive communications from us electronically. We will communicate with you in a variety of ways, such as by e-mail, text, in-app push notices, or by posting notices and messages on this site. You agree that all agreements, notices, disclosures, and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing.  Your options, including your right to opt-out of receiving certain kinds of emails from Digital Transformation Pro, are further described in our Privacy Policy.</p>
  <div style={{ fontSize: "24px", fontWeight: "bold" }}>USER CONTENT</div>
  <p>We may now or in the future permit users to post, upload, transmit through, or otherwise make available through our Services (collectively, “submit”) messages, statements, testimonials, text, illustrations, data, files, images, graphics, photos, comments, sounds, music, videos, information, content, and/or other materials (“User Content”). By submitting User Content to Digital Transformation Pro through this Website, you agree that your User Content will become and remain the exclusive property of Digital Transformation Pro, including any future rights associated with your User Content, even if these Terms of Use are later modified or terminated. This means that you disclaim any proprietary rights in such User Content, and you acknowledge Digital Transformation Pros’ unrestricted right to use them (or materials or ideas similar to them) in any medium, now and in the future, without notice, compensation or other obligation to you or any other person. It also means Digital Transformation Pro has no obligation to keep your submissions confidential, even if it is not published through our Services. Although Digital Transformation Pro is under no obligation to review or monitor User Content, we reserve the absolute right to do so in our sole discretion. In addition, Digital Transformation Pro reserves the right to alter, edit, refuse to post or remove any User Content, in whole or in part, for any reason or for no reason. You agree that Digital Transformation Pro does not have any obligation to use or respond to any User Content. We do not guarantee any confidentiality with respect to User Content.</p>
  <p>You shall not submit any User Content protected by copyright, trademark, patent, trade secret, moral right, or other intellectual property or proprietary right without the express permission of the owner of the respective right. You are solely liable for any damage resulting from your failure to obtain such permission or from any other harm resulting from User Content that you submit.</p>
  <p>You represent, warrant, and covenant that you will not submit any User Content that:</p>
  <p>violates or infringes in any way upon the rights of others, including, but not limited to, any copyright, trademark, patent, trade secret, moral right, or other intellectual property or proprietary right of any person or entity;</p>
  <p>impersonates another or is unlawful, threatening, abusive, libelous, defamatory, invasive of privacy or publicity rights, vulgar, obscene, profane, pornographic, or otherwise objectionable, or otherwise violates any applicable law;</p>
  <p>encourages conduct that would constitute a criminal offense, give rise to civil liability or otherwise violate any law;</p>
  <p>is an advertisement for goods or services or a solicitation of funds;</p>
  <p>includes personal information such as messages which identify phone numbers, social security numbers, account numbers or addresses; or</p>
  <p>contains a formula, instruction, or advice that could cause harm or injury.</p>
  <p>any conduct by a user that in our sole discretion restricts or inhibits any other user from using or enjoying our Services will not be permitted.</p>
  <p>By submitting User Content to us, simultaneously with such posting you automatically grant, or represent or warrant that the owner has expressly granted, to us a worldwide, royalty-free, perpetual, irrevocable, non-exclusive, fully sublicensable, and transferable right and license to use, reproduce, distribute, create derivative works based upon (including, without limitation, translations), publicly display, publicly perform, transmit, and publish the User Content (in whole or in part) as we, in our sole discretion, deem appropriate including, without limitation, (1) in connection with our business; and (2) in connection with the businesses of our affiliates, licensees, assignees, successors, parents, subsidiaries, and their related companies. We may exercise this grant in any format, media or technology now known or later developed for the full term of any copyright that may exist in such User Content. Furthermore, you also grant other users permission to access your User Content and to use, reproduce, distribute, create derivative works based upon, publicly display, publicly perform, transmit, and publish your User Content for personal, non-commercial use as permitted by the functionality of our Services and these Terms of Use. Notwithstanding the foregoing, you waive any and all claims you (or any copyright holder) may now or later have in any jurisdiction to so-called “moral rights” with respect to the User Content.</p>
  <p>By submitting User Content, you also grant us the worldwide, perpetual, irrevocable, fully sublicensable, and transferable right, but not the obligation, to use any and all names, identities, titles, likenesses, distinctive appearances, physical likenesses, images, portraits, pictures, photographs (whether still or moving), screen personas, voices, vocal styles, statements, gestures, mannerisms, personalities, performance characteristics, biographical data, signatures, and any other indicia or imitations of identity or likeness listed, provided, referenced, or otherwise contained in the User Content (all attributes, collectively, per person, a “Persona”), including, without limitation, your name and geographical location, for purposes of advertising and trade, in any format, medium, or technology now known or later developed without further notice, approval, or compensation, unless prohibited by law. Our uses of your Persona will be consistent with the terms of our  Privacy Policy, where it is applicable.</p>
  <p>Notwithstanding the generality of the foregoing, we reserve the right to display advertisements in connection with your User Content and to use your User Content for advertising, marketing, promotional, and other commercial purposes. You acknowledge and agree that your User Content may be included on the websites and advertising networks of our distribution partners, marketing partners, accounts, and third-party service providers (including their downstream users).</p>
  <p>We have the right, but not the obligation, to monitor User Content. We have the right in our sole discretion and for any reason whatsoever to edit, refuse to post, remove, or disable access to any User Content.</p>
  <div style={{ fontSize: "24px", fontWeight: "bold" }}>SERVICE CONTENT & THIRD PARTY LINKS</div>
  <p>Except for the sale of our products, we provide our Services including, without limitation, Service Content for educational, entertainment and/or promotional purposes only. You may not rely on any information and opinions expressed through any of our Services for any other purpose. In all instances, it is your responsibility to evaluate the accuracy, timeliness, completeness, or usefulness of any Service Content. Under no circumstances will we be liable for any loss or damage caused by your reliance on any Service Content.</p>
  <p>Any health-related Service Content available is not intended to be a substitute for professional medical advice. Digital Transformation Pro does not warrant the validity of any such health-related statements or claims found on our website or through our Services. All such information is general in nature and may be helpful to some persons but not others, depending upon their personal needs. The products identified on the Website are intended to prevent and treat exercise associated muscle cramps and are not intended to diagnose, treat, cure, or prevent any disease.  You should always consult with your physician prior to changing or undertaking a new diet or exercise program. Never disregard professional medical advice or delay in seeking it because of something you have read on or through our Services.</p>
  <p>In many instances, Service Content will include content posted by a third-party or will represent the opinions and judgments of a third-party. We do not endorse, warrant and are not responsible for the accuracy, timeliness, completeness, or reliability of any opinion, advice, or statement offered through our Services by anyone other than our authorized employees or spokespersons while acting in their official capacities.</p>
  <p>Our Services may link or contain links to other websites maintained by third parties. We do not operate or control, in any respect, or necessarily endorse the content found on these third-party websites. You assume sole responsibility for your use of third-party links. We are not responsible for any content posted on third-party websites or liable to you for any loss or damage of any sort incurred as a result of your dealings with any third-party or their website.</p>
  <div style={{ fontSize: "24px", fontWeight: "bold" }}>INDEMNIFICATION</div>
  <p>To the fullest extent permitted by applicable law, you agree to indemnify and hold harmless Digital Transformation Pro and its officers, directors, employees, parents, partners, successors, agents, distribution partners, affiliates, subsidiaries, and their related companies (collectively, the “Digital Transformation Pro Parties”) from and against any and all demands, claims, liabilities, losses, damages, obligations, costs and expenses (including reasonable attorneys’ fees and costs) arising out of, related to, or that may arise in connection with: (i) your purchase or use of our Services; (ii) User Content provided by you or through use of your Membership; (iii) any actual or alleged violation or breach by you of these Terms of Use; (iv) any actual or alleged breach of any representation, warranty, or covenant that you have made to us; or (v) your negligent or intentional acts or omissions. You agree to cooperate fully with us in the defense of any claim that is the subject of your obligations hereunder.</p>
  <div style={{ fontSize: "24px", fontWeight: "bold" }}>DISCLAIMER OF WARRANTIES</div>
  <p>YOU EXPRESSLY AGREE THAT USE OF OUR SERVICES IS AT YOUR SOLE RISK. OUR SERVICES AND SERVICE CONTENT (INCLUDING SOFTWARE) ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED. WITHOUT LIMITING THE FOREGOING AND TO THE FULLEST EXTENT PERMITTED BY LAW, THE DIGITAL TRANSFORMATION PRO PARTIES DISCLAIM ANY AND ALL WARRANTIES, INCLUDING ANY WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE.</p>
  <div style={{ fontSize: "24px", fontWeight: "bold" }}>LIMITATION ON LIABILITY</div>
  <p>UNDER NO CIRCUMSTANCES SHALL ANY OF THE DIGITAL TRANSFORMATION PRO PARTIES BE LIABLE FOR INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES (EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), ARISING OUT OF, RELATING TO, OR IN ANY WAY CONNECTED WITH OUR SERVICES (INCLUDING YOUR USE OF OUR PRODUCTS) OR THESE TERMS OF USE. YOUR SOLE REMEDY FOR DISSATISFACTION WITH OUR SERVICES INCLUDING, WITHOUT LIMITATION, SERVICE CONTENT IS TO STOP USING OUR SERVICES. SUCH LIMITATION SHALL ALSO APPLY WITH RESPECT TO DAMAGES INCURRED BY REASON OF PRODUCTS RECEIVED THROUGH OR ADVERTISED ON OUR SITE OR ANY LINKS PLACED IN OUR SERVICES, AS WELL AS BY REASON OF ANY INFORMATION OR ADVICE RECEIVED THROUGH OR ADVERTISED IN CONNECTION WITH OUR SERVICES OR ANY LINKS PLACED IN OUR SERVICES. SUCH LIMITATION SHALL ALSO APPLY WITH RESPECT TO DAMAGES INCURRED BY REASON OF ANY CONTENT POSTED BY A THIRD-PARTY OR CONDUCT OF A THIRD-PARTY USING OUR SERVICES.</p>
  <p>NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, IN NO EVENT SHALL THE CUMULATIVE LIABILITY OF ALL OF THE DIGITAL TRANSFORMATION PRO PARTIES EXCEED THE LESSER OF THE TOTAL PAYMENTS RECEIVED FROM YOU BY DIGITAL TRANSFORMATION PRO DURING THE PRECEDING TWELVE (12) MONTH PERIOD OR $100. FURTHERMORE, YOU AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF, RELATING TO, OR IN ANY WAY CONNECTED WITH ANY OF OUR SERVICES OR THESE TERMS OF USE MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION SHALL BE PERMANENTLY BARRED.</p>
  <p>In some jurisdictions limitations of liability are not permitted or are restricted. In such jurisdictions, some of the foregoing limitations may not apply to you. These limitations shall apply to the fullest extent permitted by law.</p>
  <div style={{ fontSize: "24px", fontWeight: "bold" }}>TERMINATION</div>
  <p>We reserve the right in our sole discretion and at any time to terminate or suspend your Membership and/or block your use of our Services for any reason including, without limitation, if you have failed to comply with the letter or spirit of these Terms of Use or our Privacy Policy. You agree that Digital Transformation Pro is not liable to you or any third party for any termination or suspension of your Membership or for blocking your use of our Services.</p>
  <p>Any suspension or termination shall not affect your obligations to us under these Terms of Use. The provisions of these Terms of Use which by their nature should survive the suspension or termination of your Membership or these Terms of Use shall survive including, but not limited to the rights and licenses that you have granted hereunder, indemnities, releases, disclaimers, limitations on liability, provisions related to choice of law, and all of the provisions under “Miscellaneous.”</p>
  <div style={{ fontSize: "24px", fontWeight: "bold" }}>COPYRIGHT POLICY</div>
  <p>We respect the intellectual property rights of others and expect users to do the same. In appropriate circumstances and at our sole discretion, we may terminate and/or disable the Membership of users suspected to be infringing the copyrights (or other intellectual property rights) of others. Additionally, in appropriate circumstances and in our sole discretion, we may remove or disable access to material on any of our websites or hosted on our systems that may be infringing or the subject of infringing activity.</p>
  <p>In accordance with the Digital Millennium Copyright Act of 1998, Title 17 of the United States Code, Section 512 (“DMCA”), we will respond promptly to claims of copyright infringement that are reported to the agent that we have designated to receive notifications of claims infringement (its “Designated Agent”). Our Designated Agent is:</p>
  <p>Digital Transformation Pro LLC</p>
  <p>Legal Department</p>
  <p>Digital Transformation Pro, LLC</p>
  <p>Ste 130-188</p>
  <p>1750 Prairie City Road</p>
  <p>Folsom, CA 95630</p>
  <p>Phone:  (800) 604-4934</p>
  <p>If you are a copyright owner (or authorized to act on behalf of the copyright owner) and believe that your work’s copyright has been infringed, please report your notice of infringement to us by providing our Designated Agent with a written notification of claimed infringement that includes substantially the following:</p>
  <p>A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</p>
  <p>Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at a single online site are covered by a single notification, a representative list of such works at that site.</p>
  <p>Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material.</p>
  <p>Information reasonably sufficient to permit us to contact you, such as an address, telephone number, and, if available, an email address at which you may be contacted.</p>
  <p>A statement that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law.</p>
  <p>A statement that the information in the notification is accurate, and under penalty of perjury, that you are authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</p>
  <p>We will investigate notices of copyright infringement and take appropriate actions under the DMCA. Inquiries that do not follow this procedure may not receive a response.</p>
  <div style={{ fontSize: "24px", fontWeight: "bold" }}>CHOICE OF LAW; JURISDICTION AND VENUE; INTERNATIONAL USERS</div>
  <p>These Terms of Use shall be construed in accordance with the laws of the state of California without regard to its conflict of laws rules. Any legal proceedings against Digital Transformation Pro that may arise out of, relate to, or be in any way connected with our Services or these Terms of Use shall be brought exclusively in the state or federal courts located in or for the state of California, and you waive any jurisdictional, venue, or inconvenient forum objections to such courts.  Digital Transformation Pro makes no representations that the Website or our Services are appropriate or available for use outside the United States.  This Website is for residents of the United States only.  This site is not intended for use by, or to provide any information to, users outside of the United States.  You agree to comply with all laws and regulations applicable to your use of this Website.</p>
  <div style={{ fontSize: "24px", fontWeight: "bold" }}>NO CLASS ACTIONS</div>
  <p>TO THE EXTENT ALLOWED BY LAW, WE EACH WAIVE ANY RIGHT TO PURSUE DISPUTES ON A CLASSWIDE BASIS; THAT IS, TO EITHER JOIN A CLAIM WITH THE CLAIM OF ANY OTHER PERSON OR ENTITY OR ASSERT A CLAIM IN A REPRESENTATIVE CAPACITY ON BEHALF OF ANYONE ELSE IN ANY LAWSUIT, ARBITRATION OR OTHER PROCEEDING.</p>
  <div style={{ fontSize: "24px", fontWeight: "bold" }}>NO TRIAL BY JURY</div>
  <p>TO THE EXTENT ALLOWED BY LAW, WE EACH WAIVE ANY RIGHT TO TRIAL BY JURY IN ANY LAWSUIT OR OTHER PROCEEDING.</p>
  <div style={{ fontSize: "24px", fontWeight: "bold" }}>AMENDMENT; ADDITIONAL TERMS</div>
  <p>We reserve the right in our sole discretion and at any time and for any reason, to modify or discontinue any aspect or feature of our Services or to modify these Terms of Use. In addition, we reserve the right to provide you with operating rules or additional terms that may govern your use of our Services generally, unique of our Services, or both (“Additional Terms”). Any Additional Terms that we may provide to you will be incorporated by reference into these Terms of Use. To the extent any Additional Terms conflict with these Terms of Use, the Additional Terms will control.</p>
  <p>Modifications to these Terms of Use or Additional Terms will be effective immediately upon notice, either by posting on the Website, notification by email or through any of our other applications. It is your responsibility to review the Terms of Use from time to time for any changes or Additional Terms. Your access and use of our Services following any modification of these Terms of Use or the provision of Additional Terms will signify your assent to and acceptance of the same. If you object to any subsequent revision to the Terms of Use or to any Additional Terms, immediately discontinue use of our Services and, if applicable, terminate your Membership.</p>
  <div style={{ fontSize: "24px", fontWeight: "bold" }}>MISCELLANEOUS</div>
  <p>No waiver by either party of any breach or default hereunder shall be deemed to be a waiver of any preceding or subsequent breach or default. The section headings used herein are for convenience only and shall not be given any legal import.</p>
  <p>Except where specifically stated otherwise, if any part of these Terms of Use is unlawful or unenforceable for any reason, we both agree that only that part of the Terms of Use shall be stricken and that the remaining terms in the Terms of Use shall not be affected.</p>
  <p>These Terms of Use (including the Privacy Policy and any Additional Terms incorporated by reference) constitute the entire agreement of the parties with respect to the subject matter hereof and supersede all previous written, electronic, or oral agreements between us with respect to such subject matter.</p>
  <p>You may not assign these Terms of Use or assign any rights or delegate any obligations hereunder, in whole or in part, without our prior written consent. Any such purported assignment or delegation by you without the appropriate prior written consent will be null and void and of no force and effect. We may assign these Terms of Use or any rights hereunder without your consent and without notice.</p>
  <p>You agree that no agency, partnership, joint venture, employee-employer, or franchiser-franchisee relationship exists as a result of these Terms of Use or our Services.  You acknowledge that you do not have authority or power to bind Digital Transformation Pro.  In no event shall Digital Transformation Pro be liable for any representation, act, or omission made by you.</p>
  <p></p>
</div>
    </div>
  );
};

export default TermsAndConditions;
