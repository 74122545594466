import React, { useState, useEffect } from "react";
import BackIcon from "../assets/backIcon.svg";
import Loading from "../components/Loading/Loading";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../components/Button/Button";
import RightArrow from "../assets/rightArrow.svg";
import EbookItems from "components/EbookItems/EbookItems";
import CategoryItem from "../components/CategoryItem/CategoryItem";
import axios from "axios";
import { toast } from "react-toastify";
import BookSVG from "../assets/book.svg";
import BookIMG from "../assets/ebook-landing.png";
import EbookMiniLanding from "../components/EbookMiniLanding/EbookMiniLanding";
import TimePopup from "components/LeadMagnetQuizTimer/LeadMagnetQuizTimer";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";


const Store = ({ props }) => {
  const { username } = useParams();
  const [currentStep, setCurrentStep] = useState(2);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [shouldRenderUI, setShouldRenderUI] = useState(false);
  const [categories, setCategories] = useState([]);
  const [ebooks, setEbooks] = useState([]);
  const [selectedebooks, setSelectedEbooks] = useState([]);
  const [data, setData] = useState({});
  const [timer, setTimer] = useState(-2);
  const [paymentOptions, setPaymentOptions] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);
 
  const [stripePromise, setStripePromise] = useState(null);
  const options = {
    mode: "payment",
    amount: 100,
    currency: "usd",
    appearance: {},
    fields: {
      billingDetails: {
        email: "always",
      },
    },
  };

  const filteredEbooks = ebooks.filter(
    (ebook) => ebook.category_id === selectedCategory
  );
  useEffect(() => {
    const fetchStripeKey = async () => {
      try {
        const response = await axios.get("payment/get-stripe-details", {
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.status === 200) {
          setStripePromise(loadStripe(response.data.publishable_key));
        }
      } catch (error) {
        console.error("Error fetching Stripe details:", error);
      }
    };

    const fetchEbooks = async () => {
      try {
        const response = await axios.get(
          `/${props.type}/all?username=` + username,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          setEbooks(response.data);
          return response.data;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const fetchCategories = async (ebooks2) => {
      try {
        const response = await axios.get("/category/all?username=" + username, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.status === 200) {
          console.log(response.data.categories);
          const categoriesWithEbooks = response.data.categories.filter(
            (category) => {
              return ebooks2.some((ebook) => ebook.category_id === category.id);
            }
          );
          setCategories(categoriesWithEbooks);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchLandingData = async () => {
      try {
        const response = await axios.get(
          `/lead-magnet/get-${props.type}-landing-page?username=` + username,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          setData(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchData = async () => {
      await fetchStripeKey();
      const ebooks2 = await fetchEbooks();
      await Promise.all([fetchCategories(ebooks2), fetchLandingData()]);

      setIsLoading(false);
      setShouldRenderUI(true);
    };

    fetchData();
  }, []);

  const addView = async () => {
    try {
      for (var i = 0; i < ebooks.length; i++) {
        const requestBody = {
          page_type: "product",
          model_type: props.type,
          analytic_type: "view",
          model_id: ebooks[i].id,
          username: username,
        };

        const response = await axios.post("/analytics", requestBody);
      }
    } catch (error) {
      console.error("Error making request:", error);
    }
  };

  const addClick = async () => {
    try {
      for (var i = 0; i < selectedebooks.length; i++) {
        const requestBody = {
          page_type: "product",
          model_type: props.type,
          analytic_type: "click",
          model_id: selectedebooks[i].id,
          username: username,
        };

        const response = await axios.post("/analytics", requestBody);
      }
    } catch (error) {
      console.error("Error making request:", error);
    }
  };

  const handleGetAccess = async () => {
    addClick();
    setCurrentStep((prevStep) => prevStep + 1);
    setTimer(parseInt(data.timer_duration));
  };

  const handleBack = () => {
    if (currentStep > 2) {
      setCurrentStep((prevStep) => prevStep - 1);
      setSelectedEbooks([]);
    }
  };
  const handleContinue = () => {
    if (currentStep == 2 && selectedCategory == 0) {
      toast.error("select a category");
      return;
    }

    if (currentStep === 2) {
      addView();
    }
    if (currentStep == 3 && selectedebooks.length == 0) {
      toast.error("Select an item" + selectedebooks);
      return;
    }

    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleEbookSelect = (ebookId) => {
    setSelectedEbooks((prevSelected) => {
      const isSelected = prevSelected.some((ebook) => ebook.id === ebookId);

      if (isSelected) {
        return prevSelected.filter((ebook) => ebook.id !== ebookId);
      } else {
        const ebookToAdd = ebooks.find((ebook) => ebook.id === ebookId);
        return [...prevSelected, ebookToAdd];
      }
    });
  };

  useEffect(() => {
    const totalPrice = selectedebooks.reduce(
      (sum, ebook) => sum + parseFloat(ebook.price),
      0
    );
    console.log(totalPrice);
    setTotalPrice(totalPrice);
  }, [selectedebooks]);

  return (
    <Elements stripe={stripePromise} options={options}>
      <div className=" relative">
        {isLoading && <Loading />}
        {shouldRenderUI && (
          <div>
            {/* divs for bg */}
            <div className="flex flex-col">
              <div className="flex flex-col items-start justify-start px-5 py-2 md:p-12 lg:p-16 xl:p-24 bg-white h-[50vh]">
                {/* Conditionally render based on the currentStep */}
                {currentStep === 2 && (
                  <>
                    <p className="text-2xl md:text-4xl font-normal">
                      Select Category
                    </p>
                    <p className="my-4 md:my-9 max-w-xs text-black/60 text-sm md:text-base font-normal leading-6">
                      Select the category first where you want to add the lead
                      magnet.
                    </p>
                  </>
                )}
                {currentStep === 3 && (
                  <>
                    <p className="text-2xl md:text-4xl font-normal capitalize">
                      {/* <p className="text-2xl md:text-4xl font-normal"> */}
                      Select {props.type}
                    </p>
                    <p
                      className="my-4 md:my-9 max-w-4xl text-black/60 text-sm md:text-base font-normal leading-6"
                      style={{ zIndex: 9999 }}
                    >
                      {/* <p className="my-4 md:my-9 max-w-xs text-black/60 text-sm md:text-base font-normal leading-6"> */}
                      {props.type != "service" ? (
                        <div>
                          Choose from{" "}
                          {
                            categories.find(
                              (category) => category.id === selectedCategory
                            ).title
                          }{" "}
                          {props.title}. Select and continue to view mini
                          landing page with details and registration. Then enter
                          your email to receive it for free.
                          <br></br>
                        </div>
                      ) : (
                        <div>
                          Click on the service to be redirected to the service
                          page.
                          <br></br>
                        </div>
                      )}

                      {props.type != "service" && (
                        <div>
                          <input
                            type="checkbox"
                            name="has_timer"
                            id=""
                            className="mt-2"
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              if (isChecked) {
                                const filteredEbooks = ebooks.filter(
                                  (ebook) =>
                                    ebook.category_id === selectedCategory
                                );
                                setSelectedEbooks(filteredEbooks);

                                const totalPrice1 = filteredEbooks.reduce(
                                  (sum, ebook) => sum + ebook.price,
                                  0
                                );
                                setTotalPrice(totalPrice1);
                              } else {
                                setSelectedEbooks([]);
                              }
                            }}
                          />
                          Get All the Selected {props.title}s?
                        </div>
                      )}
                    </p>
                  </>
                )}
                {currentStep >= 4 && (
                  <>
                    <p className="text-2xl md:text-4xl font-normal capitalize">
                      {props.type} Mini Landing Page
                    </p>
                  </>
                )}

                {currentStep >= 4 && (
                  <div className="absolute ml-20 mr-20 inset-0 flex flex-col md:flex-row items-center justify-center gap-6">
                    {selectedebooks.map((ebook) => {
                      return (
                        <EbookMiniLanding
                          data={data}
                          icon={BookIMG}
                          onClickHandler={() => handleGetAccess()}
                        />
                      );
                    })}
                  </div>
                )}
              </div>
              <div className="bg-primary h-[50vh]"></div>
            </div>
            {/* Render steps based on the currentStep */}
            {currentStep === 2 &&
              (categories.length == 0 ? (
                <div className="absolute inset-0 flex flex-col md:flex-row items-center justify-center gap-6">
                  No category
                </div>
              ) : (
                <>
                  <div className="mt-14 md:mt-0 absolute inset-0 flex flex-col md:flex-row items-center justify-center gap-5 xl:gap-11">
                    {categories.map((category, index) => (
                      <CategoryItem
                        selected={selectedCategory == category.id}
                        icon={category.image}
                        title={category.title}
                        onClickHandler={() => setSelectedCategory(category.id)}
                      />
                    ))}
                  </div>
                </>
              ))}

            {currentStep === 3 && (
              <div className="absolute ml-20 mr-20 inset-0 flex flex-col md:flex-row items-center justify-center gap-6">
                {filteredEbooks.map((ebook) => {
                  const isSelected = selectedebooks.some(
                    (ebookt) => ebookt.id === ebook.id
                  );
                  return (
                    <EbookItems
                      url={ebook.url}
                      selected={isSelected}
                      key={ebook.id}
                      icon={ebook.image || BookSVG}
                      title={ebook.title}
                      price={ebook.price}
                      onClickHandler={() => {
                        handleEbookSelect(ebook.id);
                      }}
                    />
                  );
                })}
              </div>
            )}

            <div className="absolute bottom-4 md:bottom-16 right-10 md:right-20 flex gap-3">
              {(currentStep <= 3 && (currentStep <= 2 || props.type != "service")) && (
                <>
                  <Button
                    onClick={handleBack}
                    text="Back"
                    src={BackIcon}
                    className="mt-2 md:mt-4 flex flex-row-reverse items-center text-white px-3 md:px-10 py-2 rounded-full font-medium text-sm md:text-xs bg-[#3855B3] hover:bg-[#3855b3da] duration-300"
                  />
                  <Button
                    onClick={handleContinue}
                    text="Continue"
                    src={RightArrow}
                    className="mt-2 md:mt-4 flex items-center text-black px-3 md:px-10 py-2 rounded-full font-normal text-sm md:text-xs bg-white"
                  />
                </>
              )}
            </div>
            
            <TimePopup
              selectedData={selectedebooks}
              username={username}
              timer={timer}
              setTimer={setTimer}
              data={data}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              totalPrice={totalPrice}
              type={props.type}
            />
          </div>
        )}
      </div>
    </Elements>
  );
};

export default Store;
